import { Collapse, HStack, Icon, Text } from "@chakra-ui/react";
import { FiAlertCircle } from "react-icons/fi";

type ErrorAlertProps = {
  message: string;
  isOpen: boolean;
};

export const ErrorAlert = ({ message, isOpen }: ErrorAlertProps) => (
  <Collapse in={isOpen} animateOpacity>
    <HStack
      bg="error.50"
      color="text.primary"
      padding={"0.38rem 0.63rem"}
      borderRadius={"0.5rem"}
      border={"1px solid"}
      borderColor={"error.200"}
      width={"fit-content"}
    >
      <Icon color={"error.500"} as={FiAlertCircle}></Icon>
      <Text>{message}</Text>
    </HStack>
  </Collapse>
);
