import { Text, TextProps } from "@chakra-ui/react";

export const PreformattedText = (props: TextProps) => (
  <Text
    as="pre"
    whiteSpace="pre-wrap"
    overflowWrap={"break-word"}
    fontFamily={"inherit"}
    {...props}
  />
);
