import { Stack, StackProps } from "@chakra-ui/react";

export const DisplayCard = ({ ...props }: StackProps) => (
  <Stack
    bg="gray.50"
    borderRadius={"0.5rem"}
    padding={"1.25rem"}
    gap={"0.75rem"}
    border="1px solid"
    borderColor="border.gray"
    {...props}
  />
);
