import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  ButtonGroup,
  CloseButton,
  Stack,
} from "@chakra-ui/react";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

interface CustomToastProps {
  onClose: any;
  message: string;
}

export function CustomToast({ onClose, message }: CustomToastProps) {
  const navigate = useNavigate();
  return (
    <Alert rounded={"md"} background="white" shadow="lg">
      <Stack gap={2}>
        <AlertTitle color={"gray.900"}>
          Updates have been made to Ticket
        </AlertTitle>
        <AlertDescription color="gray.500">{message}</AlertDescription>
        <ButtonGroup size={"sm"} variant="ghost">
          <Button color="gray.500" onClick={onClose} colorScheme="gray">
            Close
          </Button>

          <Button
            onClick={() => {
              navigate(-1);
              onClose();
            }}
            rightIcon={<FaArrowRight />}
            colorScheme="brand"
          >
            Confirm and return to Article Page
          </Button>
        </ButtonGroup>
      </Stack>
      <CloseButton
        alignSelf="flex-start"
        position="relative"
        right={-1}
        top={-1}
        onClick={onClose}
      />
    </Alert>
  );
}
