import {
  Modal,
  ModalOverlay,
  ModalContent,
  Button,
  Box,
  HStack,
  IconButton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { IoMdClose, IoMdRefresh } from "react-icons/io";
import { useRecoilState } from "recoil";

import { trackMixpanelRecordingEvent } from "../../../../utils/mixpanelEvents";
import { newHarkState } from "../../atoms/NewHarkStateAtom";
import { logVideoError } from "../../../../utils/videoError";

interface VideoPlaybackProps {
  duration: number;
  closeCamera: () => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onRetry: () => void;
}

const MobilePlaybackModal = ({
  closeCamera,
  onClose,
  isOpen,
  onRetry,
}: VideoPlaybackProps) => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const [posterUrl, setPosterUrl] = useState("");

  // Todo: revoke poster url on retry

  const vidRef = useRef(null);

  const closeModal = () => {
    closeCamera();
    onClose();
  };

  const handleCancelRecording = () => {
    setNewHark({
      ...newHark,
      thumbNail: undefined,
      recording: null,
    });
    closeCamera();
    onClose();
  };

  const handleSaveClick = () => {
    trackMixpanelRecordingEvent("Recording Saved", "Video");
    closeModal();
  };

  function revokePosterUrl() {
    if (posterUrl) {
      URL.revokeObjectURL(posterUrl);
      setPosterUrl(""); // Reset the poster URL state
    }
  }

  useEffect(() => {
    function generatePosterUrl() {
      if (!newHark.thumbNail) return;
      const posterUrl = URL.createObjectURL(newHark.thumbNail);
      setPosterUrl(posterUrl);
      console.log(posterUrl);
    }
    generatePosterUrl();

    // Cleanup function that runs when the component unmounts
    return () => {
      revokePosterUrl();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newHark.thumbNail]);

  return (
    <Modal size={"full"} isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent
        background={"black"}
        color="white"
        height={"-webkit-fill-available"}
      >
        <Stack flexFlow="column" height="100%" py={5} gap={2}>
          <Box px={5}>
            <IconButton
              onClick={handleCancelRecording}
              aria-label="Discard"
              icon={<IoMdClose size={24} />}
              color="gray.300"
              variant={"ghost"}
              justifySelf={"left"}
            />
          </Box>

          <Box>
            <video
              ref={vidRef}
              playsInline
              controls
              poster={posterUrl}
              onError={(e) => logVideoError(e, "MobilePlayback.tsx")}
            >
              {newHark.recording && newHark.recording.type === "video" && (
                <source
                  src={window.URL.createObjectURL(
                    newHark.recording?.source as Blob
                  )}
                  type="video/mp4"
                />
              )}
            </video>
          </Box>
          <HStack justify={"space-between"} px={5}>
            <Button
              onClick={onRetry}
              leftIcon={<IoMdRefresh />}
              color={"white"}
              bgColor={"black"}
            >
              Retry
            </Button>

            <Button
              variant="solid"
              color="brand.buttonText"
              colorScheme={"brand"}
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </HStack>
        </Stack>
      </ModalContent>
    </Modal>
  );
};

export default MobilePlaybackModal;
