import {
        FormControl,
        Input,
        FormHelperText,
        FormLabel,
        FormErrorMessage,
        Select,
        Button,
        ButtonGroup,
        Stack
      } from "@chakra-ui/react"

import {useState } from "react";
import "./nps.css"
import { string } from "joi";

interface NpsProp {
  onChange: (name: string, value: string) => void;
};

const Nps: React.FC<NpsProp> = ({onChange}) => {

  const [npsRating, setNpsRating] = useState<number | null>(null);
	const [activeButton, setActiveButton] = useState<number | null>(null);

  

	const handleClick = (rating: number) => {
    setNpsRating(rating);
		setActiveButton(rating);
		onChange?.(String(rating), "nps");
  };
	
	const buttons = [0,1,2,3,4,5,6,7,8,9,10];
	
	return (
		<div className="nps">
			<Stack direction='row' justifyContent={'space-between'}>
				<label>Not very likely</label>
				<label id='vl'>Very likely</label>
			</Stack>
			<div id = 'buttons'>
			{buttons.map((rating) => (
          <button
            key={rating}
            style={{backgroundColor: activeButton === rating ? '#4083F4' : '' }}
            onClick={() => handleClick(rating)}
          >
          	{rating}
          </button>
        ))}
			</div>
			<input type='hidden' 
				value={npsRating !== null ? npsRating.toString() : ''}>
				</input>
		</div>
	);
};
        
export default Nps