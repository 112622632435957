import {
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Box,
  Textarea,
  ButtonGroup,
  IconButton,
} from "@chakra-ui/react";
import { useState } from "react";
import { useRecoilState } from "recoil";

import {
  HarkButton,
  HarkButtonVariant,
} from "../../../../components/HarkButton";
import { trackMixpanelRecordingEvent } from "../../../../utils/mixpanelEvents";
import { newHarkState } from "../../atoms/NewHarkStateAtom";
import {
  RecordModal,
  RecordModalFooter,
  RecordModalHeader,
} from "../RecordingModal";
import { IoMdClose } from "react-icons/io";
import { LanguageSelection } from "../LanguageSelection";
import { RecordHarkButton } from "../RecordHarkButton";

interface WriteTextProps {
  buttonVariant: HarkButtonVariant;
}
export const WriteTextModal = ({ buttonVariant }: WriteTextProps) => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [textMessage, setTextMessage] = useState("");
  const [hasTypedMessageBefore, setHasTypedMessageBefore] = useState(false);

  const openModal = () => {
    onOpen();
    trackMixpanelRecordingEvent("Recording Modal Opened", "Text");
  };

  const cancelRecording = () => {
    onClose();
    setTextMessage("");
    trackMixpanelRecordingEvent("Recording Cancelled", "Text");
  };

  const handleSave = () => {
    setNewHark({
      ...newHark,
      recording: { type: "text", source: textMessage, mediaType: "text" },
    });
    trackMixpanelRecordingEvent("Recording Complete", "Text");
    trackMixpanelRecordingEvent("Recording Saved", "Text");

    onClose();
  };

  return (
    <>
      {!newHark.recording && (
        <RecordHarkButton
          id="textModal"
          onClick={openModal}
          variant={buttonVariant}
          recordingOption="text"
        >
          Write Message
        </RecordHarkButton>
      )}

      <RecordModal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <RecordModalHeader
            title="Write about the issue"
            description="Please keep it focused to provide sufficient details."
          />
          <ModalBody py={4}>
            <LanguageSelection isText={true} />
            <Box py={4}>
              <Textarea
                minH={200}
                value={textMessage}
                onChange={(e) => {
                  setTextMessage(e.target.value);
                  if (!hasTypedMessageBefore) {
                    setHasTypedMessageBefore(true);
                    trackMixpanelRecordingEvent("Recording Started", "Text");
                  }
                }}
                placeholder="Type your message here"
                resize={"vertical"}
              />
            </Box>
          </ModalBody>

          <RecordModalFooter>
            <ButtonGroup width={"full"}>
              <IconButton
                aria-label="Cancel"
                variant="outline"
                bg="white"
                borderColor={"gray.300"}
                icon={<IoMdClose />}
                onClick={cancelRecording}
              />

              <HarkButton
                name="textSaveButton"
                flexGrow={1}
                onClick={handleSave}
                isDisabled={!textMessage}
              >
                Save & continue
              </HarkButton>
            </ButtonGroup>
          </RecordModalFooter>
        </ModalContent>
      </RecordModal>
    </>
  );
};
