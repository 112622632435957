import { Workflow } from "../../../@types/Workflow";

type WorkflowType =
  | "Direct Link"
  | "User Selection"
  | "Default Only"
  | "No workflow";

export const getWorkflowType = (
  workflows: Workflow[],
  selectedWorkflow: Workflow | undefined,
  slug: string | null
): WorkflowType => {
  if (selectedWorkflow?.slug === slug) return "Direct Link";
  if (workflows.length > 1) return "User Selection";
  if (workflows.length === 1) return "Default Only";
  else return "No workflow";
};
