import { Icon } from "@chakra-ui/react";

export const RecordScreenIcon = () => (
  <Icon viewBox="0 0 20 18" fill="none">
    <path
      d="M6.31017 16.5C7.4225 15.9665 8.6753 15.6667 10.0003 15.6667C11.3254 15.6667 12.5782 15.9665 13.6905 16.5M5.66699 13.1667H14.3337C15.7338 13.1667 16.4339 13.1667 16.9686 12.8942C17.439 12.6545 17.8215 12.272 18.0612 11.8016C18.3337 11.2669 18.3337 10.5668 18.3337 9.16667V5.5C18.3337 4.09987 18.3337 3.3998 18.0612 2.86502C17.8215 2.39462 17.439 2.01217 16.9686 1.77248C16.4339 1.5 15.7338 1.5 14.3337 1.5H5.66699C4.26686 1.5 3.5668 1.5 3.03202 1.77248C2.56161 2.01217 2.17916 2.39462 1.93948 2.86502C1.66699 3.3998 1.66699 4.09987 1.66699 5.5V9.16667C1.66699 10.5668 1.66699 11.2669 1.93948 11.8016C2.17916 12.272 2.56161 12.6545 3.03202 12.8942C3.5668 13.1667 4.26686 13.1667 5.66699 13.1667Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
