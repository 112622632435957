import { Tag, TagProps } from "@chakra-ui/react";

interface WorkflowButtonProps extends TagProps {
  isActive: boolean;
  onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void;
}



export const WorkflowButton = ({ isActive, onClick, ...props }: WorkflowButtonProps) => {
  function captureEnterKey(event: React.KeyboardEvent) {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      onClick?.(event);
    }
  }

  return (
    <Tag
      colorScheme={"brand"}
      boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
      p="0.25rem 0.625rem"
      bg={isActive ? "brand.activeBg" : "white"}
      color={"text.secondary"}
      cursor={isActive ? "default" : "pointer"}
      border="1px solid"
      _hover={{ bg: "brand.activeBg" }}
      _focus={{ boxShadow: "rgba(66, 153, 225, 0.6) 0px 0px 0px 3px" }}
      onKeyDown={captureEnterKey}
      onClick={onClick}
      borderColor={isActive ? "brand.500" : "border.gray"}
      borderRadius={"0.5rem"}
      fontWeight={500}
      fontSize={"0.875rem"}
      lineHeight={"1.25rem"}
      transition="background-color 0.5s ease" /* Transition effect */
      role="button"
      tabIndex={0}
      {...props}
    />
  );
};
