import {
  Box,
  HStack,
  Heading,
  Icon,
  IconButton,
  Text,
  Stack,
} from "@chakra-ui/react";
import "react-phone-number-input/style.css";
import "../../style/phone-input.css";
import { useRecoilValue } from "recoil";
import { newHarkState } from "../../atoms/NewHarkStateAtom";
import { RiPencilLine } from "react-icons/ri";
import { WorkflowButton } from "../WorkflowSelection/WorkflowButton";
import { RecordingSnapshot } from "../RecordingSnapshot";
import { FaRegUserCircle } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";

import { PreferredContactMethod } from "./PrefreedContact";
import { AdditionalInfo } from "./AdditonalInfoSection";
import { DisplayCard } from "../DisplayCard";
import { useEffect } from "react";
import { validationErrorState } from "../../atoms/validationErrorsState";
import { ErrorAlert } from "../ErrorAlert";

type ReviewHarkProps = {
  goToStep: (step: number) => void;
  isSubmitting: boolean;
};

export const ReviewHark = ({ goToStep, isSubmitting }: ReviewHarkProps) => {
  const newHark = useRecoilValue(newHarkState);
  const selectedWorkflow = newHark.workflow;
  const customFields = Object.values(newHark.customFieldResponses);

  const surveyCustomFields =
    selectedWorkflow?.custom_fields?.filter(
      (field) => field.is_survey === true
    ) || [];

  const additionalInfoFields = selectedWorkflow?.custom_fields?.filter(
    (field) => field.is_survey === false
  ) || [];

  const hasSurveyStep = surveyCustomFields.length > 0;

  const errors = useRecoilValue(validationErrorState);

  useEffect(() => {
    const scrollToSection = () => {
      const section = document.getElementById("preferredContactSection");

      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    };
    scrollToSection();
  }, []);

  const items = [
    {
      heading: "Selected Issue Category",
      step: 0,
      component: (
        <WorkflowButton width={"fit-content"} isActive={false}>
          {newHark.workflow?.name}
        </WorkflowButton>
      ),
    },
    {
      heading: "Contact Details",
      step: 1,
      component: (
        <Box>
          <HStack gap={2}>
            <Icon as={FaRegUserCircle} color="brand.500" />
            <Text>
              {newHark.contactDetails.firstName}
              &nbsp;
              {newHark.contactDetails.lastName}
            </Text>
          </HStack>
          <HStack>
            <Icon as={HiOutlineMail} color="brand.500" />
            <Text>{newHark.contactDetails.email}</Text>
          </HStack>
        </Box>
      ),
    },
    {
      heading: "Your Message",
      step: 2,
      component: (
        <Box borderRadius="0.5rem" p="1rem" bg="white">
          <RecordingSnapshot showDeleteIcon={false} />
        </Box>
      ),
    },
    {
      heading: "Survey Responses",
      step: 2,
      component: <AdditionalInfo isSurvey={true} />,
      ignore: !surveyCustomFields.length 
    },
    {
      heading: "Additional Info",
      step: hasSurveyStep ? 3 : 2,
      component: <AdditionalInfo />,
      ignore:
        (!additionalInfoFields.length || additionalInfoFields.every((field) => !field)) &&
        !newHark.attachments.length &&
        !newHark.contactDetails.address &&
        !newHark.contactDetails.phone,
    },
    {
      heading: "Tell us how you prefer to communicate",
      component: <PreferredContactMethod />,
      ignore: selectedWorkflow?.settings?.hidePreferredContactMethod,
    },
  ];

  return (
    <Stack gap={5}>
      {items
        .filter((item) => !item.ignore)
        .map((item, i) => (
          <Stack key={i} gap={5}>
            {item.heading === "Preferred contact channel" && errors.phone && (
              <ErrorAlert
                message="Seems there’s a missing or incomplete field, please complete the field to proceed"
                isOpen={true}
              />
            )}
            <DisplayCard>
              <HStack justify={"space-between"}>
                <Heading
                  fontSize={"1rem"}
                  as="h2"
                  color="text.secondary"
                  fontWeight={600}
                >
                  {item.heading}
                </Heading>
                {item.step !== undefined && (
                  <IconButton
                    isDisabled={isSubmitting}
                    size={"sm"}
                    colorScheme="brand"
                    aria-label="Search database"
                    variant="ghost"
                    bg="white"
                    onClick={() => goToStep(item.step)}
                  >
                    {<RiPencilLine size={20} />}
                  </IconButton>
                )}
              </HStack>
              {item.component}
            </DisplayCard>
          </Stack>
        ))}

      {Boolean(selectedWorkflow?.settings?.legalText) && (
        <Text fontWeight={"bold"} my={4} fontSize={"14px"} color={"gray.600"}>
          {selectedWorkflow?.settings?.legalText}
        </Text>
      )}
    </Stack>
  );
};
