import _ from "lodash";
import axios, { AxiosRequestConfig } from "axios";
import { IHark, LanguageOption, NewHark } from "../@types/IHark";
import { setSessionRewindInfo } from "../utils/sessionRewind";
import { uploadFileToCloudStorage } from "./cloudStorageUpload";
import { CustomField } from "../@types/Workflow";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const formatCustomFields = (
  responses: { [key: string]: string },
  customFields?: CustomField[]
) => {
  if (!customFields) return [];
  const formatted: Array<{ field: number; response: string }> = [];
  for (const field of customFields) {
    const { name, id } = field;
    if (responses[name])
      formatted.push({ field: id, response: responses[name] });
  }

  return formatted;
};

export async function createHark(newHark: NewHark): Promise<IHark> {
  const { firstName, lastName, email, phone, communicationMethod, address } =
    newHark.contactDetails!;

  const company = newHark.company._id;

  let recording;
  if (!newHark.recording?.source) throw new Error("invalid recording");
  if (newHark.recording?.type === "text") recording = newHark.recording.source;
  else {
    const recordingBlob = newHark.recording.source as Blob;
    recording = await uploadFileToCloudStorage(
      new File(
        [recordingBlob],
        "recording" +
          (newHark.recording ? "." + newHark.recording.mediaType : ""),
        {
          type: recordingBlob.type,
        }
      ),
      company
    );
  }
  let thumbnail;
  if (!!newHark.thumbNail)
    thumbnail = await uploadFileToCloudStorage(
      newHark.thumbNail! as File,
      company
    );

  const additional_files: Array<{ objectId: string; name: string }> =
    await Promise.all(
      newHark.attachments.map(async (attachment, index) => {
        const objectId: string = await uploadFileToCloudStorage(
          attachment,
          company
        );
        return { objectId, name: `additional_file_${index}` };
      })
    );

  const config: AxiosRequestConfig = {
    method: "POST",
    url: `${BASE_URL}/tasks/`,

    data: {
      thumbnail,
      name: `${firstName} ${lastName}`,
      email,
      phone,
      communication_method: communicationMethod,
      company,
      product_name: _.capitalize(`${newHark.workflow.name}`),
      additional_files,
      address,
      custom_field_responses: formatCustomFields(
        newHark.customFieldResponses,
        newHark.workflow.custom_fields
      ),
      workflow: newHark.workflow.slug,
      existing_ticket_id: newHark.ticket_id,
      hark_type: newHark.recording?.type,
      additionalInformation: [],
      language: newHark.language,
    },
  };

  if (newHark.recording?.type === "text") {
    config.data.text = recording;
  } else {
    config.data.recording = recording;
  }

  const response = await axios(config);

  // update session Recorder with harkID
  setSessionRewindInfo({ HarkID: response?.data?.data?._id });

  return response.data.data;
}

export const getHarkById = async (id: string | number): Promise<IHark> => {
  const response = await axios.get(`${BASE_URL}/tasks/${id}`);
  return response.data.data;
};

export const closeHarkTicket = async (harkID: string): Promise<any> => {
  const response = await axios.patch(`${BASE_URL}/tasks/${harkID}/close/`);
  return response.data.data;
};

export async function getLanguageOptions(): Promise<LanguageOption[]> {
  const res = await axios.get(`${BASE_URL}/tasks/language-options/`);
  return res.data;
}