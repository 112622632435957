import { useDisclosure } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { HarkButtonVariant } from "../../../../components/HarkButton";
import { isMobileDevice } from "../../../../utils/detectMobileBrowser";
import { trackMixpanelRecordingEvent } from "../../../../utils/mixpanelEvents";
import { newHarkState } from "../../atoms/NewHarkStateAtom";
import useRecorder from "../../hooks/useRecorder";
import { DesktopRecordModal } from "./DesktopRecordModal";
import MobilePlaybackModal from "./MobilePlayback";
import { MobileRecordModal } from "./MobileRecordModal";
import VideoPlayback from "./VideoPlayBacks";
import { useEffect, useState } from "react";
import Countdown from "../CountDownTimer";
import { RecordHarkButton } from "../RecordHarkButton";

interface RecordVideoProps {
  buttonVariant: HarkButtonVariant;
}

const RecordVideo = ({ buttonVariant }: RecordVideoProps) => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const { callToActionText } = newHark;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isVideoPlayBackOpen,
    onOpen: openVideoPlayback,
    onClose: closeVideoPlayback,
  } = useDisclosure();

  const {
    mediaStream,
    requestAccessToMediaDevices,
    stopAllMediaStreams,
    startRecording,
    stopRecording,
    duration,
    isRecording,
    cameras,
    changeCamera,
    reverseCameraFacing,
    loading,
  } = useRecorder({
    recordType: "video",
    onRecordingStarted: () => setShowCountdown(false),
  });
  const [showCountdown, setShowCountdown] = useState<boolean>(false);

  useEffect(() => {
    if (mediaStream) {
      let video = document.getElementById("record-video") as HTMLVideoElement;
      video.srcObject = mediaStream;
    }
  }, [mediaStream]);

  const openModal = () => {
    onOpen();
    trackMixpanelRecordingEvent("Recording Modal Opened", "Video");
    requestAccessToMediaDevices().then(() => {
      let video = document.getElementById("record-video") as HTMLVideoElement;
      video.srcObject = mediaStream;
    });
  };

  const closeModal = () => {
    stopAllMediaStreams();
    onClose();
  };

  const handleStopRecording = () => {
    stopRecording();
    trackMixpanelRecordingEvent("Recording Complete", "Video");
    openVideoPlayback();
    onClose();
  };

  const retryRecording = () => {
    trackMixpanelRecordingEvent("Recording Retry", "Video");
    setNewHark({ ...newHark, recording: null });
    closeVideoPlayback();
    openModal();
  };

  const handleCancelRecording = () => {
    trackMixpanelRecordingEvent("Recording Cancelled", "Video");
    stopRecording();
    stopAllMediaStreams();
    setTimeout(() => {
      setNewHark({
        ...newHark,
        thumbNail: undefined,
        recording: null,
      });

      onClose();
      setShowCountdown(false);
    }, 50);
  };

  const CountDownTimer = showCountdown ? (
    <Countdown onFinished={startRecording} />
  ) : (
    ""
  );

  return (
    <>
      {!newHark.recording && (
        <RecordHarkButton
          id='videoModal'
          recordingOption="video"
          onClick={openModal}
          variant={buttonVariant}
        >
          {callToActionText} Video
        </RecordHarkButton>
      )}

      {newHark.recording ? (
        isMobileDevice() ? (
          <MobilePlaybackModal
            duration={duration}
            closeCamera={stopAllMediaStreams}
            isOpen={isVideoPlayBackOpen}
            onOpen={openVideoPlayback}
            onClose={closeVideoPlayback}
            onRetry={retryRecording}
          />
        ) : (
          <VideoPlayback
            duration={duration}
            closeCamera={stopAllMediaStreams}
            isOpen={isVideoPlayBackOpen}
            onOpen={openVideoPlayback}
            onClose={closeVideoPlayback}
            onRetry={retryRecording}
          />
        )
      ) : isMobileDevice() ? (
        <MobileRecordModal
          onClose={closeModal}
          isOpen={isOpen}
          loading={loading}
          startRecording={() => setShowCountdown(true)}
          isRecording={isRecording}
          videoStream={mediaStream}
          reverseCameraFacing={reverseCameraFacing}
          handleStopRecording={handleStopRecording}
          duration={duration}
          handleCancelRecording={handleCancelRecording}
          countDownTimer={CountDownTimer}
        />
      ) : (
        <DesktopRecordModal
          isOpen={isOpen}
          loading={loading}
          startRecording={() => setShowCountdown(true)}
          isRecording={isRecording}
          videoStream={mediaStream}
          changeCamera={changeCamera}
          cameras={cameras}
          handleStopRecording={handleStopRecording}
          duration={duration}
          handleCancelRecording={handleCancelRecording}
          countDownTimer={CountDownTimer}
        />
      )}
    </>
  );
};

export default RecordVideo;
