import {
  FormControl,
  Input,
  FormHelperText,
  FormLabel,
  FormErrorMessage,
  Select,
} from "@chakra-ui/react";

import { CustomField } from "../../../../@types/Workflow";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { formatDateToMMDDYYYY } from "../../../../utils/dateFormatter";
import "./datePicker.css";
import Nps from "./nps"
import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { Company } from "../../../../@types/Company"; 
import { Workflow } from "../../../../@types/Workflow"; 

interface CustomFieldInputProps {
  field: CustomField;
  value: string;
  errorMessage?: string;
  onChange: (name: string, value: string) => void;
  validateNumberInput: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const CustomFieldInput = ({
  field,
  value,
  errorMessage,
  onChange,
  validateNumberInput,
}: CustomFieldInputProps) => {
  const { name, id, required, description, misc } = field;
  const [date, setDate] = useState<Date | null>(null);
  const [dateError, setDateError] = useState<string | null>(null);
  const validInputTypes = ["number", "date", "select", "nps"];
  const inputType = String(misc?.input_type);
  const stylingProps = {
    _invalid: { border: "1px solid", borderColor: "border.error" },
    _focus: {
      borderColor: !!errorMessage ? "border.error" : "border.primary",
      boxShadow: "none",
    },
  };
  const loaderData = useLoaderData() as { company: { company: Company }; slugWorkflow: Workflow; customFields: [CustomField] };
  const allCompanyCustomFields = loaderData.customFields;
  const allCustomFieldData = allCompanyCustomFields.find((customField) => customField.id === id) || { description: "" };

  return (
    <FormControl isInvalid={!!errorMessage} isRequired={required}>
      <FormLabel fontSize="sm">
        {name}&nbsp; {required ? "(required)" : "(optional)"}
      </FormLabel>
      
      {inputType === "select" && misc?.input_options?.length && (
        <Select
          bg="white"
          name={String(name)}
          value={value}
          placeholder="Select Option"
          onChange={(e) => onChange(String(name), e.target.value)}
          {...stylingProps}
        >
          {misc.input_options.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
      )}
      {inputType === "date" && (
        <DatePicker
          className="date-picker"
          format="MM/dd/y"
          yearPlaceholder="yyyy"
          monthPlaceholder="mm"
          dayPlaceholder="dd"
          value={date}
          name={String(name)}
          onChange={(date) => {
            if (date instanceof Date) {
              const year = date.getFullYear();

              // Check if the year is a four-digit number
              if (year >= 1000 && year <= 9999) {
                setDate(date);
                onChange(String(name), formatDateToMMDDYYYY(date));
              } else {
                setDate(null);
                onChange(String(id), "");
                setDateError("Invalid Date");
              }
            }
          }}
          onInvalidChange={() => {
            setDate(null);
            onChange(String(name), "");
            setDateError("Invalid Date");
          }}
          
        />
      )}
      {inputType === "number" && (
        <Input
          bg="white"
          value={value}
          name={String(name)}
          onChange={(e) => onChange(String(name), e.target.value)}
          onKeyUp={(e) => {
            validateNumberInput(e);
          }}
          type="number"
          pattern="[0-9]*"
          {...stylingProps}
        />
      )}
      {inputType === "nps" && (
        <Nps 
          onChange={(rating) => onChange(String(name), rating)}
        />
      )
      }
      {!validInputTypes.includes(inputType) && (
        <Input
          bg="white"
          name={String(name)}
          type={"text"}
          value={value}
          onChange={(e) => onChange(String(name), e.target.value)}
          {...stylingProps}
        />
      )}
      <FormHelperText>{allCustomFieldData.description}</FormHelperText>
      <FormErrorMessage>{dateError || errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
