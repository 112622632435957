import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Icon,
  Slide,
  Stack,
} from "@chakra-ui/react";
import { HarkButton } from "../../../../components/HarkButton";
import { NarrationGuide } from "./NarrationGuide";
import { LanguageSelection } from "../LanguageSelection";
import { MdFiberManualRecord } from "react-icons/md";
import { FaChevronLeft } from "react-icons/fa";
interface MobileNarrationSlideProps {
  isOpen: boolean;
  secondaryAction: () => void;
  primaryAction: () => void;
}
export const MobileNarrationSlide = ({
  isOpen,
  secondaryAction,
  primaryAction,
}: MobileNarrationSlideProps) => (
  <Slide direction="bottom" in={isOpen} style={{ zIndex: 1000 }}>
    <Stack gap={2} bg="white" p={5} roundedTop={"lg"}>
      <LanguageSelection />
      <Card bg="#f7f7f8" marginBottom={2}>
        <CardBody>
          <NarrationGuide />
        </CardBody>
      </Card>
      <ButtonGroup>
        <Button
          onClick={secondaryAction}
          colorScheme="gray"
          aria-label="Discard"
          leftIcon={<FaChevronLeft />}
          background="rgba(255, 255, 255, 0.25)"
          border="gray.500"
          borderWidth={1}
          color="black"
          size="md"
          fontSize={13}
        >
          Go back
        </Button>
        <HarkButton flexGrow={1} onClick={primaryAction}>
          <Icon as={MdFiberManualRecord} size={24} marginRight={2} /> Start
          Recording
        </HarkButton>{" "}
      </ButtonGroup>
    </Stack>
  </Slide>
);
