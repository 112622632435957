import {
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  ButtonGroup,
} from "@chakra-ui/react";
import { useRef } from "react";
import { IoMdRefresh } from "react-icons/io";
import { useRecoilValue } from "recoil";
import { HarkButton } from "../../../../components/HarkButton";

import { trackMixpanelRecordingEvent } from "../../../../utils/mixpanelEvents";
import { newHarkState } from "../../atoms/NewHarkStateAtom";
import {
  RecordModal,
  RecordModalFooter,
  RecordModalHeader,
  SecondaryRecordModalButton,
} from "../RecordingModal";
import { logVideoError } from "../../../../utils/videoError";

interface VideoPlaybackProps {
  duration: number;
  closeCamera: () => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onRetry: () => void;
}

const VideoPlayback = ({
  duration,
  closeCamera,
  onClose,
  isOpen,
  onRetry,
}: VideoPlaybackProps) => {
  const newHark = useRecoilValue(newHarkState);

  const vidRef = useRef(null);

  const closeModal = () => {
    closeCamera();
    onClose();
  };

  const handleSaveClick = () => {
    trackMixpanelRecordingEvent("Recording Saved", "Video");
    closeModal();
  };

  return (
    <RecordModal
      isOpen={isOpen}
      onClose={closeModal}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <RecordModalHeader
          title="Record Video"
          description="Record a short video message narrating the issue"
        />

        <ModalBody>
          <Box>
            <figure>
              <video
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
                ref={vidRef}
                controls
                playsInline
                preload="auto"
                onError={(e) => logVideoError(e, "VideoPlayBacks.tsx")}
              >
                {newHark.recording && newHark.recording.type === "video" && (
                  <source
                    src={newHark.recording?.playbackUrl}
                    type="video/mp4"
                  />
                )}
              </video>
            </figure>
          </Box>
        </ModalBody>

        <RecordModalFooter>
          <ButtonGroup width={"full"}>
            <SecondaryRecordModalButton
              width="50%"
              leftIcon={<IoMdRefresh />}
              onClick={onRetry}
            >
              Retry
            </SecondaryRecordModalButton>
            <HarkButton width="50%" onClick={handleSaveClick}>
              Save
            </HarkButton>
          </ButtonGroup>
        </RecordModalFooter>
      </ModalContent>
    </RecordModal>
  );
};

export default VideoPlayback;
