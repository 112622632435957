import { useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { trackMixpanelRecordingEvent } from "../../../utils/mixpanelEvents";
import { newHarkState } from "../atoms/NewHarkStateAtom";
import { Stopwatch } from "../../../utils/stopwatch";

const constraints = {
  video: true,
};

export const useScreenRecorder = ({
  onRecordingStopped,
  onRecordingStarted,
}: {
  onRecordingStopped: () => void;
  onRecordingStarted: () => void;
}) => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const [duration, setDuration] = useState<number>(0);
  const [isRecording, setIsRecording] = useState<boolean>(false);

  // Moved videoStream and audioStream to useRef
  const videoStream = useRef<MediaStream | null>(null);
  const audioStream = useRef<MediaStream | null>(null);

  // Moved recorder to useRef
  const recorder = useRef<MediaRecorder | null>(null);

  const stopWatch = new Stopwatch((duration) => setDuration(duration));

  const requestAccessToMediaDevices = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getDisplayMedia(
        constraints
      );
      videoStream.current = mediaStream;

      const videoTrack = mediaStream.getVideoTracks()[0];
      videoTrack.onended = () => {
        stopRecording();
        window.focus();
      };

      const audio = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioStream.current = audio;
    } catch (err: any) {
      console.log("Errorprpprpprp");
      throw new Error(err);
    }
  };

  const stopRecording = () => {
    recorder.current?.stop();
    setIsRecording(false);
  };

  const stopAllMediaStreams = () => {
    videoStream.current?.getTracks().forEach((t) => t.stop());
    audioStream.current?.getTracks().forEach((t) => t.stop());

    videoStream.current = null;
    audioStream.current = null;
  };

  const startRecording = async () => {
    if (!videoStream.current || !audioStream.current) return;

    const mixedStream = new MediaStream([
      ...videoStream.current.getTracks(),
      ...audioStream.current.getTracks(),
    ]);

    recorder.current = new MediaRecorder(mixedStream);

    const chunks: Blob[] = [];

    recorder.current.ondataavailable = (event: BlobEvent) => {
      chunks.push(event.data);
      const videoBlob = new Blob(chunks, { type: "video/mp4" });
      stopAllMediaStreams();
      recorder.current = null;
      setNewHark({
        ...newHark,
        recording: {
          source: videoBlob,
          mediaType: "mp4",
          type: "screen",
          playbackUrl: window.URL.createObjectURL(videoBlob),
        },
      });
    };

    recorder.current.onstop = () => {
      stopWatch.stop();
      setIsRecording(false);
      trackMixpanelRecordingEvent("Recording Complete", "Screen");
      onRecordingStopped();
    };

    recorder.current.onstart = () => {
      trackMixpanelRecordingEvent("Recording Started", "Screen");
      setIsRecording(true);
      stopWatch.reset();
      stopWatch.start();
      onRecordingStarted();
    };

    recorder.current.onerror = (e: any) => {
      stopWatch.stop();

      console.log(e.error);
    };

    recorder.current.onpause = () => {
      stopWatch.stop();
      trackMixpanelRecordingEvent("Recording Paused", "Screen");
    };

    recorder.current.onresume = () => {
      stopWatch.start();

      trackMixpanelRecordingEvent("Recording Resumed", "Screen");
    };

    recorder.current.start();
  };

  return {
    videoStream: videoStream.current,
    stopAllMediaStreams,
    requestAccessToMediaDevices,
    startRecording,
    stopRecording,
    isRecording,
    duration,
  };
};

export default useScreenRecorder;
