import { Box, BoxProps } from "@chakra-ui/react";

interface CardProps extends BoxProps {
  children?: React.ReactNode | React.ReactNode[];
}
const Card = ({ children, ...restProps }: CardProps) => (
  <Box
    width="full"
    bg="white"
    rounded="xl"
    p={{ md: "5", base: "2" }}
    {...restProps}
  >
    {children}
  </Box>
);

export default Card;
