import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Box,
  Icon,
} from "@chakra-ui/react";
import { useRef } from "react";
import { HiExclamation } from "react-icons/hi";

type WorkflowChangeAlertProps = {
  isOpen: boolean;
  onClose: () => void;
  onAction: () => void;
};
export default function WorkflowChangeAlert({
  isOpen,
  onClose,
  onAction,
}: WorkflowChangeAlertProps) {
  const cancelRef = useRef<any | null>();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent borderRadius={"1rem"} p={"1.25rem"} gap={"1.25rem"}>
          <AlertDialogBody
            p="0"
            m="0"
            textAlign={"center"}
            color={"gray.700"}
            display={"flex"}
            flexDir={"column"}
            alignItems={"center"}
          >
            <Box
              width="fit-content"
              borderRadius={"0.625rem"}
              p={"0.4rem 0.65rem 0.25rem"}
              bg="orange.50"
              mb="1rem"
            >
              <Icon as={HiExclamation} color="orange.500" boxSize={10} />
            </Box>
            <AlertDialogHeader
              fontSize="1rem"
              fontWeight={"semibold"}
              textAlign={"center"}
              lineHeight={"1.5rem"}
              p="0"
              m="0 0 0.25rem"
            >
              Change Issue Category?
            </AlertDialogHeader>
            This will clear your current message. You'll need to provide new
            details for the selected category.
          </AlertDialogBody>

          <AlertDialogFooter
            p="1.25rem 0 0"
            m="0"
            borderTop={"1px solid #E2E4E9"}
          >
            <Button ref={cancelRef} colorScheme="brand" onClick={onClose}>
              No, Leave It
            </Button>
            <Button
              onClick={() => {
                onClose();
                onAction();
              }}
              variant={"outline"}
              ml={3}
            >
              Yes, Change It
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
