export class Stopwatch {
  // duration in seconds
  duration: number = 0;
  isRunning: boolean = false;
  private intervalID: ReturnType<typeof setInterval> | undefined = undefined;

  onDurationChange: (duration: number) => void;

  constructor(onDurationChange: (duration: number) => void) {
    this.onDurationChange = onDurationChange;
  }

  start() {
    if (this.isRunning) return;
    this.isRunning = true;
    this.intervalID = setInterval(() => {
      this.duration += 1;
      this.onDurationChange(this.duration);
    }, 1000);
  }

  stop() {
    if (!this.isRunning || !this.intervalID) return;
    clearInterval(this.intervalID);
    this.isRunning = false;
    this.intervalID = undefined;
  }
  reset() {
    clearInterval(this.intervalID);
    this.isRunning = false;
    this.intervalID = undefined;
    this.duration = 0;
    this.onDurationChange(this.duration);
  }
}
