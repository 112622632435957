import { Button, Circle, IconButtonProps, Square } from "@chakra-ui/react";

interface RecordButtonProps extends Partial<IconButtonProps> {
  isRecording: boolean;
  onClick: () => void;
  colorScheme?: string;
}

export const RecordButton = ({
  isRecording,
  onClick,
  colorScheme,
}: RecordButtonProps) => {
  return (
    <Circle
      size="50px"
      border="2px solid"
      borderColor={colorScheme ? `${colorScheme}.500` : "white"}
      as={Button}
      aria-label={"Record Video"}
      onClick={onClick}
    >
      {isRecording ? (
        <Square
          rounded={"sm"}
          size="24px"
          bg={colorScheme ? `${colorScheme}.500` : "error.500"}
          color="white"
        ></Square>
      ) : (
        <Circle size="40px" bg="error.500" color="white"></Circle>
      )}
    </Circle>
  );
};
