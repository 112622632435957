import { Grid, Stack } from "@chakra-ui/react";

import { ChangeEvent } from "react";
import { IContactDetails } from "../../../@types/IContactDetails";
import { useRecoilState } from "recoil";

import { setSessionRewindInfo } from "../../../utils/sessionRewind";
import { newHarkState } from "../atoms/NewHarkStateAtom";
import { validateItem } from "../utils/newHarkValidator";
import { validationErrorState } from "../atoms/validationErrorsState";
import { ContactInput } from "./ContactInput";
import { getContactDetailsSchema } from "../utils/validationSchemas";
type ContactDetailsProps = {
  handleContinue: () => void;
};
const ContactDetails = ({ handleContinue }: ContactDetailsProps) => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const [errors, setErrors] = useRecoilState(validationErrorState);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = target;
    if (name === "phone") value = value.replaceAll(" ", "");

    const contactDetails = {
      ...newHark.contactDetails,
      [name]: value,
    } as IContactDetails;

    setNewHark({ ...newHark, contactDetails });
    const contactDetailsSchema = getContactDetailsSchema();
    const error = validateItem(contactDetailsSchema[name], value);

    setErrors({ ...errors, [name]: error });

    // update session recorder with the user's email
    if (name === "email") setSessionRewindInfo({ user_email: value });
  };

  function checkForEnter(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      handleContinue();
    }
  }

  return (
    <Stack gap={4}>
      <Grid templateColumns={["repeat(1, 2fr)", "repeat(2, 1fr)"]} gap="4">
        <ContactInput
          label="Your first name"
          error={errors.firstName}
          value={newHark.contactDetails.firstName}
          name="firstName"
          onChange={handleChange}
          type="text"
          placeholder="First name"
          onKeyDown={checkForEnter}
        />

        <ContactInput
          label="Your last name"
          error={errors.lastName}
          value={newHark.contactDetails.lastName}
          name="lastName"
          onChange={handleChange}
          type="text"
          placeholder="Last name"
          onKeyDown={checkForEnter}
        />
      </Grid>

      <ContactInput
        label="Your email address"
        error={errors.email}
        value={newHark.contactDetails.email}
        name="email"
        onChange={handleChange}
        type="email"
        placeholder="Email address"
        onKeyDown={checkForEnter}
      />
    </Stack>
  );
};

export default ContactDetails;
