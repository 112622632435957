import React, { useRef, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { brandColorsThemeState } from "../../../../atoms/brandColorsThemeState";

type AudioVisualizationProps = {
  analyser: AnalyserNode | null;
  dataArray: Uint8Array;
};

const AudioVisualization: React.FC<AudioVisualizationProps> = ({
  analyser,
  dataArray,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  // You can adjust this value
  const colors = useRecoilValue(brandColorsThemeState);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas?.getContext("2d");
    if (!ctx || !analyser) return;

    const MIN_BAR_HEIGHT = canvas.height * 0.08; // 1% of canvas height

    const draw = () => {
      const bufferLength = analyser.frequencyBinCount;

      // Fetch the frequency data into our dataArray
      analyser.getByteFrequencyData(dataArray);

      if (!canvas) return;

      ctx.fillStyle = "rgb(255, 255, 255)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      const barWidth = canvas.width / bufferLength / 1.5;
      let barHeight;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        barHeight = dataArray[i];
        const normalizedBarHeight = (barHeight / 255) * canvas.height;

        // If not recording, use the minimum bar height.
        // If recording, ensure the bar height is never below the minimum

        barHeight = Math.max(normalizedBarHeight, MIN_BAR_HEIGHT);

        const y = canvas.height / 2 - barHeight / 2;
        ctx.fillStyle = colors["500"];
        ctx.fillRect(x, y, barWidth, barHeight);
        x += barWidth + 3; // 5 is the spacing between bars, adjust as needed
      }

      requestAnimationFrame(draw);
    };

    draw();
  }, [analyser, dataArray, colors]);

  return (
    <canvas
      style={{ marginTop: "1em" }}
      ref={canvasRef}
      width={450}
      height={70}
    />
  );
};

export default AudioVisualization;
