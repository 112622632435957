import { Box, Container, Stack, useSteps } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useLoaderData, useLocation, useParams } from "react-router-dom";
import { updateFavicon } from "./utils/updateFavicon";
import mixpanel from "mixpanel-browser";
import tinycolor from "tinycolor2";

import {
  getContactDetailsFromURL,
  getTicketIdFromURL,
} from "./utils/loadHarkDataFromUrl";
import { setSessionRewindInfo } from "../../utils/sessionRewind";
import { MixpanelEvents, trackMixpanelEvent } from "../../utils/mixpanelEvents";
import { NavController } from "./components/NavController";
import { getWorkflowType } from "./utils/getWorkflowType";
import { brandColorsThemeState } from "../../atoms/brandColorsThemeState";
import { newHarkState } from "./atoms/NewHarkStateAtom";
import CompanyLogo from "./components/CompanyLogo";
import { PrivacyPolicyTC } from "./components/PrivacyPolicyTC";
import { Company } from "../../@types/Company";
import { CustomField, Workflow } from "../../@types/Workflow";

export default function SendHarkPage() {
  const { companyID } = useParams();
  const loaderData = useLoaderData() as { company: { company: Company }; slugWorkflow: Workflow; customFields: [CustomField] };
  const company = loaderData.company?.company || {};
  const slugWorkflow = loaderData.slugWorkflow;
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const location = useLocation();

  const hasSurveyStep = slugWorkflow?.survey_custom_field_ids && slugWorkflow?.survey_custom_field_ids.length > 0;

  const defaultWorkflow = company.workflows.find((workflow) => workflow.default === true);
  
  const queryParams = new URLSearchParams(location.search);
  const slug = queryParams.get("slug");

  const isSlugValid = slugWorkflow ? true : false;
  const [brandColors, setBrandColors] = useRecoilState(brandColorsThemeState);
  const [, setLoading] = useState(false);
  const contactDetails = getContactDetailsFromURL(queryParams);
  const hasContactDetails = Boolean(contactDetails.email && contactDetails.firstName && contactDetails.lastName);
  
  const startingStep = () => {
    if (isSlugValid && hasContactDetails) {
      return 2;
    } else if (isSlugValid) {
      return 1;
    } else {
      return 0;
    }
  }

  const { activeStep, setActiveStep } = useSteps({
    index: startingStep(),
    count: hasSurveyStep ? 5 : 4,
  });

  useEffect(() => {
    setLoading(true);

    updateFavicon(company.favicon_url || company.logo_url);

    // update page title
    document.title = company.name;

    let selectedWorkflow;

    const contactDetails = getContactDetailsFromURL(queryParams);
    const ticket_id = getTicketIdFromURL(queryParams);

    const updateNewHark = {
      ...newHark,
      company: company,
      contactDetails,
      ticket_id,
    };

    const workflows = company.workflows || [];

    if (isSlugValid) {
      updateNewHark.workflow = slugWorkflow;
      selectedWorkflow = slugWorkflow;
    } else {
      selectedWorkflow =
        defaultWorkflow ||
        workflows[0];
    }
    
    setNewHark(updateNewHark);

    const workflowBrandColors = {
      ...brandColors,
      ...selectedWorkflow?.brand_colors,
    };

    workflowBrandColors.activeBg = tinycolor(workflowBrandColors[500])
      .setAlpha(0.05)
      .toRgbString();

    setBrandColors(workflowBrandColors);

    setLoading(false);

    setSessionRewindInfo({ company_name: company.name });

    // add the comnpany name as a super property
    mixpanel.register({
      company: company.name,
    });

    const workflowType = getWorkflowType(workflows, selectedWorkflow, slug);
    trackMixpanelEvent(MixpanelEvents.HARK_PAGE_LOADED, {
      "Workflow Type": workflowType,
      company: company.name,
      call_to_action_text: newHark.callToActionText,
    });

    if (workflowType === "Direct Link" || workflowType === "Default Only")
      trackMixpanelEvent(MixpanelEvents.WORKFLOW_SELECTED, {
        "Workflow Selection Type": "Automatic",
        Workflow: selectedWorkflow?.name,
        "Workflow Count": workflows.length,
        company: company.name,
      });
  }, [companyID]);

  return (
    <Stack px={2} py={[5, 10]} bg="brand.25" h={"100vh"} overflow={"scroll"}>
      <CompanyLogo />
      <Box display="flex" h="full" flexDir={"column"}>
      <NavController activeStep={activeStep} setActiveStep={setActiveStep}  />
        <Container
          maxW={["full", 750]}
          borderTop={"1px solid"}
          borderColor={"gray.100"}
          borderBottomRadius={"16px"}
          bg="white"
          py={2}
        >
          <PrivacyPolicyTC
            companyID={companyID}
            termsAndConditionsUrl={newHark.company?.terms_and_conditions_url}
            privacyPolicyUrl={newHark.company?.privacy_policy_url}
          />
        </Container>
      </Box>
    </Stack>
  );
}
