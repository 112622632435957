import mixpanel from "mixpanel-browser";

export enum MixpanelEvents {
  HARK_SUBMITTED = "Hark Submit Button Clicked",
  HARK_VALIDATION_FAILED = "Hark Submission Validation Failed",
  ERROR_SENDING_HARK = "Error Sending Hark",
  TAKE_PICTURE_CLICKED = "Attachments - Take Picture Clicked",
  PICTURE_SNAPPED = "Attachments - Take Picture Snapped",
  PICTURE_SAVED = "Attachments - Take Picture Saved",
  RETAKE_PICTURE_CLICKED = "Attachments - Take Picture Retake Clicked",
  ATTACH_FILE_CLICKED = "Attachments - Attach File Clicked",
  FILE_ATTACHED = "Attachments - File Attached",
  FILE_ATTACHMENT_SAVED = "Attachments - File Attached Saved",
  REMOVED_ATTACHMENT = "Attachments - Attachment Removed",
  HARK_RECEIVED_BY_API = "Hark Received via API",
  HARK_PAGE_LOADED = "Hark Page Loaded",
  WORKFLOW_SELECTED = "Workflow Selected",
  DEVICE_PERMISSIONS_ERROR = "Error Granting Device Permission",
  CUSTOMER_INDICATED_ISSUE_SOLVED = "Customer indicated issue was solved",
  DISPLAYED_SUGGESTED_ARTICLES = "Help Center Article Content Displayed to Customer",
  SUGGESTED_ARTICLE_CLICKED = "Customer Clicked on Help Center Article",
  CONTACT_INFO_ENTERED = "Contact Info Entered",
  QR_POPUP_OPENED = "Clicked on popup for qr code"
}
type MixpanelRecordingEvents =
  | "Recording Modal Opened"
  | "Recording Modal Opened"
  | "Recording Started"
  | "Recording Cancelled"
  | "Recording Complete"
  | "Recording Saved"
  | "Recording Deleted"
  | "Recording Retry"
  | "Recording Paused"
  | "Recording Resumed"
  | "Device Permission Error"
  | "Error Taking Photo"
  | "Error Changing Camera";

type MixpanelRecordingType = "Audio" | "Video" | "Screen" | "Text" | "Photo";

export const trackMixpanelRecordingEvent = (
  event: MixpanelRecordingEvents,
  recordType: MixpanelRecordingType
) => {
  mixpanel.track(event, { "Record type": recordType });
};

export const trackMixpanelEvent = (
  event: MixpanelEvents,
  properties?: { [key: string]: string | number | undefined }
) => {
  mixpanel.track(event, properties);
};
