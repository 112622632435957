import { Button, HStack, Image } from "@chakra-ui/react";
import { IoMdRefresh } from "react-icons/io";

interface PhotoPreviewProps {
  picturePreviewUrl: string;
  onRetry: () => void;
  onSave: () => void;
}
const PhotoPreview = ({
  picturePreviewUrl,
  onRetry,
  onSave,
}: PhotoPreviewProps) => {
  return (
    <>
      <Image src={picturePreviewUrl} width="100%" />
      <HStack
        width={"full"}
        justify="space-evenly"
        height={"fit-content"}
        position="fixed"
        bottom={10}
      >
        <Button
          onClick={onRetry}
          fontWeight="normal"
          width="fit-content"
          textAlign="right"
          variant="ghost"
          colorScheme={"blackAlpha"}
          color="white"
          leftIcon={<IoMdRefresh />}
          pl={0}
        >
          Retry
        </Button>
        <Button
          variant="solid"
          color="brand.buttonText"
          colorScheme={"brand"}
          onClick={onSave}
        >
          Save
        </Button>
        olor="black"
      </HStack>
    </>
  );
};

export default PhotoPreview;
