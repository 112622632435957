import Joi from "joi";
import { isValidPhoneNumber } from "react-phone-number-input";
import { CustomField } from "../../../@types/Workflow";

const requiredFieldMessages = {
  "any.required": "This field is required",
  "string.empty": "This field is required",
};

export function getNameSchema() {
  return Joi.string().required().messages(requiredFieldMessages);
}

export function getEmailSchema() {
  return Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email")
    .messages({
      ...requiredFieldMessages,
      "string.email": "Invalid email format",
    });
}

export function getContactDetailsSchema(): { [key: string]: Joi.Schema } {
  return {
    firstName: getNameSchema(),
    lastName: getNameSchema(),
    email: getEmailSchema(),
  };
}

export function getAddressSchema(requireAddress: boolean) {
  return requireAddress
    ? Joi.string().required().min(1).messages(requiredFieldMessages)
    : Joi.string().optional().allow("").empty("");
}

export function getPhoneNumberSchema(requirePhoneNumber: boolean) {
  const phoneSchema = Joi.string()
    .messages({
      ...requiredFieldMessages,
      "any.invalid": "Invalid phone number format",
    })
    .custom((value, helpers) => {
      if (!isValidPhoneNumber(String(value)))
        return helpers.error("any.invalid");
      return value;
    });
  return requirePhoneNumber
    ? phoneSchema.required()
    : phoneSchema.optional().allow("").empty("");
}

export function getAttachmentSchema(requireAttachments: boolean) {
  return requireAttachments
    ? Joi.array().items(Joi.any()).min(1).required()
    : Joi.array().items(Joi.any()).optional();
}

export function getRecordingSchema() {
  return Joi.object({
    type: Joi.valid("audio", "video", "screen", "text").required(),
    source: Joi.any().required(),
    mediaType: Joi.valid("mp3", "webm", "mp4", "text"),
    playbackUrl: Joi.string().optional(),
    duration: Joi.number().optional(),
  })
    .required()
    .messages({
      "object.base": `** Recording is required **`,
    });
}

export const getCustomFieldResponseSchema = (
  customFields: CustomField[]
): { [key: string]: Joi.StringSchema } => {
  return customFields.reduce((schema, customField) => {
    const fieldSchema = Joi.string().label(customField.name);
    return customField.required
      ? {
          ...schema,
          [customField.name]: fieldSchema
            .required()
            .messages(requiredFieldMessages),
        }
      : { ...schema, [customField.name]: fieldSchema.optional().allow("") };
  }, {});
};
