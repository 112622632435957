// source: https://www.developerway.com/posts/debouncing-in-react
import { useEffect, useMemo, useRef } from 'react';

const debounce = require('lodash.debounce');

export const useDebounce = (callback: () => void, delay: number): (() => void) => {
    const ref = useRef<() => void>();

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    const debouncedCallback = useMemo(() => {
        const func = () => {
            ref.current?.();
        };

        return debounce(func, delay);
    }, [delay]);

    return debouncedCallback;
};
  