import { Center, Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

interface CountdownProps {
  onFinished: () => void;
  color?: string;
}

const Countdown: React.FC<CountdownProps> = ({
  onFinished,
  color = "brand.500",
}) => {
  const [count, setCount] = useState<number>(3);

  useEffect(() => {
    if (count > -1) {
      const timer = setTimeout(() => {
        setCount(count - 0.5);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      onFinished();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  return (
    <Center
      position={"absolute"}
      top={0}
      left={0}
      zIndex={1000}
      width="full"
      height="full"
      backgroundColor="rgb(245,247,250, 0.5)"
    >
      <Text color={color} fontWeight={"bold"} fontSize={"8xl"}>
        {count <= 0 ? "Go!" : Math.ceil(count)}
      </Text>
    </Center>
  );
};

export default Countdown;
