import { Honeybadger } from "@honeybadger-io/react";

export function logVideoError(error: any, componentName: string): void {
    // Log the error to Honeybadger with the component name
    Honeybadger.notify(error, {
        context: {
            component: componentName
        }
    });
}
