import { Box, HStack, Heading, Image, Stack, useToast } from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import { MouseEvent, useEffect, useState } from "react";
import { MixpanelEvents, trackMixpanelEvent } from "../../utils/mixpanelEvents";
import { closeHarkTicket } from "../../api/hark";
import { getArticleByID } from "../../api/articles";
import { Article } from "../../@types/Article";
import { Company } from "../../@types/Company";
import { getCompanyById } from "../../api/companies";
import { ArticleHelpfulSlide } from "./components/ArticleHelpfulSlide";
import { AgentContactSlide } from "./components/AgentContactSlide";
import { CustomToast } from "./components/CustomToast";

const toastMessages = {
  agentShouldStillContact:
    "Glad to hear the article was helpful. We’re reviewing your inquiry and will be in touch shortly",
  agentShouldNotContact:
    "Glad to hear the article was helpful and you no longer need support. If you do need anything else, please send a new inquiry.",

  articleNotHelpful:
    "Thank you for getting in touch. We will get back to your shortly.",
};

export default function ReadArticlePage() {
  const toast = useToast();

  const { harkID, articleID } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const workflowSlug = queryParams.get("workflow");
  const companyID = queryParams.get("company");
  const [company, setCompany] = useState<Company>();

  const [article, setArticle] = useState<Article>({
    html_content: "",
  } as Article);
  const [isOpenHelpfulSlide, setIsoOpenHelpfulSlide] = useState(false);
  const [isOpenAgentContactSlide, setIsoOpenAgentContactSlide] =
    useState(false);

  const [feedback, setFeedback] = useState({
    isHelpful: null as boolean | null,
    shouldAgentContact: null as boolean | null,
  });

  useEffect(() => {
    const loadCompany = async () => {
      const data = await getCompanyById(String(companyID));
      setCompany(data.company);
    };

    loadCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function loadSuggestedArticles() {
      const article = await getArticleByID(
        String(articleID),
        String(companyID),
        String(workflowSlug)
      );
      setArticle(article);
      trackMixpanelEvent(MixpanelEvents.DISPLAYED_SUGGESTED_ARTICLES);
    }

    loadSuggestedArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsoOpenHelpfulSlide(true);
    }, 1500);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleISHelpfulClick = (event: MouseEvent<HTMLButtonElement>) => {
    const { value } = event.currentTarget;
    const isHelpful = value === "yes" ? true : false;
    const newFeedbackValue = {
      ...feedback,
      isHelpful,
    };
    setFeedback(newFeedbackValue);
    setIsoOpenHelpfulSlide(false);

    if (isHelpful) setIsoOpenAgentContactSlide(true);
    else
      toast({
        duration: 8000,
        position: "top-right",
        render: ({ onClose }) => (
          <CustomToast
            message={toastMessages.articleNotHelpful}
            onClose={onClose}
          />
        ),
      });
  };

  const handleAgentContatClick = async (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    const { value } = event.currentTarget;
    const shouldAgentContact = value === "yes" ? true : false;
    const newFeedbackValue = {
      ...feedback,
      shouldAgentContact,
    };
    setFeedback(newFeedbackValue);

    if (!shouldAgentContact) await handleCloseTicket();
    setIsoOpenAgentContactSlide(false);

    const message = shouldAgentContact
      ? toastMessages.agentShouldStillContact
      : toastMessages.agentShouldNotContact;

    toast({
      duration: 8000,
      position: "top-right",
      render: ({ onClose }) => (
        <CustomToast message={message} onClose={onClose} />
      ),
    });
  };

  const handleCloseTicket = async () => {
    try {
      trackMixpanelEvent(MixpanelEvents.CUSTOMER_INDICATED_ISSUE_SOLVED);
      await closeHarkTicket(String(harkID));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Stack gap={10} width="full" minH="100vh" bg="white">
      <HStack gap={5} px={[5, 20]} pt={10}>
        <Image maxH={16} src={company?.logo_url} />
        <Heading fontSize="xl" fontWeight="bold">
          {article.title}
        </Heading>
      </HStack>
      <Box
        dangerouslySetInnerHTML={{ __html: article.html_content }}
        px={[5, 20]}
        width="full"
      ></Box>

      <ArticleHelpfulSlide
        isHelpful={feedback.isHelpful}
        handleClick={handleISHelpfulClick}
        isOpen={isOpenHelpfulSlide}
      />
      <AgentContactSlide
        shouldAgentContact={feedback.shouldAgentContact}
        handleClick={handleAgentContatClick}
        isOpen={isOpenAgentContactSlide}
      />
    </Stack>
  );
}
