import { useState, useEffect } from "react";

export const useAudioVisualization = ({
  mediaStream,
}: {
  mediaStream: MediaStream | null;
}) => {
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const [analyser, setAnalyser] = useState<AnalyserNode | null>(null);
  const [dataArray, setDataArray] = useState<Uint8Array>(new Uint8Array(0));

  useEffect(() => {
    if (mediaStream) {
      const newAudioContext = new AudioContext();
      setAudioContext(newAudioContext);
      const source = newAudioContext.createMediaStreamSource(mediaStream);
      const newAnalyser = newAudioContext.createAnalyser();
      newAnalyser.fftSize = 256; // Change if needed
      source.connect(newAnalyser);
      setAnalyser(newAnalyser);
    }

    return () => {
      audioContext?.close(); // Close the audio context to release resources
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaStream]);


  useEffect(() => {
    if (analyser) {
      const bufferLength = analyser.frequencyBinCount;
      const newdataArray = new Uint8Array(bufferLength);
      setDataArray(newdataArray);
    }
  }, [analyser]);

  return {
    analyser,
    dataArray,
  };
};
