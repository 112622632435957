import { Box } from "@chakra-ui/react";

export const PausedAudioAnimation = () => (
  <Box height={24} alignItems={"center"} display="flex" gap={"2px"}>
    {new Array(90).fill(false).map((_, index) => (
      <Box
        key={index}
        bg="gray.100"
        width={"3px"}
        height={Math.random() * 30}
      />
    ))}
  </Box>
);
