import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Text,
  FormErrorMessage,
  Stack,
  Box,
} from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { newHarkState } from "../../atoms/NewHarkStateAtom";
import { ChangeEvent, useEffect } from "react";
import { IContactDetails } from "../../../../@types/IContactDetails";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { validationErrorState } from "../../atoms/validationErrorsState";
import { ContactInput } from "../ContactInput";
import { validateItem } from "../../utils/newHarkValidator";
import {
  getEmailSchema,
  getPhoneNumberSchema,
} from "../../utils/validationSchemas";

export const PreferredContactMethod = () => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const [errors, setErrors] = useRecoilState(validationErrorState);
  const selectedWorkflow = newHark.workflow;
  const hidePreferredContactMethod =
    selectedWorkflow?.settings?.hidePreferredContactMethod;

  const contactMethods = selectedWorkflow?.settings?.contactMethods || [
    "email",
    "phone",
  ];
  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = target;
    const contactDetails = {
      ...newHark.contactDetails,

      [name]: value,
    } as IContactDetails;
    setNewHark({ ...newHark, contactDetails });
  };

  useEffect(() => {
    const contactDetails = { ...newHark.contactDetails };
    contactDetails.preferredPhone =
      contactDetails.preferredPhone || contactDetails.phone;
    contactDetails.preferredEmail =
      contactDetails.preferredEmail || contactDetails.email;
    setNewHark({ ...newHark, contactDetails });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return hidePreferredContactMethod ? (
    <></>
  ) : (
    <Stack id="preferredContactSection">
      <FormControl isInvalid={!!errors.communicationMethod}>
        <RadioGroup
          size="sm"
          name="communication_method"
          onChange={(e: "email" | "phone") => {
            setNewHark({
              ...newHark,
              contactDetails: {
                ...newHark.contactDetails,
                communicationMethod: e,
              },
            });
          }}
          value={newHark.contactDetails.communicationMethod}
        >
          <Stack direction="row">
            {contactMethods.map((method, i) => (
              <Box
                key={i}
                bg="white"
                borderRadius={"0.5rem"}
                p="0.5rem 0.75rem"
                border={"1px solid"}
                borderColor={"gray.100"}
              >
                <Radio
                  key={method}
                  value={method}
                  isChecked={
                    newHark.contactDetails.communicationMethod === method
                  }
                  name="communicationMethod"
                  onChange={handleChange}
                  colorScheme="brand"
                >
                  <Text
                    textTransform={method === "SMS" ? "none" : "capitalize"}
                  >
                    {method}
                  </Text>
                </Radio>
              </Box>
            ))}
          </Stack>
        </RadioGroup>
        <FormErrorMessage>{errors.communicationMethod}</FormErrorMessage>
      </FormControl>
      {newHark.contactDetails.communicationMethod === "email" ? (
        <ContactInput
          label="Please enter your email address"
          error={errors.preferredEmail}
          value={newHark.contactDetails.preferredEmail}
          name="preferredEmail"
          onChange={(e) => {
            const { value } = e.target;
            setNewHark({
              ...newHark,
              contactDetails: {
                ...newHark.contactDetails,
                preferredEmail: value,
              },
            });
            const error = validateItem(getEmailSchema(), value);

            setErrors({ ...errors, preferredEmail: error });
          }}
          type="email"
          placeholder="Email address"
        />
      ) : (
        <FormControl isInvalid={!!errors.preferredPhone}>
          <FormLabel fontSize="sm">Please enter your phone number</FormLabel>

          <PhoneInput
            className={!!errors.phone ? "PhoneInput-inValid" : ""}
            international
            countryCallingCodeEditable={false}
            value={newHark.contactDetails.preferredPhone}
            onChange={(value: string) => {
              setNewHark({
                ...newHark,
                contactDetails: {
                  ...newHark.contactDetails,
                  preferredPhone: value,
                },
              });
              const error = validateItem(getPhoneNumberSchema(true), value);
              setErrors({
                ...errors,
                preferredPhone: error,
              });
            }}
            defaultCountry="US"
          />
          <FormErrorMessage>{errors.preferredPhone}</FormErrorMessage>
        </FormControl>
      )}
    </Stack>
  );
};
