import { useRecoilValue } from "recoil";

import { AudioSnapShot } from "./RecordAudio/AudioSnapShot";
import { newHarkState } from "../atoms/NewHarkStateAtom";
import VideoSnapShot from "./RecordVideo/VideoSnapshot";
import { TextSnapShot } from "./WriteText/TextSnapShot";

// TODO! refactor recording Snapshot

export const RecordingSnapshot = ({ showDeleteIcon = true }) => {
  const newHark = useRecoilValue(newHarkState);

  return (
    <>
      {newHark.recording?.type === "audio" && (
        <AudioSnapShot showDeleteIcon={showDeleteIcon} />
      )}
      {newHark.recording?.type === "text" && (
        <TextSnapShot showDeleteIcon={showDeleteIcon} />
      )}
      {(newHark.recording?.type === "video" ||
        newHark.recording?.type === "screen") && (
        <VideoSnapShot showDeleteIcon={showDeleteIcon} />
      )}
    </>
  );
};
