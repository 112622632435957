import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function uploadFileToCloudStorage(
  file: File,
  company: string
): Promise<string> {
  const fileExt = file.name?.split('.').pop();
  const newFileName = uuidv4() + (fileExt ? `.${fileExt}` : '');
  const res = await getCloudStorageSignedUrl(newFileName, company);
  const { signed_url, file_name } = res;
  const config = {
    method: "PUT",
    url: signed_url,
    headers: {
      "Content-Type": "application/octet-stream",
    },
    data: file,
  };
  try {
    const result = await axios(config);
    console.log(result, "Result from uploading recording.")
    return file_name;
  } catch (error) {
    console.log(error, "Error uploading recording! GOING TO TRY AGAIN.")
    const tryAgain = await axios(config);
    console.log(tryAgain, "Try again result")
    return file_name;
  }
}

async function getCloudStorageSignedUrl(
  fileName: string,
  company: string
): Promise<{ signed_url: string; file_name: string }> {
  const response = await axios.get(
    `${BASE_URL}/utils/generate_presigned_url/?file_name=${fileName}&company=${company}`
  );
  return response.data;
}
