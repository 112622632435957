import { Button, ButtonProps } from "@chakra-ui/react";

export type HarkButtonVariant = "solid" | "outline" | "ghost";

export interface HarkButtonProps extends ButtonProps {
  variant?: "solid" | "outline" | "ghost";
}
export const HarkButton = ({
  variant = "solid",
  ...restProps
}: HarkButtonProps) => {
  const variantStyles = {
    outline: { _hover: { bg: "brand.activeBg" } },
    solid: { color: "brand.buttonText" },
    ghost: {
      color: "brand.secondaryButtonText",
      _hover: { border: "1px solid", borderColor: "brand.secondaryButtonText" },
    },
  };

  return (
    <Button
      transition={"all 0.2s ease-in-out"}
      colorScheme="brand"
      variant={variant}
      {...variantStyles[variant]}
      {...restProps}
    />
  );
};
