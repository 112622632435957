import { FiAlertCircle } from "react-icons/fi";
import {
  FormControl,
  Input,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Icon,
  List,
  ListItem,
} from "@chakra-ui/react";

import { useRecoilState } from "recoil";
import { useEffect, useRef, useState } from "react";
import { newHarkState } from "../atoms/NewHarkStateAtom";
import { validationErrorState } from "../atoms/validationErrorsState";
import { useCombobox } from "downshift";
import { useDebounce } from "../../../utils/useDebounce";
import { Loader } from "@googlemaps/js-api-loader";

interface LocationSearchInputProps {
  isRequired: boolean;
}

const LocationSearchInput = ({ isRequired }: LocationSearchInputProps) => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const { contactDetails } = newHark;
  const [errors, setErrors] = useRecoilState(validationErrorState);

  const [selected, setSelected] = useState({});

  const [items, setItems] = useState([]);
  const autocompleteRef = useRef(null);

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    setInputValue,
    inputValue,
  } = useCombobox({
    onInputValueChange({ inputValue, selectedItem }) {
      handleInputChange();
    },
    items: items as { description: string; place_id: string }[], // Add type annotation to items array
    itemToString(item) {
      return item ? item.description : "";
    },
    onSelectedItemChange({ selectedItem }) {
      if (selectedItem) {
        setSelected(selectedItem);

        setNewHark({
          ...newHark,
          contactDetails: {
            ...contactDetails,
            address: selectedItem.description,
            addressID: selectedItem.place_id,
          },
        });

        setErrors({ ...errors, address: "" });
      }
    },
  });

  const [service, setService] =
    useState<null | google.maps.places.AutocompleteService>(null);

  useEffect(() => {
    const loader = new Loader({
      apiKey: String(process.env.REACT_APP_GOOGLE_MAPS_API_KEY),
      libraries: ["places"],
    });
    loader
      .importLibrary("places")
      .then((google) => {
        setService(new google.AutocompleteService());
      })
      .catch((e) => {
        console.error("Problem loading Google Maps API", e);
      });
  }, []);

  const clearInput = () => {
    setInputValue("");
    setItems([]);
  };

  const displaySuggestions = function (items: any, status: any) {
    if (status !== google.maps.places.PlacesServiceStatus.OK || !items) {
      console.error(status);
      return;
    }

    setItems(items);
  };

  const debouncedRequest = useDebounce(() => {
    // Dont send request if input is empty
    if (!inputValue) {
      setItems([]);
      return;
    }

    // send requests to Google API
    // access to latest state here
    service?.getQueryPredictions({ input: inputValue }, displaySuggestions);
  }, 500);

  const handleInputChange = () => {
    debouncedRequest();
  };

  const inputValueContainsMilitaryAddress = (address: string) => {
    const lowerCaseAddress = String(address).toLocaleLowerCase();
    return lowerCaseAddress.includes("apo") || lowerCaseAddress.includes("fpo");
  };

  const handleBlur = () => {
    if (inputValueContainsMilitaryAddress(inputValue)) {
      setErrors({
        ...errors,
        address: undefined,
      });
      setNewHark({
        ...newHark,
        contactDetails: {
          ...contactDetails,
          address: inputValue,
        },
      });
    } else if (
      (selected as { description?: string })?.description !== inputValue
    ) {
      setErrors({
        ...errors,
        address: "Please select an address from the list that appears",
      });
      clearInput();
      setNewHark({
        ...newHark,
        contactDetails: {
          ...contactDetails,
          address: "",
        },
      });
    }
  };

  return (
    <FormControl isInvalid={!!errors.address}>
      <InputGroup zIndex={100} width={"100%"} display={"block"}>
        <FormLabel fontSize="sm" display={"block"} {...getLabelProps()}>
          Your address ({isRequired ? "required" : "optional"})
        </FormLabel>
        <InputGroup zIndex={100}>
          <Input
            ref={autocompleteRef}
            _invalid={{ border: "1px solid", borderColor: "border.error" }}
            _focus={{
              borderColor: errors.address ? "border.error" : "border.primary",
              boxShadow: "none",
            }}
            bg={contactDetails.address ? "#FAFAFA" : "white"}
            width={"100%"}
            {...getInputProps()}
            onBlur={handleBlur}
          />
        </InputGroup>
        <List
          position={"absolute"}
          bg={"white"}
          marginTop={1}
          width={"100%"}
          {...getMenuProps()}
          borderBottomLeftRadius={"160px"}
        >
          {isOpen &&
            items.map((item: any, index: number) => (
              <ListItem
                key={index}
                width={"100%"}
                padding={2}
                {...getItemProps({ item, index })}
                backgroundColor={
                  highlightedIndex === index ? "gray.100" : "white"
                }
                fontSize={"sm"}
                cursor={"pointer"}
              >
                <span>{item.description}</span>
              </ListItem>
            ))}
        </List>

        {!!errors.address && (
          <InputRightElement>
            <Icon color={"text.error.secondary"} as={FiAlertCircle}></Icon>
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage color="text.error">{errors.address}</FormErrorMessage>
    </FormControl>
  );
};

export default LocationSearchInput;
