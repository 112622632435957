export const colors = {
  primary: {
    25: "#F6FAFE",
    50: "#E8F1FE",
    100: "#D1E3FB",
    200: "#A4C8F8",
    300: "#649EF5",
    400: "#4083F4",
    500: "#2E69DA",
    600: "#02438F",
    700: "#023877",
    800: "#012D5F",
    900: "#42307D",
  },
  secondary: {
    25: "#F7FEFD",
    50: "#ECFBF9",
    100: "#DAF7F4",
    200: "#C9F2EE",
    300: "#A7EBE2",
    400: "#89E2D8",
    500: "#55D9CB",
    600: "#48978C",
    700: "#3D8078",
    800: "#326B64",
    900: "#1D403C",
  },

  gray: {
    25: "#FCFCFD",
    50: "#F9FAFB",
    100: "#F2F4F7",
    200: "#E5E7EB",
    300: "#D0D5DD",
    400: "#98A2B3",
    500: "#667085",
    600: "#475467",
    700: "#344054",
    800: "#1F2A37",
    900: "#101828",
  },

  orange: {
    50: "#FEF3EB",
    500: "#F17B2C",
    700: "#B93815",
  },

  success: {
    50: "#ECFDF3",
    100: "#D1FADF",
    500: "#12B76A",
    700: "#027A48",
  },

  error: {
    50: "#FEF3F2",
    100: "#FEE4E2",
    200: "#FECDCA",
    500: "#F04438",
  },

  text: {
    primary: "#121926",
    secondary: "#344054",
    tertiary: "#475467",
    error: "#D92D20",
    "error.secondary": "#F04438",
  },

  border: { primary: "#526CC7", gray: "#EAECF0", error: "#FDA29B" },
};

export const fonts = {
  heading: `Inter, sans-serif`,
  body: `Inter, sans-serif`,
};

export const components = {
  Modal: {
    baseStyle: {
      dialogContainer: {
        "@supports(height: -webkit-fill-available)": {},
        height: "100%",
      },
    },
  },
};
