import { Tag, TagLeftIcon, TagLabel } from "@chakra-ui/react";

import { FaCircle } from "react-icons/fa";
import { HarkStatus, HarkStatusMap } from "../../../@types/IHark";

interface StatusTagProps {
  status: HarkStatus;
}

const StatusTag = ({ status }: StatusTagProps) => {
  const color = status === "completed" ? "success" : "orange";
  return (
    <Tag color={`${color}.500`} rounded="full" size="sm" bg={`${color}.50`}>
      <TagLeftIcon as={() => <FaCircle size="8" />} />
      <TagLabel
        color={`${color}.700`}
        ml={1}
        fontWeight="semibold"
        textTransform="capitalize"
      >
        {HarkStatusMap[status]}
      </TagLabel>
    </Tag>
  );
};

export default StatusTag;
