import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Box,
  Text,
  HStack,
} from "@chakra-ui/react";
import { useRef } from "react";
import { IoMdRefresh } from "react-icons/io";
import { useRecoilState } from "recoil";

import { secondsToHH_MM_SS } from "../../../../utils/dateFormatter";
import { generateThumbnail } from "../../../../utils/generateThumbnail";
import { trackMixpanelRecordingEvent } from "../../../../utils/mixpanelEvents";
import { newHarkState } from "../../atoms/NewHarkStateAtom";
import { HarkButton } from "../../../../components/HarkButton";
import { logVideoError } from "../../../../utils/videoError";

interface VideoPlaybackProps {
  duration: number;
  closeCamera: () => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onRetry: () => void;
}

const ScreenPlayback = ({
  duration,
  closeCamera,
  onClose,
  isOpen,
  onRetry,
}: VideoPlaybackProps) => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);

  const vidRef = useRef(null);

  const closeModal = () => {
    closeCamera();
    onClose();
  };

  const handleSaveClick = () => {
    trackMixpanelRecordingEvent("Recording Saved", "Screen");
    generateThumbnail((blob) => {
      setNewHark({ ...newHark, thumbNail: blob });
    });
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      closeOnOverlayClick={false}
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md">
          <HStack justify="space-between">
            <Text>Screen Recording</Text>
            <Button
              onClick={onRetry}
              fontWeight="normal"
              width="fit-content"
              textAlign="right"
              variant="outline"
              leftIcon={<IoMdRefresh />}
            >
              Retry
            </Button>
          </HStack>
        </ModalHeader>

        <ModalBody>
          <Box>
            <figure>
              <video 
                ref={vidRef} 
                controls 
                playsInline
                onError={(e) => logVideoError(e, "ScreenPlayBack.tsx")}
              >
                {newHark.recording && newHark.recording.type === "screen" && (
                  <source
                    src={window.URL.createObjectURL(
                      newHark.recording?.source as Blob
                    )}
                    type="video/mp4"
                  />
                )}
              </video>
            </figure>
          </Box>
        </ModalBody>

        <ModalFooter justifyContent="space-between">
          <Text>{secondsToHH_MM_SS(duration)}</Text>
          <HarkButton onClick={handleSaveClick}>Save</HarkButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ScreenPlayback;
