import {
  Box,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Image,
  ModalBody,
  Center,
  Spinner,
  ModalFooter,
  Popover,
  Button,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Select,
  HStack,
  Heading,
} from "@chakra-ui/react";
import { FiSettings } from "react-icons/fi";
import { IoMdRefresh } from "react-icons/io";
import { HarkButton } from "../../../../components/HarkButton";
import { logVideoError } from "../../../../utils/videoError";

interface DesktopTakePhotoModalProps {
  isOpen: boolean;
  onClose: () => void;
  image: Blob | undefined;
  picturePreviewUrl: string;
  retakePhoto: () => void;
  savePhoto: () => void;
  videoStream: MediaStream | undefined;
  takePhoto: () => void;
  changeCamera: (cameraID: string) => void;
  cameras: MediaDeviceInfo[];
  closeCamera: () => void;
  loading: boolean;
}

const DesktopTakePhotoModal = ({
  isOpen,
  onClose,
  image,
  picturePreviewUrl,
  retakePhoto,
  savePhoto,
  videoStream,
  takePhoto,
  cameras,
  changeCamera,
  closeCamera,
  loading,
}: DesktopTakePhotoModalProps) => {
  return (
    <>
      <Modal
        size={"xl"}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={"md"}>
            <HStack justifyContent={"space-between"} width={"full"}>
              <Heading size={"md"} as="h2">
                Take a picture
              </Heading>
              {image && (
                <Button
                  variant="outline"
                  colorScheme="gray"
                  mr={3}
                  onClick={retakePhoto}
                  isDisabled={!videoStream}
                  leftIcon={<IoMdRefresh />}
                >
                  Retake
                </Button>
              )}
            </HStack>
          </ModalHeader>
          <ModalBody>
            {loading && (
              <Center paddingY={20}>
                <Spinner
                  thickness="5px"
                  speed="1s"
                  emptyColor="gray.200"
                  color="brand.100"
                  size="xl"
                />
              </Center>
            )}
            {image ? (
              <Image src={picturePreviewUrl} width="100%" />
            ) : (
              <Box width="full" position="relative">
                <video
                  style={{ width: "100%" }}
                  id="photo-video"
                  playsInline
                  muted
                  autoPlay
                  onError={(e) => logVideoError(e, "DesktopModal.tsx")}
                ></video>
                <Popover placement="right-start">
                  <PopoverTrigger>
                    <IconButton
                      position="absolute"
                      bottom={0}
                      left={0}
                      variant="ghost"
                      aria-label="Send email"
                      color="white"
                      rounded="full"
                      size="sm"
                      colorScheme="brand"
                      icon={<FiSettings size={20} />}
                    />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                      <Select
                        onChange={(e) => {
                          changeCamera(e.target.value);
                        }}
                        placeholder="Select Camera"
                      >
                        {cameras.map((camera) => (
                          <option key={camera.deviceId} value={camera.deviceId}>
                            {camera.label}
                          </option>
                        ))}
                      </Select>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Box>
            )}
          </ModalBody>

          <ModalFooter>
            {image ? (
              <Button
                isDisabled={!videoStream}
                _hover={{ bg: "brand.100" }}
                bg="brand.50"
                variant="solid"
                color="ButtonText"
                onClick={savePhoto}
              >
                Save
              </Button>
            ) : (
              <>
                <Button
                  variant="outline"
                  colorScheme="gray"
                  mr={3}
                  onClick={() => {
                    onClose();
                    closeCamera();
                  }}
                  isDisabled={loading}
                >
                  Cancel
                </Button>
                <HarkButton
                  isDisabled={!videoStream}
                  variant="solid"
                  onClick={takePhoto}
                >
                  Snap
                </HarkButton>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DesktopTakePhotoModal;
