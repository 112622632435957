import { HStack, Stack, Text } from "@chakra-ui/react";

import { IconButton } from "../TooltipButton";
import { useRecoilState } from "recoil";
import { newHarkState } from "../../atoms/NewHarkStateAtom";
import { trackMixpanelRecordingEvent } from "../../../../utils/mixpanelEvents";
import { HiOutlineTrash } from "react-icons/hi";
import { FiFileText } from "react-icons/fi";
import { DD_MON_YYYY } from "../../../../utils/dateFormatter";
import { PreformattedText } from "../PreFormattedText";

export const TextSnapShot = ({ showDeleteIcon = true }) => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);

  const handleDeleteRecording = () => {
    trackMixpanelRecordingEvent("Recording Deleted", "Text");
    setNewHark({
      ...newHark,
      recording: null,
    });
  };

  return (
    <Stack p={5} gap={2}>
      <HStack justify={"space-between"}>
        <HStack gap={4}>
          <FiFileText />
          <Text color={"gray.800"} fontWeight={"bold"} fontSize={16}>
            "Text Message - {DD_MON_YYYY()}
          </Text>
        </HStack>
        {showDeleteIcon && (
          <IconButton
            aria-label="Delete recording"
            onClick={handleDeleteRecording}
            size="sm"
            color="gray.400"
            bg="white"
            _hover={{ bg: "error.100", color: "error.500" }}
          >
            <HiOutlineTrash size={20} />
          </IconButton>
        )}
      </HStack>
      <PreformattedText textAlign={"left"} maxH={120} overflowY={"scroll"}>
        {newHark.recording?.source as string}
      </PreformattedText>
    </Stack>
  );
};
