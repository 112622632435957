import { Icon } from "@chakra-ui/react";

export const RecordAudioIcon = () => (
  <Icon viewBox="0 0 16 18" fill="none">
    <path
      d="M14.6663 9V9.83333C14.6663 13.5152 11.6816 16.5 7.99967 16.5C4.31778 16.5 1.33301 13.5152 1.33301 9.83333V9M7.99967 13.1667C6.15873 13.1667 4.66634 11.6743 4.66634 9.83333V4.83333C4.66634 2.99238 6.15873 1.5 7.99967 1.5C9.84062 1.5 11.333 2.99238 11.333 4.83333V9.83333C11.333 11.6743 9.84062 13.1667 7.99967 13.1667Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
