import { HStack, Icon, Tag, Text, Stack } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { newHarkState } from "../../atoms/NewHarkStateAtom";
import { FaRegFile } from "react-icons/fa";

import { formatBytes } from "../../../../utils/fileSizeFormatter";
import { FiFilm, FiImage, FiVolume2 } from "react-icons/fi";
import { IconType } from "react-icons";

const fileIcons: { [key: string]: IconType } = {
  audio: FiVolume2,
  video: FiFilm,
  image: FiImage,
  other: FaRegFile,
};

export const AdditionalInfo = ({isSurvey = false}) => {
  const newHark = useRecoilValue(newHarkState);
  
  const customFields = newHark.customFieldResponses;
  const surveyCustomFields = newHark.workflow.custom_fields?.filter((field) => field.is_survey === true) || [];
  const afterRecordCustomFields = newHark.workflow.custom_fields?.filter((field) => !field.is_survey) || [];

  const arrToUse = isSurvey ? surveyCustomFields : afterRecordCustomFields;

  return (
    <Stack gap={2} color="text.tertiary">
      {newHark.attachments.map((attachment, i) => (
        <HStack
          key={i}
          p={2}
          bg="white"
          alignItems="start"
          justifyContent="space-between"
          borderRadius="lg"
          align={"center"}
        >
          <HStack justify={"center"}>
            <Icon
              color="brand.700"
              as={
                fileIcons[attachment.type]
                  ? fileIcons[attachment.type]
                  : fileIcons.other
              }
              w="4"
              h="4"
            />
            <Text fontSize={"0.875rem"} color="gray.700" noOfLines={1}>
              {attachment.name}
            </Text>
          </HStack>
          <Tag color="gray.500" size="sm">
            {formatBytes(Number(attachment.size))}
          </Tag>
        </HStack>
      ))}

      {newHark.workflow?.settings?.collectPhoneNumber &&
        newHark.contactDetails.phone && (
          <HStack
            p={2}
            bg="white"
            alignItems="start"
            justifyContent="space-between"
            borderRadius="lg"
            align={"center"}
          >
            <Text>Phone Number</Text>
            <Text>{newHark.contactDetails.phone}</Text>
          </HStack>
        )}

      {newHark.workflow?.settings?.collectAddress &&
        newHark.contactDetails.address && (
          <HStack
            p={2}
            bg="white"
            alignItems="start"
            justifyContent="space-between"
            borderRadius="lg"
            align={"center"}
          >
            <Text>Physical Address</Text>
            <Text>{newHark.contactDetails.address}</Text>
          </HStack>
        )}

      {arrToUse.map((field, i) => (
        <HStack
          p={2}
          key={i}
          bg="white"
          alignItems="start"
          justifyContent="space-between"
          borderRadius="lg"
          align={"center"}
        >
          <Text>{field.name}</Text>
          <Text>{customFields[field.name]}</Text>
          {/* <Text>{Object.entries(customFields)[field.name][value]}</Text> */}
        </HStack>
      ))}


    </Stack>
  );
};
