import { useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { trackMixpanelRecordingEvent } from "../../../utils/mixpanelEvents";
import { Stopwatch } from "../../../utils/stopwatch";
import { newHarkState } from "../atoms/NewHarkStateAtom";
import { useToast } from "@chakra-ui/react";
import { RecordingOption } from "../../../@types/Workflow";
import { captureVideoFrame } from "../../../utils/generateThumbnail";

const recordTypeMapping: { [key: string]: "Screen" | "Video" | "Audio" } = {
  audio: "Audio",
  video: "Video",
};

const constraints = {
  video: { facingMode: "environment" },
  audio: true,
};

type useRecord = {
  recordType: RecordingOption;
  onRecordingStopped?: () => void;
  onRecordingStarted?: () => void;
  handleCancel?: () => void;
};

export const useRecorder = ({
  recordType,
  onRecordingStarted,
  onRecordingStopped,
  handleCancel,
}: useRecord) => {
  const toast = useToast();
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const [duration, setDuration] = useState<number>(0);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [audioPerms, setAudioPerms] = useState<boolean>(false);

  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);
  const [, setMicrophones] = useState<MediaDeviceInfo[]>([]);
  const recordingMediaStream = useRef<MediaStream | null>(null);
  const [cameraFacingMode, setCameraFacingMode] = useState("environment");

  const recorder = useRef<MediaRecorder | null>();

  const [loading, setLoading] = useState(false);
  const [isPausedRecording, setIsPausedRecording] = useState(false);

  const stopWatch = new Stopwatch((duration) => setDuration(duration));

  const enumerateDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      setCameras(videoDevices);
      const audioDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      setMicrophones(audioDevices);
    } catch (err) {
      console.log("Error Enumerating device", err);
    }
  };

  const requestAccessToMediaDevices = async () => {
    try {
      setLoading(true);
      const mediaStream: MediaStream =
        await navigator.mediaDevices.getUserMedia(
          recordType === "video" ? constraints : { audio: true }
        );
      setLoading(false);

      recordingMediaStream.current = mediaStream;
      setAudioPerms(true);
      enumerateDevices();
    } catch (err: any) {
      setLoading(false);
      toast({
        title: "Error requesting access to media device",
        status: "error",
        duration: 20000,
        isClosable: true,
        position: "top-left",
        variant: "top-accent",
        description: err.message,
      });
      if (handleCancel) handleCancel();
    }
  };

  const reverseCameraFacing = async () => {
    const facingMode =
      cameraFacingMode === "environment" ? "user" : "environment";
    setCameraFacingMode(facingMode);

    const oldStream = recordingMediaStream.current;
    oldStream?.getTracks().forEach((t) => t.stop());

    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode },
        audio: true,
      });
      recordingMediaStream.current = mediaStream;
      let video = document.getElementById("record-video") as HTMLVideoElement;
      video.srcObject = mediaStream;
    } catch (err) {
      trackMixpanelRecordingEvent(
        "Device Permission Error",
        recordTypeMapping[recordType]
      );
    }
  };

  const changeCamera = async (deviceId: string) => {
    const camera = cameras.find((camera) => camera.deviceId === deviceId);
    if (!camera) return;
    const oldStream = recordingMediaStream.current;
    oldStream?.getTracks().forEach((t) => t.stop());

    const mediaStream = await navigator.mediaDevices.getUserMedia({
      video: { deviceId: camera.deviceId },
      audio: true,
    });
    recordingMediaStream.current = mediaStream;
    let video = document.getElementById("record-video") as HTMLVideoElement;
    video.srcObject = mediaStream;
  };

  const stopRecording = async () => {
    recorder.current?.stop();
  };

  const pauseRecording = async () => {
    recorder.current?.pause();
    setIsRecording(false);
    setIsPausedRecording(true);
  };

  const stopAllMediaStreams = () => {
    recordingMediaStream.current?.getTracks().forEach((t) => t.stop());
  };

  const resumeRecording = async () => {
    recorder.current?.resume();
    setIsPausedRecording(false);

    setIsRecording(true);
  };

  const startRecording = async () => {
    if(!audioPerms && recordType === "audio" && handleCancel) handleCancel();
    if (!recordingMediaStream.current) return;
    recorder.current = new MediaRecorder(recordingMediaStream.current);

    // where we are going to store recording
    const chunks: Blob[] = [];

    recorder.current.onstart = () => {
      !!onRecordingStarted && onRecordingStarted();
      setIsRecording(true);
      stopWatch.reset();
      stopWatch.start();
      trackMixpanelRecordingEvent(
        "Recording Started",
        recordTypeMapping[recordType]
      );
    };

    recorder.current.onstop = () => {
      console.log("Recording Stopped, duration is:", stopWatch.duration);
      stopWatch.stop();
    };

    recorder.current.onerror = (e: any) => {
      stopRecording();
      stopWatch.stop();

      toast({ status: "error", title: `Error recording ${recordType}` });
    };

    recorder.current.onpause = () => {
      stopWatch.stop();
      trackMixpanelRecordingEvent(
        "Recording Paused",
        recordTypeMapping[recordType]
      );
    };

    recorder.current.onresume = () => {
      stopWatch.start();

      trackMixpanelRecordingEvent(
        "Recording Resumed",
        recordTypeMapping[recordType]
      );
    };

    recorder.current.ondataavailable = async (event: BlobEvent) => {
      // where we are going to store recording
      if (event.data.size  > 0) {
        chunks.push(event.data);
      }
      if (recorder.current?.state !== "recording") {
        const blob = new Blob([...chunks], {
          type: recordType === "audio" ? "audio/mpeg" : "video/webm",
        });

        let thumbNail;

        if (recordType === "video" || recordType === "screen") {
          let videoElement = document.getElementById(
            "record-video"
          ) as HTMLVideoElement;
          if (videoElement){
            thumbNail = await captureVideoFrame(videoElement);
          }      
        }

        stopAllMediaStreams();
        recorder.current = null;
        console.log("Last(?) ondataavailable Duration", stopWatch.duration)
        setNewHark({
          ...newHark,
          recording: {
            source: blob,
            mediaType: recordType === "video" ? "webm" : "mp4",
            type: recordType,
            playbackUrl: window.URL.createObjectURL(blob),
            duration: stopWatch.duration,
          },
          thumbNail,
        });
        stopWatch.reset();
        setIsRecording(false);
        setIsPausedRecording(false);
      }
    };




    recorder.current.start();


    setInterval(() => {
      if (recorder.current?.state === "recording") {
        recorder.current.requestData();
      }
    }, 10000);
  };
  return {
    mediaStream: recordingMediaStream.current,
    stopAllMediaStreams,
    requestAccessToMediaDevices,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    isRecording,
    duration,
    cameras,
    changeCamera,
    reverseCameraFacing,
    isPausedRecording,
    loading,
  };
};

export default useRecorder;
