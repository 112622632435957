import { URLSearchParams } from "url";
import { IContactDetails } from "../../../@types/IContactDetails";

export const getContactDetailsFromURL = (
  queryParams: URLSearchParams
): IContactDetails => {
  const contactDetails = {
    communicationMethod: "email",
  } as Partial<IContactDetails>;
  contactDetails.email = queryParams.get("email") || "";
  contactDetails.firstName = queryParams.get("first_name") || "";
  contactDetails.lastName = queryParams.get("last_name") || "";
  contactDetails.phone = queryParams.get("phone") || "";
  return contactDetails as IContactDetails;
};

export const getTicketIdFromURL = (queryParams: URLSearchParams) => {
  return queryParams.get("ticket_id") || "";
};
