import {
  Box,
  Heading,
  Button,
  ButtonGroup,
  Container,
  Flex,
  useToast,
  Stack,
} from "@chakra-ui/react";
import { pick } from "lodash";
import StepperNav from "./StepperNav";
import { FaChevronLeft } from "react-icons/fa";
import WorkflowSelection from "./WorkflowSelection/WorkflowSelection";
import { useRecoilState } from "recoil";
import { defaultHarkState, newHarkState } from "../atoms/NewHarkStateAtom";
import ContactDetails from "./ContactDetails";
import {
  validateContactDetails,
  validateContactMethodSection,
  validateRecordHark,
  validateSurveyFields,
} from "../utils/newHarkValidator";
import RecordHark from "./RecordHark";
import { PreformattedText } from "./PreFormattedText";
import { ReviewHark } from "./ReviewHark/ReviewHark";
import mixpanel from "mixpanel-browser";
import {
  MixpanelEvents,
  trackMixpanelEvent,
} from "../../../utils/mixpanelEvents";
import { useState } from "react";
import { createHark } from "../../../api/hark";
import { useNavigate } from "react-router-dom";
import { validationErrorState } from "../atoms/validationErrorsState";
import { ErrorAlert } from "./ErrorAlert";
import { SurveyStep } from "./SurveyStep";
import { getAddressWithZipCode } from "../utils/getAddressWithZipcode";

const steps = [
  { title: "Issue type" },
  { title: "Your details" },
  { title: "Talk about it" },
  { title: "Review" },
];
type NavControllerProps = {
  activeStep: number;
  setActiveStep: (n: number) => void;
};

export function NavController({
  activeStep,
  setActiveStep,
}: NavControllerProps) {
  const [validationErrors, setValidationErrors] =
    useRecoilState(validationErrorState);
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const selectedWorkflow = newHark.workflow;
  // const hasSurveyStep = selectedWorkflow?.survey_custom_field_ids && selectedWorkflow?.survey_custom_field_ids.length > 0;
  const hasSurveyStep = selectedWorkflow?.custom_fields?.reduce(
    (acc, field) => {
      if (Boolean(field.is_survey)) {
        return true;
      }
      return acc;
    },
    false
  );
  const toast = useToast();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [, setSubmissionError] = useState("");

  const handleSubmit = async () => {
    try {
      mixpanel.alias(newHark.contactDetails.email);
      trackMixpanelEvent(MixpanelEvents.HARK_SUBMITTED);

      setSubmitting(true);
      setSubmissionError("");

      const { addressID } = newHark.contactDetails;
      let zipcodeAddress;
      if (addressID) zipcodeAddress = await getAddressWithZipCode(addressID);
      const response = await createHark({
        ...newHark,
        contactDetails: {
          ...newHark.contactDetails,
          address: zipcodeAddress,
        },
      });

      setActiveStep(1);

      setSubmitting(false);
      trackMixpanelEvent(MixpanelEvents.HARK_RECEIVED_BY_API, {});

      sessionStorage.setItem("submitted", "true");
      if (newHark?.company?.chat_enabled && selectedWorkflow?.chat_enabled)
        sessionStorage.setItem(
          "chat_handoff_email",
          newHark.contactDetails.email
        );
      navigate(
        `/harks/${response._id}/suggested-articles/?workflow=${newHark.workflow?.slug}&company=${newHark.company._id}`,
        {
          state: { hark: newHark },
        }
      );
      setNewHark(defaultHarkState);
      setValidationErrors({});
    } catch (err: any) {
      console.log(err);
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "Error Sending Hark, Try again";
      setSubmissionError(errorMessage);
      trackMixpanelEvent(MixpanelEvents.ERROR_SENDING_HARK, {
        errorMessage,
        stack: err.stack,
      });
      setSubmitting(false);
      toast({
        title: "Error sending message",
        description: errorMessage,
        status: "error",
      });
    }
  };

  const handleContinue = () => {
    if (errors) {
      console.log("errors", errors);
      setValidationErrors({ ...validationErrors, ...errors });
      return;
    }
    if (activeComponent?.triggerMixpanelEvent !== undefined) {
      activeComponent.triggerMixpanelEvent();
    }

    if (activeStep < components.length - 1) setActiveStep(activeStep + 1);
    else handleSubmit();
  };

  const handleGoBack = () => setActiveStep(activeStep - 1);
  const handleGoToStep = (step: number) => {
    if (step < activeStep) setActiveStep(step);
    if (step > activeStep) {
      for (let i = activeStep; i < step; i++) {
        const error = components[i]?.validation();
        if (error) return;
      }
      setActiveStep(step);
    }
  };

  const components = [
    {
      title:
        selectedWorkflow?.meta_data?.workflowSelectionTitle ??
        "What best describes your issue?",
      description:
        selectedWorkflow?.meta_data.workflowSelectionMessage ??
        "Select the category that best describes your issue",
      component: <WorkflowSelection />,
      validation: () => {
        return Boolean(newHark.workflow)
          ? undefined
          : { workflow: "Choose an issue type to proceed." };
      },
      headingError: validationErrors.workflow,
      triggerMixpanelEvent: () =>
        trackMixpanelEvent(MixpanelEvents.WORKFLOW_SELECTED, {
          "Workflow Selection Type": "Manual",
          Workflow: selectedWorkflow?.name,
          "Workflow Count": newHark.company?.workflows.length || 0,
        }),
    },
    {
      title:
        selectedWorkflow?.meta_data?.customerDetailsTitle ??
        "Take a second to introduce yourself!",
      description:
        selectedWorkflow?.meta_data?.customerDetailsMessage ??
        "Please try to use contact details that are associated with your account.",
      component: <ContactDetails handleContinue={handleContinue} />,
      validation: () =>
        validateContactDetails(
          pick(newHark.contactDetails, ["firstName", "lastName", "email"])
        ),
      headingError:
        (validationErrors.firstName ||
          validationErrors.lastName ||
          validationErrors.email) &&
        "Seems there’s a missing or incomplete field, please complete the field to proceed",

      triggerMixpanelEvent: () =>
        trackMixpanelEvent(MixpanelEvents.CONTACT_INFO_ENTERED, {}),
    },

    {
      title: newHark.recording
        ? selectedWorkflow?.meta_data?.afterRecordHarkTitle || "All Set?"
        : selectedWorkflow?.meta_data?.recordHarkTitle || "Record Hark",

      description: newHark.recording
        ? selectedWorkflow?.meta_data?.afterRecordHarkMessage ||
          "Feel free to re-record and add any additional information that you think would be helpful."
        : selectedWorkflow?.meta_data?.recordHarkMessage ||
          "Having difficulties or any issues at all? Let’s hear (and see) it!",
      component: <RecordHark />,
      validation: () => validateRecordHark(newHark),

      headingError:
        validationErrors.recording &&
        !newHark.recording &&
        "Please select an option to describe your issue.",
      name: "savedRecording",
    },
    {
      title:
        selectedWorkflow?.meta_data?.reviewHarkTitle ??
        "Review your information",
      description:
        selectedWorkflow?.meta_data?.reviewHarkMessage ??
        "Did we get everything? If so, go ahead and send it to us and we’ll get to work!",
      component: (
        <ReviewHark goToStep={setActiveStep} isSubmitting={submitting} />
      ),
      validation: () => validateContactMethodSection(newHark),
      headingError: "",
    },
  ];

  const surveyComponent = {
    title:
      selectedWorkflow?.meta_data?.surveyTitle ||
      "We'd love to hear your feedback!",
    description:
      selectedWorkflow?.meta_data?.surveyMessage ||
      "Take a moment to share your thoughts with us.",
    component: <SurveyStep />,
    validation: () => validateSurveyFields(newHark),
    name: "surveyStep",
    headingError:
      validationErrors.recording &&
      !newHark.recording &&
      "Please select an option to describe your issue.",
  };

  if (hasSurveyStep) {
    components.splice(2, 0, surveyComponent);
  }

  const activeComponent = components[activeStep];
  const errors = activeComponent?.validation();

  const stepToHideContinueButton = hasSurveyStep ? 3 : 2;

  if (!activeComponent) {
    return null;
  }
  return (
    <Container
      bg="white"
      maxW={["full", 750]}
      px={[5, 20, 36]}
      py={[5, 10]}
      borderTopRadius={"16px"}
      display="flex" // Using Flexbox
      flexDirection="column" // Flex direction as column
      justifyContent="space-between" // Space between items
      alignItems={"center"}
      flexGrow={[0.6, 0.5]}
      mb={0}
    >
      <Flex direction="column" flexGrow={1} width={"full"} gap={5}>
        <StepperNav
          activeStep={activeStep}
          steps={steps}
          goToStep={handleGoToStep}
          isDisabled={submitting}
        />

        <Stack>
          {activeComponent?.headingError && (
            <ErrorAlert isOpen={true} message={activeComponent?.headingError} />
          )}
          <Heading
            autoFocus={true}
            color="text.primary"
            fontSize={"1.5rem"}
            lineHeight={"2rem"}
            role="heading"
            aria-level={2}
          >
            {activeComponent.title}
          </Heading>
          <PreformattedText
            fontSize={"1rem"}
            color="text.tertiary"
            lineHeight={"1.5rem"}
          >
            {activeComponent.description}
          </PreformattedText>
        </Stack>

        <Box>{activeComponent.component}</Box>
      </Flex>

      <ButtonGroup
        pt={5}
        colorScheme="brand"
        width={"full"}
        justifyContent={"end"}
      >
        {!!activeStep && (
          <Button
            onClick={handleGoBack}
            variant={"ghost"}
            colorScheme="gray"
            isDisabled={submitting}
            leftIcon={<FaChevronLeft />}
            color={"text.tertiary"}
            aria-label="Go back"
          >
            Back
          </Button>
        )}
        {(activeStep !== stepToHideContinueButton || newHark.recording) && (
          <Button
            isLoading={submitting}
            loadingText={"Sending message..."}
            onClick={handleContinue}
            opacity={!errors ? "100%" : "50%"}
            isDisabled={errors || submitting}
            title={errors ? "Please complete the form to proceed." : "Submit"}
            aria-label={activeStep < steps.length - 1 ? "Continue" : "Submit"}
          >
            {activeStep < components.length - 1
              ? "Continue"
              : selectedWorkflow?.meta_data?.submitMessage || "Submit"}
          </Button>
        )}
      </ButtonGroup>
    </Container>
  );
}
