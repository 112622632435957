import { useDisclosure } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { HarkButtonVariant } from "../../../../components/HarkButton";
import { trackMixpanelRecordingEvent } from "../../../../utils/mixpanelEvents";
import { newHarkState } from "../../atoms/NewHarkStateAtom";
import useRecorder from "../../hooks/useRecorder";
import AudioPlayback from "./AudioPlayback";
import { RecordAudioModal } from "./RecordAudioModal";
import { useAudioVisualization } from "../../hooks/useAudioVisualization";
import AudioVisualization from "./AudioVisualization";
import Countdown from "../CountDownTimer";
import { useState } from "react";
import { AnimatedMicrophone } from "./AnimatedMicrophone";
import { PausedAudioAnimation } from "./PausedAudioAnimation";
import { RecordHarkButton } from "../RecordHarkButton";

interface RecordAudioProps {
  buttonVariant: HarkButtonVariant;
}

const AudioRecordModal = ({ buttonVariant }: RecordAudioProps) => {
  const [showCountdown, setShowCountdown] = useState<boolean>(false);

  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const { callToActionText } = newHark;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isPlaybackOpen,
    onOpen: openPlayback,
    onClose: closePlayback,
  } = useDisclosure();

  const {
    isRecording,
    duration,
    startRecording,
    requestAccessToMediaDevices,
    stopRecording,
    loading,
    pauseRecording,
    isPausedRecording,
    resumeRecording,
    mediaStream,
  } = useRecorder({
    recordType: "audio",
    onRecordingStarted: () => setShowCountdown(false),
    handleCancel: () => handleStopRecording()
  });
  const { analyser, dataArray } = useAudioVisualization({ mediaStream });

  const handleStartRecording = () => {
    requestAccessToMediaDevices().then(() => setShowCountdown(true));
  };

  const openModal = () => {
    onOpen();
    trackMixpanelRecordingEvent("Recording Modal Opened", "Audio");
  };

  const handleStopRecording = () => {
    stopRecording();
    trackMixpanelRecordingEvent("Recording Complete", "Audio");

    openPlayback();
    onClose();
  };

  const retryRecording = () => {
    trackMixpanelRecordingEvent("Recording Retry", "Audio");
    setNewHark({ ...newHark, recording: null });
    closePlayback();
    openModal();
  };

  const handleCancelRecording = () => {
    trackMixpanelRecordingEvent("Recording Cancelled", "Audio");
    stopRecording();

    setTimeout(() => {
      setNewHark({
        ...newHark,
        thumbNail: undefined,
        recording: null,
      });

      setShowCountdown(false);

      onClose();
    }, 50);
  };

  const animatedMicrophone = (
    <AnimatedMicrophone
      isRecording={isRecording}
      analyser={analyser}
      dataArray={dataArray}
    />
  );

  const audioVisualization = isRecording ? (
    <AudioVisualization analyser={analyser} dataArray={dataArray} />
  ) : (
    <PausedAudioAnimation />
  );

  const CountDownTimer = showCountdown ? (
    <Countdown onFinished={startRecording} />
  ) : (
    ""
  );
  return (
    <>
      {!newHark.recording && (
        <RecordHarkButton
          onClick={openModal}
          variant={buttonVariant}
          recordingOption="audio"
        >
          {callToActionText} Audio
        </RecordHarkButton>
      )}

      {newHark.recording ? (
        <AudioPlayback
          duration={newHark.recording?.duration}
          isOpen={isPlaybackOpen}
          onClose={closePlayback}
          onRetry={retryRecording}
        />
      ) : (
        <RecordAudioModal
          isOpen={isOpen}
          loading={loading}
          startRecording={handleStartRecording}
          isRecording={isRecording}
          handleStopRecording={handleStopRecording}
          duration={duration}
          handleCancelRecording={handleCancelRecording}
          pauseRecording={pauseRecording}
          resumeRecording={resumeRecording}
          isPausedRecording={isPausedRecording}
          audioVisualization={audioVisualization}
          animatedMicrophone={animatedMicrophone}
          countDownTimer={CountDownTimer}
        />
      )}
    </>
  );
};

export default AudioRecordModal;
