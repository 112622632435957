import { atom } from "recoil";
import { IContactDetails } from "../../../@types/IContactDetails";
import { NewHark } from "../../../@types/IHark";
const callToActionTextOptions = ["Record", "Send", "Create"];

export const defaultHarkState = {
  contactDetails: {
    firstName: "",
    lastName: "",
    email: "",
    communicationMethod: "email",
  } as IContactDetails,
  recording: null,
  attachments: [] as File[],
  callToActionText: callToActionTextOptions[Math.floor(Math.random() * 3)],
  language: "en-US",
  customFieldResponses: {},
} as NewHark;

export const newHarkState = atom({
  key: "newHarkAtomState",
  default: defaultHarkState,
});
