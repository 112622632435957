import axios from "axios";
import { Workflow } from "../@types/Workflow";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function getWorkflowBySlug(
  companyID: string,
  workflowSlug: string
): Promise<Workflow> {
  const response = await axios.get(
    `${BASE_URL}/companies/workflows/${workflowSlug}/?company_id=${companyID}`
  );
  return response.data.data.workflow;
}
