import { Stack, position, Tooltip } from "@chakra-ui/react";
import RecordVideo from "./RecordVideo/Index";
import { useRecoilValue } from "recoil";
import RecordScreen from "./RecordScreen/RecordScreenModal";
import { HarkButtonVariant } from "../../../components/HarkButton";
import { RecordingOption } from "../../../@types/Workflow";
import RecordAudioModal from "./RecordAudio/Index";
import { WriteTextModal } from "./WriteText/WriteTextModal";
import { newHarkState } from "../atoms/NewHarkStateAtom";
import HarkQRcode from "./Attachment/harkQRcode"
import { isMobileDevice } from "../../../utils/detectMobileBrowser";
import {useState, useEffect} from 'react'
import "./Attachment/harkQr.css"

const RecordingOptions = () => {
  const selectedWorkflow = useRecoilValue(newHarkState).workflow;
  const [isQrVisible, setIsQrVisible] = useState(true);
  const { firstName, lastName, email } = useRecoilValue(newHarkState).contactDetails
  const recordingOptions: RecordingOption[] = selectedWorkflow?.settings
    ?.recordingOptions || ["video", "audio", "screen"];
  const recordingSaved = useRecoilValue(newHarkState).recording !== null;
  useEffect(() => {
    if (recordingSaved || isMobileDevice()){
      setIsQrVisible(false);
    }else{
      setIsQrVisible(true);
    }}, [recordingSaved])

  const components = {
    video: (variant: HarkButtonVariant) => (
      <RecordVideo buttonVariant={variant} key="video" />  
    ),
    audio: (variant: HarkButtonVariant) => (
      <RecordAudioModal buttonVariant={variant} key="audio" />
    ),
    screen: (variant: HarkButtonVariant) => (
      <RecordScreen buttonVariant={variant} key="screen" />
    ),

    text: (variant: HarkButtonVariant) => (
      <WriteTextModal buttonVariant={variant} key="text" />
    ),
  };

  const getURl = () => {
    const base = window.location;
    const baseString = base.toString();
    const url = new URL(baseString);
    url.searchParams.set("slug", selectedWorkflow.slug);
    url.searchParams.set("first_name", firstName);
    url.searchParams.set("last_name", lastName);
    url.searchParams.set("email", email);
    return url.toString();
  }
 
  return (
    <Stack justifyContent="space-between">
      {/* Record Video */}
      {recordingOptions?.map((option, index) =>
        components[option](index === 0 ? "solid" : "outline")
      )}
      <HarkQRcode isVisible={isQrVisible} url={getURl()} />
    </Stack>
  );
};

export default RecordingOptions;
