import { Select, HStack, Text } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { newHarkState } from "../atoms/NewHarkStateAtom";
import { useEffect, useState } from "react";
import { LanguageOption } from "../../../@types/IHark";
import axios from "axios";

export const LanguageSelection = ({ isText = false }: { isText?: boolean }) => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const [languageOptions, setLanguageOptions] = useState<LanguageOption[]>([]);
  const company = newHark.company;
  const languageSupport = company.multiple_language_support;

  useEffect(() => {
    if (languageSupport){
      axios.get("https://py-server.sendhark.com/tasks/language-options/")
      .then(res => {
        setLanguageOptions(res.data)
      })
      .catch(err =>{
        console.log(err);
      })
    }
  }, []);

  const handleLanguageSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setNewHark({ ...newHark, language: e.target.value });
  };

  const message = isText ? "You are writing in: " : "You’ll be speaking in: ";

  return  languageSupport ? (
    <HStack width={"full"} gap={0}>
      <Text color="gray.500" fontSize={14}>
        {message} &nbsp;
      </Text>
      <Select
        onChange={handleLanguageSelection}
        value={newHark.language}
        width="fit-content"
        rounded="md"
        size={"xs"}
      >
        {languageOptions.map(({ code, name }) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
      </Select>
    </HStack>
  ) : (
    <></>
  );
};
