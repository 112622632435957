import {
  Box,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepStatus,
  StepTitle,
  Stepper,
  VStack,
} from "@chakra-ui/react";

type StepperNavProps = {
  steps: Array<{ title: string }>;
  activeStep: number;
  isDisabled: boolean;
  goToStep: (step: number) => void;
};

export default function StepperNav({
  activeStep,
  steps,
  isDisabled,
  goToStep,
}: StepperNavProps) {
  return (
    <VStack
      width={["85%", "70%"]}
      alignSelf={"center"}
      position={"relative"}
      mb={10}
    >
      <Stepper
        zIndex={2}
        width={"full"}
        colorScheme="brand"
        bg="none"
        index={activeStep}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <VStack
              justify={"flex-start"}
              height={"3.5rem"}
              alignItems={"center"}
            >
              <Box bg="white">
                <StepIndicator
                  background={activeStep === index ? "brand.activeBg" : "white"}
                  opacity="100%"
                  cursor={"pointer"}
                  color={activeStep === index ? "brand.500" : "text.tertiary"}
                  onClick={isDisabled ? () => {} : () => goToStep(index)}
                >
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber style={{ fontSize: "15px" }} />}
                    active={<StepNumber style={{ fontSize: "15px" }} />}
                  />
                </StepIndicator>
              </Box>

              <StepTitle
                style={{
                  fontWeight: "400",
                  fontSize: "0.625rem",
                  textAlign: "center",
                }}
              >
                {step.title}
              </StepTitle>
            </VStack>
          </Step>
        ))}
      </Stepper>
      <Box
        borderTop="1px dashed #667085"
        position="absolute"
        width="90%"
        top="35%"
        zIndex={1}
      />
    </VStack>
  );
}
