import { Heading, Text } from "@chakra-ui/react";

export const NarrationGuide = () => (
  <>
    <Heading fontSize={14} color={"gray.800"} as="h2">
     We'd love to hear your voice!
    </Heading>
    <Text fontSize={14} textAlign={"justify"} color={"gray.600"}>
      Please narrate your video and explain the
      issue in detail. It really helps us resolve things faster.
    </Text>
  </>
);
