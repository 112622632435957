interface sessionInfoParams {
  company_name?: string;
  user_email?: string;
  HarkID?: string;
  "Mixpanel ID"?: string;
}

export const setSessionRewindInfo = (params: sessionInfoParams): void => {
  // SessionRewind disabled
  console.log("SessionRewind disabled");
  // try {
  //   const { sessionRewind, SessionRewindConfig } = window;

  //   if (!sessionRewind && !SessionRewindConfig) return;

  //   if (sessionRewind?.isRecording()) {
  //     sessionRewind.setSessionInfo({ ...params });
  //   } else {
  //     const sessionInfo = SessionRewindConfig.sessionInfo || {};
  //     SessionRewindConfig.sessionInfo = { ...sessionInfo, ...params };
  //   }
  // } catch (err) {
  //   console.log("Error setting sessionInfo info");

  //   console.log(err);
  // }
};
