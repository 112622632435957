import { HStack, Stack, Text } from "@chakra-ui/react";
import { IoMdPlay, IoMdPause } from "react-icons/io";

import { IconButton } from "../TooltipButton";
import { secondsToTimeWords } from "../../../../utils/dateFormatter";
import { useRecoilValue } from "recoil";
import { newHarkState } from "../../atoms/NewHarkStateAtom";
import useAudioPlayback from "../../hooks/useAudioPlayBack";
import { trackMixpanelRecordingEvent } from "../../../../utils/mixpanelEvents";
import { HiOutlineTrash } from "react-icons/hi";

export const AudioSnapShot = ({ showDeleteIcon = true }) => {
  const { playAudio, isPlayingAudio, deleteRecording } = useAudioPlayback();
  const newHark = useRecoilValue(newHarkState);

  const handleDeleteRecording = () => {
    trackMixpanelRecordingEvent("Recording Deleted", "Audio");
    deleteRecording();
  };

  return (
    <Stack>
      <HStack justify={"space-between"} align={"top"}>
        <HStack gap={10} justify={"top"}>
          <IconButton
            aria-label={isPlayingAudio ? "Pause" : "Play"}
            onClick={playAudio}
            color="brand.700"
            bg="brand.25"
          >
            {isPlayingAudio ? <IoMdPause /> : <IoMdPlay />}
          </IconButton>
          <Stack>
            <Text color={"gray.800"} fontWeight={"bold"} fontSize={16}>
              Audio Recording
            </Text>
            <Text color="gray.500" fontSize="xs">
              {secondsToTimeWords(Number(newHark.recording?.duration))}
            </Text>
          </Stack>
        </HStack>
        {showDeleteIcon && (
          <IconButton
            aria-label="Delete recording"
            onClick={handleDeleteRecording}
            size="sm"
            color="gray.400"
            bg="white"
            _hover={{ bg: "error.100", color: "error.500" }}
          >
            <HiOutlineTrash size={20} />
          </IconButton>
        )}
      </HStack>
    </Stack>
  );
};
