import { HStack, Text, IconButton, Icon, Tag } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { FaRegFile } from "react-icons/fa";
import { FiTrash2, FiFilm, FiImage, FiVolume2 } from "react-icons/fi";
import { formatBytes } from "../../../../utils/fileSizeFormatter";

const fileIcons: { [key: string]: IconType } = {
  audio: FiVolume2,
  video: FiFilm,
  image: FiImage,
  other: FaRegFile,
};

type UploadedFileProps = {
  fileName: string;
  size: number;
  type: string;
  onDelete: () => void;
};

const UploadedFile = ({
  fileName,
  size,
  type,
  onDelete,
}: UploadedFileProps) => {
  const fileType = type?.split("/")[0];
  const fileICon = fileIcons[fileType] ? fileIcons[fileType] : fileIcons.other;
  return (
    <HStack
      px="2"
      py={1}
      bg="white"
      alignItems="center"
      justifyContent="space-between"
      borderRadius="lg"
      align={"center"}
    >
      <HStack justify={"center"}>
        <Icon color="brand.700" as={fileICon} w="4" h="4" />
        <Text fontSize={"0.875rem"} color="gray.700" noOfLines={1}>
          {fileName}
        </Text>
      </HStack>
      <HStack justify={"center"}>
        <Tag color="gray.500" size="sm">
          {formatBytes(Number(size))}
        </Tag>
        <IconButton
          onClick={onDelete}
          variant="ghost"
          aria-label="Delete File"
          icon={<FiTrash2 />}
          size={"sm"}
        />{" "}
      </HStack>{" "}
    </HStack>
  );
};

export default UploadedFile;
