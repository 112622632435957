import {
  Box,
  HStack,
  IconButton,
  Image,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
} from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { IoMdPlay } from "react-icons/io";
import { useEffect, useState } from "react";

import { newHarkState } from "../../atoms/NewHarkStateAtom";
import videoThumbnail from "../../../../assets/images/video_thumbnail.png";
import { secondsToTimeWords } from "../../../../utils/dateFormatter";
import { trackMixpanelRecordingEvent } from "../../../../utils/mixpanelEvents";
import { HiOutlineTrash } from "react-icons/hi";
import { logVideoError } from "../../../../utils/videoError";

const VideoSnapShot = ({ showDeleteIcon = true }) => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const { thumbNail } = newHark;
  const [imageUrl, setImageUrl] = useState<string>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    thumbNail && setImageUrl(window.URL.createObjectURL(thumbNail));
  }, [thumbNail]);

  const handleDeleteRecording = () => {
    trackMixpanelRecordingEvent("Recording Deleted", "Video");
    setNewHark({ ...newHark, recording: null, thumbNail: undefined });
  };
  return (
    <HStack justify={"space-between"} alignItems={"top"}>
      <HStack>
        <Box position="relative">
          <IconButton
            onClick={onOpen}
            cursor="pointer"
            icon={<IoMdPlay />}
            aria-label=""
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            marginY="auto"
            marginX="0"
            size="md"
            borderRadius="full"
            color="brand.500"
            p="2"
            backdropFilter="saturate(180%) blur(10px)"
            background="rgba(255,255,255,0.1);"
            _hover={{
              backdropFilter: "saturate(180%) blur(10px)",
              background: "rgba(255,255,255,0.3);",
            }}
          />
          <Image objectFit="cover" w="20" src={imageUrl || videoThumbnail} />
        </Box>
        <Box>
          <Text
            textTransform={"capitalize"}
            color={"gray.800"}
            fontWeight={"bold"}
            fontSize={16}
          >
            {newHark.recording?.type} Recording
          </Text>
          <Text color="gray.500" fontSize="xs">
            {secondsToTimeWords(Number(newHark.recording?.duration))}
          </Text>{" "}
        </Box>{" "}
      </HStack>
      {showDeleteIcon && (
        <IconButton
          aria-label="Delete recording"
          onClick={handleDeleteRecording}
          size="sm"
          color="gray.400"
          bg="white"
          _hover={{ bg: "error.100", color: "error.500" }}
        >
          <HiOutlineTrash size={20} />
        </IconButton>
      )}

      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <video 
              controls
              onError={(e) => logVideoError(e, "VideoSnapShot.tsx")}
              >
              <source src={newHark.recording?.playbackUrl} type="video/mp4" />
              Error loading video playback
            </video>
          </ModalContent>
        </Modal>
      )}
    </HStack>
  );
};

export default VideoSnapShot;
