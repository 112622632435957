import {
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  HStack,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Select,
  Flex,
  Icon,
  ButtonGroup,
  Stack,
} from "@chakra-ui/react";
import { FiSettings } from "react-icons/fi";
import { MdFiberManualRecord } from "react-icons/md";
import { secondsToHH_MM_SS } from "../../../../utils/dateFormatter";
import { HarkButton } from "../../../../components/HarkButton";
import { ReactNode } from "react";
import {
  RecordModal,
  RecordModalFooter,
  RecordModalHeader,
  RecordModalLoadingSpinner,
} from "../RecordingModal";
import { IoMdClose } from "react-icons/io";
import { LanguageSelection } from "../LanguageSelection";
import { NarrationGuide } from "../Narration/NarrationGuide";
import { logVideoError } from "../../../../utils/videoError";
import { MaxVideoLengthWarning } from "../VideoMaxLengthWarning";

interface DesktopRecordModalProps {
  loading: boolean;
  isOpen: boolean;
  videoStream: MediaStream | null;
  startRecording: () => void;
  isRecording: boolean;
  changeCamera: (deviceId: string) => void;
  cameras: MediaDeviceInfo[];
  handleStopRecording: () => void;
  duration: number;
  handleCancelRecording: () => void;

  countDownTimer: ReactNode;
}

export const DesktopRecordModal = ({
  loading,
  isOpen,
  videoStream,
  startRecording,
  isRecording,
  changeCamera,
  cameras,
  handleStopRecording,
  duration,
  handleCancelRecording,

  countDownTimer,
}: DesktopRecordModalProps) => {
  return (
    <RecordModal
      isOpen={isOpen}
      onClose={handleCancelRecording}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <RecordModalHeader
          title="Record Video Clip"
          description="Record a short video message narrating the issue"
        />
        <ModalCloseButton size="sm" isDisabled={loading} />
        <ModalBody display={"flex"} flexDirection={"column"}>
          {loading && <RecordModalLoadingSpinner />}

          <Stack flexGrow={1} gap={2} pb={2}>
            <Box position={"relative"} width="full" flexGrow={1}>
              <video
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                id={"record-video"}
                playsInline
                muted
                autoPlay
                // @ts-ignore
                srcObject={videoStream}
                onError={(e) => logVideoError(e, "DesktopRecordModal.tsx")}
              ></video>
              {!isRecording && (
                <Popover placement="right-start">
                  <PopoverTrigger>
                    <IconButton
                      position="absolute"
                      bottom={[1, 2]}
                      left={[2, 4]}
                      variant="ghost"
                      aria-label="Send email"
                      color="gray.300"
                      rounded="full"
                      size="sm"
                      colorScheme="brand"
                      icon={<FiSettings size={20} />}
                    />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                      <Select
                        onChange={(e) => {
                          changeCamera(e.target.value);
                        }}
                        placeholder="Select Camera"
                      >
                        {cameras.map((camera) => (
                          <option value={camera.deviceId} key={camera.deviceId}>
                            {camera.label}
                          </option>
                        ))}
                      </Select>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
                )}
            </Box>
            <LanguageSelection />
            <Stack
              gap={1}
              p={[2, 4]}
              border="1px"
              borderColor={"gray.100"}
              background={"gray.25"}
              rounded="md"
            >
              <NarrationGuide />
            </Stack>
          </Stack>

          {countDownTimer}
        </ModalBody>

        <RecordModalFooter>
          {isRecording ? (
            <HStack gap={4} width="full" justify="space-between">
              <Flex gap={1} alignItems={"center"}>
                <Icon as={MdFiberManualRecord} color="red.500" />
                <Text>{secondsToHH_MM_SS(duration)}</Text>
              </Flex>

              <HarkButton
                flex={1}
                isDisabled={!videoStream}
                onClick={() => {
                  handleStopRecording();
                }}
                width={"full"}
              >
                Stop
              </HarkButton>
            </HStack>
          ) : (
            <ButtonGroup width={"full"}>
              <IconButton
                aria-label="Cancel"
                variant="outline"
                bg="white"
                borderColor={"gray.300"}
                icon={<IoMdClose />}
                onClick={handleCancelRecording}
                isDisabled={loading}
              />

              <HarkButton
                leftIcon={<MdFiberManualRecord />}
                width={"full"}
                isDisabled={!videoStream}
                onClick={startRecording}
              >
                Start Recording
              </HarkButton>
            </ButtonGroup>
          )}
        </RecordModalFooter>
      </ModalContent>
    </RecordModal>
  );
};
