import { Skeleton, Wrap, WrapItem } from "@chakra-ui/react";

export const SkeletonLoader = () => (
  <Wrap spacing={3}>
    {new Array(5).fill(0).map((_, index) => (
      <WrapItem key={index}>
        <Skeleton
          borderRadius={"8px"}
          width={`${Math.random() * (130 - 70) + 60}px`}
          height={"30px"}
          startColor="white"
        />
      </WrapItem>
    ))}
  </Wrap>
);
