import {
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  Text,
  HStack,
  Spinner,
  Center,
  Stack,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { IoMdReverseCamera } from "react-icons/io";

import { secondsToHH_MM_SS } from "../../../../utils/dateFormatter";
import { RecordButton } from "../../../../components/RecordButton";
import { MobileNarrationSlide } from "../Narration/MobileNarration";
import { ReactNode } from "react";
import { logVideoError } from "../../../../utils/videoError";
import { MaxVideoLengthWarning } from "../VideoMaxLengthWarning";

interface MobileModalProps {
  loading: boolean;
  isOpen: boolean;
  onClose: () => void;
  videoStream: MediaStream | null;
  startRecording: () => void;
  isRecording: boolean;
  handleStopRecording: () => void;
  duration: number;
  reverseCameraFacing: () => void;
  handleCancelRecording: () => void;

  countDownTimer: ReactNode;
}

export const MobileRecordModal = ({
  loading,
  isOpen,
  onClose,
  videoStream,
  startRecording,
  isRecording,
  handleStopRecording,
  duration,
  reverseCameraFacing,
  handleCancelRecording,

  countDownTimer,
}: MobileModalProps) => {
  const { isOpen: isOpenNarrationGuide, onClose: closeNarrationGuide } =
    useDisclosure({ defaultIsOpen: true });

  return (
    <>
      <Modal
        size={"full"}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          background={"black"}
          minH={"-webkit-fill-available"}
          height={"-webkit-fill-available"}
        >
          <Box position="relative" height={"full"}>
            {loading && (
              <Center height={"full"} width={"full"} paddingY={20}>
                <Spinner
                  thickness="5px"
                  speed="1s"
                  emptyColor="gray.200"
                  color="brand.100"
                  size="xl"
                />
              </Center>
            )}

            <Box width="full" height="85%" >
              <video
                id={"record-video"}
                playsInline
                muted
                autoPlay
                style={{
                  minWidth: "100%",
                  minHeight: "100%",
                  right: 0,
                  bottom: 0,
                }}
                // @ts-ignore
                srcObject={videoStream}
                onError={(e) => logVideoError(e, "MobileRecordModal.tsx")}
              ></video>
            </Box>

            <Stack
              width="full"
              position="fixed"
              top={"50px"}
              gap={2}
              zIndex={1000}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              {!isRecording && (
                <IconButton
                  onClick={reverseCameraFacing}
                  aria-label="Swap Camera"
                  rounded="full"
                  icon={<IoMdReverseCamera />}
                  isRound={true}
                  variant="solid"
                  position={"absolute"}
                  right={10}
                  top={0}
                  size={"lg"}
                  fontSize={"2xl"}
                />
              )}
            </Stack>
            <Stack
              width="full"
              position="fixed"
              bottom={5}
              gap={2}
              zIndex={1000}
            >
              <Text textAlign={"center"} color="white">
                {isRecording ? secondsToHH_MM_SS(duration) : "00:00"}
              </Text>

              <HStack
                width={"full"}
                justify="space-evenly"
                height={"fit-content"}
              >
                <RecordButton
                  isRecording={isRecording}
                  isDisabled={!videoStream}
                  onClick={isRecording ? handleStopRecording : startRecording}
                />
              </HStack>
            </Stack>
          </Box>

          {isOpen && (
            <MobileNarrationSlide
              isOpen={isOpenNarrationGuide}
              secondaryAction={() => {
                handleCancelRecording();
              }}
              primaryAction={() => {
                closeNarrationGuide();
                startRecording();
              }}
            />
          )}

          {countDownTimer}
        </ModalContent>
      </Modal>
    </>
  );
};
