import { IoMdAttach } from "react-icons/io";
import { HiOutlineCamera } from "react-icons/hi";
import { useRecoilState } from "recoil";
import {
  Stack,
  Heading,
  ButtonGroup,
  useDisclosure,
  Text,
  Icon,
} from "@chakra-ui/react";

import { newHarkState } from "../../atoms/NewHarkStateAtom";
import AttachFileModal from "./AttachFileModal";
import UploadedFile from "./UploadedFile";
import TakePhoto from "./TakePhoto";
import { HarkButton } from "../../../../components/HarkButton";
import {
  MixpanelEvents,
  trackMixpanelEvent,
} from "../../../../utils/mixpanelEvents";
import { PreformattedText } from "../PreFormattedText";
import { validationErrorState } from "../../atoms/validationErrorsState";
import { validateRecordHark } from "../../utils/newHarkValidator";
import { DisplayCard } from "../DisplayCard";

const Attachment = () => {
  const [errors, setErrors] = useRecoilState(validationErrorState);

  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const { attachments, workflow: selectedWorkflow } = newHark;
  const metaData = selectedWorkflow?.meta_data;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenAttachmentModal,
    onOpen: onOpenAttachmentModal,
    onClose: onCloseAttachmentModal,
  } = useDisclosure();

  const handleOpenAttachmentModal = () => {
    trackMixpanelEvent(MixpanelEvents.ATTACH_FILE_CLICKED);
    onOpenAttachmentModal();
  };

  const handleTakePicture = () => {
    trackMixpanelEvent(MixpanelEvents.TAKE_PICTURE_CLICKED);
    onOpen();
  };

  const validateAttachment = (hark = newHark) => {
    const error = validateRecordHark(hark);
    setErrors({ ...errors, attachments: error?.attachments });
  };

  return (
    <DisplayCard>
      <Heading fontSize="0.875rem" fontWeight={"600"}>
        {metaData?.uploadTitle ?? "Upload Additional Documents"}
      </Heading>
      <PreformattedText color="GrayText" fontSize="0.875rem">
        {metaData?.uploadMessage ??
          "To help us understand better, please add at least one attachment (photo, document, etc.)."}{" "}
      </PreformattedText>
      <ButtonGroup size="sm">
        <HarkButton
          borderRadius={"1rem"}
          border="1px solid"
          borderColor={"brand.500"}
          onClick={handleTakePicture}
          leftIcon={<Icon as={HiOutlineCamera} color="brand.500" />}
          variant="outline"
          bg="white"
          color="text.tertiary"
          fontWeight={"normal"}
        >
          Take picture
        </HarkButton>
        <HarkButton
          borderRadius={"1rem"}
          border="1px solid"
          borderColor={"brand.500"}
          onClick={handleOpenAttachmentModal}
          leftIcon={<Icon as={IoMdAttach} color="brand.500" />}
          variant="outline"
          bg="white"
          color="text.tertiary"
          fontWeight={"normal"}
        >
          Attach file
        </HarkButton>
      </ButtonGroup>

      <Stack pt={[1, 2]} gap={2}>
        {attachments.map((file, index) => (
          <UploadedFile
            key={index}
            fileName={file.name}
            size={file.size}
            type={file.type}
            onDelete={() => {
              const files = [...attachments];
              files.splice(index, 1);
              const updatedHark = { ...newHark, attachments: files };
              setNewHark(updatedHark);
              trackMixpanelEvent(MixpanelEvents.REMOVED_ATTACHMENT);
              validateAttachment(updatedHark);
            }}
          />
        ))}
      </Stack>

      <AttachFileModal
        onClose={onCloseAttachmentModal}
        isOpen={isOpenAttachmentModal}
        onOpen={onOpenAttachmentModal}
      />

      <TakePhoto isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      {errors.attachments && (
        <Text fontSize={"xs"} color="error.500">
          {"* Photos required for submission"}
        </Text>
      )}
    </DisplayCard>
  );
};

export default Attachment;
