import { Wrap, WrapItem, useDisclosure } from "@chakra-ui/react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { defaultHarkState, newHarkState } from "../../atoms/NewHarkStateAtom";
import { Workflow } from "../../../../@types/Workflow";
import {
  MixpanelEvents,
  trackMixpanelEvent,
} from "../../../../utils/mixpanelEvents";
import { WorkflowButton } from "./WorkflowButton";
import { SkeletonLoader } from "../SleletonLoader";
import { validationErrorState } from "../../atoms/validationErrorsState";
import WorkflowChangeAlert from "./WorkflowAlert";
import { useState } from "react";

const WorkflowSelection = () => {
  const setErrors = useSetRecoilState(validationErrorState);
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const workflows = newHark.company?.workflows || [];
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [workflowToChange, setWorkflowToChange] = useState<Workflow>();

  const handleSelectWorkflow = (selectedWorkflow: Workflow) => {
    let prevSettings = newHark.workflow?.settings;
    if (!prevSettings) {
      const workflow =
        newHark.company?.workflows?.find((wf) => !!wf.default) ||
        newHark.company?.workflows[0];
      prevSettings = workflow.settings;
    }

    const showBusinessName = prevSettings?.showBusinessName;
    const showBrandLogo = prevSettings?.showBrandLogo;
    const workflow: Workflow = {
      ...selectedWorkflow,
      settings: {
        ...selectedWorkflow.settings,
        showBrandLogo,
        showBusinessName,
      },
    };

    setNewHark({
      ...defaultHarkState,
      workflow,
      contactDetails: {
        ...newHark.contactDetails,
        communicationMethod: "email",
      },
      company: newHark.company,
      ticket_id: newHark.ticket_id,
      language: newHark.language,
    });

    setErrors({});
  };

  const showConFirmation = (wf: Workflow) => {
    setWorkflowToChange(wf);
    onOpen();
  };

  return !!newHark.company ? (
    <>
      <Wrap spacing={3}>
        {workflows.map((workflow) => (
          <WrapItem key={workflow.slug}>
            <WorkflowButton
              onClick={() =>
                newHark.workflow && newHark.recording
                  ? showConFirmation(workflow)
                  : handleSelectWorkflow(workflow)
              }
              isActive={newHark.workflow?.slug === workflow.slug}
            >
              {workflow.name}
            </WorkflowButton>
          </WrapItem>
        ))}
      </Wrap>
      <WorkflowChangeAlert
        isOpen={isOpen}
        onClose={onClose}
        onAction={() => {
          workflowToChange && handleSelectWorkflow(workflowToChange);
          onClose();
        }}
      />
    </>
  ) : (
    <SkeletonLoader />
  );
};
export default WorkflowSelection;
