import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { isMobileDevice } from "../../../../utils/detectMobileBrowser";
import {
  MixpanelEvents,
  trackMixpanelEvent,
  trackMixpanelRecordingEvent,
} from "../../../../utils/mixpanelEvents";
import { newHarkState } from "../../atoms/NewHarkStateAtom";
import DesktopTakePhotoModal from "./DesktopModal";
import MobileTakePhotoModal from "./MobileModal";
import { validateRecordHark } from "../../utils/newHarkValidator";
import { validationErrorState } from "../../atoms/validationErrorsState";
import { captureVideoFrame } from "../../../../utils/generateThumbnail";
import { Toast, useToast } from "@chakra-ui/react";

const TakePhoto = ({
  isOpen,
  onOpen,
  onClose,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}) => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const [videoStream, setVideoStream] = useState<MediaStream>();
  const [image, setImage] = useState<Blob>();
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);
  const [picturePreviewUrl, setPicturePreveiwUrl] = useState("");
  const [cameraFacing, setCameraFacing] = useState("environment");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useRecoilState(validationErrorState);
  const toast = useToast();
  const validateAttachment = (hark = newHark) => {
    const error = validateRecordHark(hark);
    setErrors({ ...errors, attachments: error?.attachments });
  };

  useEffect(() => {
    isOpen && setLoading(true);
    isOpen &&
      navigator.mediaDevices
        .getUserMedia({
          video: { facingMode: "environment", width: { ideal: 1024 } },
          audio: false,
        })
        .then((stream) => {
          setLoading(false);
          setVideoStream(stream);
          enumerateDevices();
        }).catch((err) => {
          console.log("Error getting user media", err);
          setLoading(false);
          toast({
            status: "error",
            title: "Permissions Error.",
            description:
              "Check your device settings to make sure your browser has permission to take a photo, and try again",
            onCloseComplete: () => {
              onClose();
            }
          });
          trackMixpanelRecordingEvent(
            "Device Permission Error",
            "Photo",
          );

        });
  }, [isOpen]);

  useEffect(() => {
    let video = document.getElementById("photo-video") as HTMLVideoElement;
    if (videoStream && video) video.srcObject = videoStream;
  }, [videoStream, image]);

  const enumerateDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      setCameras(videoDevices);
    } catch (err) {
      console.log("Error Enumerating device", err);
    }
  };

  const reverseCameraFacing = async () => {
    try {
      let facingMode = cameraFacing === "user" ? "environment" : "user";
      setCameraFacing(facingMode);

      videoStream?.getTracks().forEach((t) => t.stop());
      setLoading(true);

      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode },
        audio: true,
      });
      setLoading(false);
      setVideoStream(mediaStream);
    } catch (error) {
      console.log("Error reversing camera facing", error);
      trackMixpanelRecordingEvent(
        "Error Changing Camera",
        "Photo",
      );
    }
  };

  const changeCamera = async (deviceId: string) => {
    try {
      const camera = cameras.find((camera) => camera.deviceId === deviceId);
      if (!camera) return;
      const oldStream = videoStream;
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: camera.deviceId },
        audio: false,
      });
      setVideoStream(mediaStream);
      oldStream?.getTracks().forEach((t) => t.stop());
    } catch (error) {
      trackMixpanelRecordingEvent(
        "Error Changing Camera",
        "Photo",
      );
    }
  };

  const retakePhoto = () => {
    setImage(undefined);
    URL.revokeObjectURL(picturePreviewUrl);
    trackMixpanelEvent(MixpanelEvents.RETAKE_PICTURE_CLICKED);
  };

  const savePhoto = () => {
    const attachments = [...newHark.attachments];
    image &&
      attachments.push(
        new File([image], `photo_${Date.now()}.png`, {
          type: image.type,
          lastModified: new Date().getTime(),
        })
      );
    const updatedHark = { ...newHark, attachments };
    setNewHark(updatedHark);
    setImage(undefined);
    URL.revokeObjectURL(picturePreviewUrl);
    closeCamera();
    onClose();
    trackMixpanelEvent(MixpanelEvents.PICTURE_SAVED);
    validateAttachment(updatedHark);
  };

  const closeCamera = () => {
    videoStream?.getTracks().forEach((t) => t.stop());
    setVideoStream(undefined);
  };

  async function takePhoto() {
    try {
      const videoPlayer = document.getElementById(
        "photo-video"
      ) as HTMLVideoElement;
  
      const blob = await captureVideoFrame(videoPlayer);
      blob && setImage(blob);
      blob && setPicturePreveiwUrl(window.URL.createObjectURL(blob));
  
      trackMixpanelEvent(MixpanelEvents.PICTURE_SNAPPED);
      
    } catch (error) {
      console.log("Error taking photo", error);
      trackMixpanelRecordingEvent(
        "Error Taking Photo",
        "Photo",
      );
    }
  }
  return (
    // @ts-ignore

    isMobileDevice() ? (
      <MobileTakePhotoModal
        isOpen={isOpen}
        onClose={onClose}
        image={image}
        picturePreviewUrl={picturePreviewUrl}
        savePhoto={savePhoto}
        videoStream={videoStream}
        takePhoto={takePhoto}
        reverseCameraFacing={reverseCameraFacing}
        closeCamera={closeCamera}
        loading={loading}
        retakePhoto={retakePhoto}
      />
    ) : (
      <DesktopTakePhotoModal
        isOpen={isOpen}
        onClose={onClose}
        image={image}
        picturePreviewUrl={picturePreviewUrl}
        savePhoto={savePhoto}
        videoStream={videoStream}
        takePhoto={takePhoto}
        cameras={cameras}
        changeCamera={changeCamera}
        closeCamera={closeCamera}
        loading={loading}
        retakePhoto={retakePhoto}
      />
    )
  );
};

export default TakePhoto;
