import { Box, Button, Container, Heading, Stack, Text } from "@chakra-ui/react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useLocation, Link, useParams } from "react-router-dom";
import { PoweredByHark } from "../../components/PoweredByHark";
import { MixpanelEvents, trackMixpanelEvent } from "../../utils/mixpanelEvents";
import { useEffect, useState } from "react";
import { Article } from "../../@types/Article";
import { getArticles } from "../../api/articles";
import { getWorkflowBySlug } from "../../api/workflows";
import { SuccessNotification } from "./components/ThanksNotification";

const defaultMeta = {
  heading: "Discover Helpful Resources",
  message:
    "Explore our curated collection of articles addressing product issues. Empower yourself with knowledge and get the assistance you need.",
};

export default function SuggestedArticlePage() {
  const { harkID } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const workflowSlug = queryParams.get("workflow");
  const companyID = queryParams.get("company");
  const [meta, setMeta] = useState<{
    heading: string;
    message: string;
    chatEnabled: boolean;
  }>({
    heading: "",
    message: "",
    chatEnabled: false,
  });

  // Check if the state is defined and if the openNotification property is true

  const [suggestedArticles, setSuggestedArticles] = useState<Article[]>([]);

  useEffect(() => {
    sessionStorage.removeItem("submitted");
  }, []);
  useEffect(() => {
    async function loadWorkflow() {
      try {
        const workflow = await getWorkflowBySlug(
          String(companyID),
          String(workflowSlug)
        );
        setMeta({
          heading:
            workflow.meta_data.suggestedArticleHeading || defaultMeta.heading,
          message:
            workflow.meta_data.SuggestedArticleMessage || defaultMeta.message,
          chatEnabled: workflow.chat_enabled || false,
        });
        trackMixpanelEvent(MixpanelEvents.DISPLAYED_SUGGESTED_ARTICLES);
      } catch (err) {
        console.log(err);
      }
    }

    loadWorkflow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function loadSuggestedArticles() {
      try {
        const articles = await getArticles(
          String(companyID),
          String(workflowSlug)
        );
        setSuggestedArticles(articles);
        trackMixpanelEvent(MixpanelEvents.DISPLAYED_SUGGESTED_ARTICLES);
      } catch (err) {
        console.log(err);
      }
    }

    loadSuggestedArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box width="full" minH="100vh" bg="white">
      <Container px={2} position="relative" pt={8}>
        <Stack gap={2}>
          {!Boolean(sessionStorage.getItem("submitted")) && (
            <SuccessNotification 
              chatEnabled={meta.chatEnabled} 
              />
          )}
          {suggestedArticles.length > 0 && (
            <>
              <Heading textAlign={"center"} as="h2" fontSize={"lg"}>
                {meta.heading}
              </Heading>
              <Text textAlign={"justify"} as="p" color={"gray.500"}>
                {meta.message}
              </Text>
            </>
          )}
          {suggestedArticles.map((article) => (
            <Stack
              key={article.id}
              p={4}
              borderRadius={"sm"}
              border={"1px"}
              borderColor={"gray.300"}
            >
              <Heading as="h3" fontSize={"md"} color={"gray.700"}>
                {article.title}
              </Heading>
              <Text
                textAlign={"justify"}
                fontSize={"sm"}
                color="gray.600"
                as="p"
              >
                {article.summary}
              </Text>
              <Button
                color="gray.700"
                size={"sm"}
                rightIcon={<HiArrowNarrowRight />}
                as={Link}
                to={`../harks/${harkID}/read-article/${article.id}?workflow=${workflowSlug}&company=${companyID}`}
                variant={"ghost"}
                width={"fit-content"}
                onClick={() =>
                  trackMixpanelEvent(MixpanelEvents.SUGGESTED_ARTICLE_CLICKED)
                }
              >
                Read Article
              </Button>
            </Stack>
          ))}
          {companyID != "653001c27e0790d0492cd18b" &&
            companyID != "65d39602d0a23a1f64c51311" && <PoweredByHark />}
        </Stack>
      </Container>
    </Box>
  );
}