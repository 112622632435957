import { useRef, useState } from "react";
import { useRecoilState } from "recoil";

import { newHarkState } from "../atoms/NewHarkStateAtom";
import { useToast } from "@chakra-ui/react";
import { trackMixpanelRecordingEvent } from "../../../utils/mixpanelEvents";

export const useAudioPlayback = () => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const [isPlayingAudio, setIsPlayingAudio] = useState<boolean>(false);

  const [duration, setDuration] = useState<number>(0);
  const audioRef = useRef(new Audio(newHark.recording?.playbackUrl));
  const toast = useToast();
  audioRef.current.addEventListener("ended", function () {
    setIsPlayingAudio(false);
  });

  audioRef.current.addEventListener("timeupdate", function () {
    setDuration(audioRef.current.currentTime);
  });

  const deleteRecording = () => {
    const audioPlayer = audioRef.current;

    audioPlayer?.pause();
    audioPlayer.currentTime = 0;
    newHark.recording?.playbackUrl &&
      URL.revokeObjectURL(newHark.recording?.playbackUrl);

    // store blob in newHark state for submission
    setNewHark({
      ...newHark,
      recording: null,
    });
  };

  const playAudio = () => {
    try {
      const audioPlayer = audioRef.current;
      if (isPlayingAudio) {
        audioPlayer.pause();
      } else {
        audioPlayer.play();
      }
    } catch (error) {
      console.error("Error playing audio:", error);
      toast({
        status: "error",
        title: "Permissions Error.",
        description:
          "Check your device settings to make sure your browser has permission to play audio, and try again",
      });
      trackMixpanelRecordingEvent(
        "Device Permission Error",
        "Audio",
      );
    }
    
  };

  const stopPlayer = () => {
    const audioPlayer = audioRef.current;

    audioPlayer?.pause();
    audioPlayer.currentTime = 0;
  };

  return {
    playAudio,
    duration,
    deleteRecording,
    isPlayingAudio,
    stopPlayer,
  };
};

export default useAudioPlayback;
