import {
  Text,
  HStack,
  ButtonGroup,
  Button,
  Box,
  Circle,
} from "@chakra-ui/react";
import { HarkButton } from "../../../../components/HarkButton";
import { FiPause } from "react-icons/fi";
import { FaVideo } from "react-icons/fa";

interface RecordScreenControlProps {
  duration: string;
  stopRecording: () => void;
}

export const RecordScreenControl = ({
  duration,
  stopRecording,
}: RecordScreenControlProps) => {
  return (
    <HStack position={"fixed"} left={2} bottom={5} zIndex={5}>
      <Circle size={14} bg="brand.500" color="white">
        <FaVideo />
      </Circle>
      <HStack
        borderRadius={"full"}
        boxShadow={"2px 5px 11px 4px rgba(87,81,81,0.27)"}
        background="white"
        align={"center"}
        justify={"space-between"}
        gap={12}
        px={6}
        py={3}
        border=".5px solid"
        borderColor={"gray.100"}
      >
        <Box color="gray.600">
          <Text fontSize={"sm"} fontWeight={"bold"}>
            {duration}
          </Text>
          <Text fontSize={"xs"}>Recording Screen</Text>
        </Box>

        <ButtonGroup size={"sm"}>
          <Button leftIcon={<FiPause />}>pause </Button>
          <HarkButton onClick={stopRecording}>Stop</HarkButton>
        </ButtonGroup>
      </HStack>
    </HStack>
  );
};
