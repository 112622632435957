import { Company } from "./Company";
import { IContactDetails } from "./IContactDetails";
import { RecordingOption, Workflow } from "./Workflow";

export interface IRecordedMedia {
  type: RecordingOption;
  source: Blob | string;
  mediaType: "mp3" | "webm" | "mp4" | "text";
  playbackUrl?: string;
  duration?: number;
}

export interface INewHark {
  isRecording: boolean;
  company: string;
  contactDetails: IContactDetails;
  recording: IRecordedMedia | null;
  productName: string;
  thumbNail?: Blob;
  attachments: File[];
  customFieldResponses: {
    [key: string]: string;
  };
  workflow: Workflow;
  ticket_id?: string;
  callToActionText: "Record" | "Send" | "Create";
  language: string;
}
interface IContact_info {
  name: string;
  email: string;
  phone: string;
  communicationMethod: "email" | "phone";
  address?: string;
}
export interface IHark {
  _id: string;
  user: string;
  company: Company;
  recording_url: string;
  thumbnail_url: string;
  product_name: string;
  contact_info: IContact_info;
  status: HarkStatus;
  communication_method: string;
  created_at: string;
  updated_at: string;
  additional_files: IAdditionalFile[];
  timeline: ITimeline[];
  __v: 0;
}

export interface IAdditionalFile {
  name: string;
  url: string;
}

interface ITimeline {
  status: HarkStatus;
  date: string;
  assignee: string;
}

export type HarkStatus = "submitted" | "review" | "completed";

export const HarkStatusMap = {
  submitted: "submitted",
  review: "in-review",
  completed: "completed",
};

export interface LanguageOption {
  name: string;
  code: string;
}

export interface NewHark {
  company: Company;
  contactDetails: IContactDetails;
  recording: IRecordedMedia | null;
  thumbNail?: Blob;
  attachments: File[];
  customFieldResponses: {
    [key: string]: string;
  };
  workflow: Workflow;
  ticket_id?: string;
  language: string;
  callToActionText: "Record" | "Send" | "Create";
}
