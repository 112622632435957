import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  HStack,
  VStack,
  Icon,
  ButtonGroup,
  Heading,
  Stack,
} from "@chakra-ui/react";
import { HarkButton } from "../../../../components/HarkButton";

import { secondsToHH_MM_SS } from "../../../../utils/dateFormatter";
import { trackMixpanelRecordingEvent } from "../../../../utils/mixpanelEvents";
import useAudioPlayback from "../../hooks/useAudioPlayBack";
import { LanguageSelection } from "../LanguageSelection";
import { HiOutlineClock } from "react-icons/hi";
import AudioVisualization from "./AudioVisualization";
import { PausedAudioAnimation } from "./PausedAudioAnimation";
import { AnimatedMicrophone } from "./AnimatedMicrophone";

interface AudioPlaybackProps {
  duration?: number;
  isOpen: boolean;
  onClose: () => void;
  onRetry: () => void;
}

const AudioPlayback = ({
  onClose,
  isOpen,
  onRetry,
  duration,
}: AudioPlaybackProps) => {
  const { playAudio, isPlayingAudio, stopPlayer } = useAudioPlayback();

  const handleSaveClick = () => {
    trackMixpanelRecordingEvent("Recording Saved", "Audio");
    stopPlayer();
    onClose();
  };

  let lastUpdated = Date.now();
  const updateInterval = 5; // in milliseconds, adjust as desired
  const transitionSpeed = 2;
  const mockAnalyser = {
    frequencyBinCount: 100,
    getByteFrequencyData: (array: Uint8Array) => {
      const now = Date.now();

      if (now - lastUpdated > updateInterval) {
        for (let i = 0; i < array.length; i++) {
          const current = array[i];
          const target = Math.random() * 255;
          array[i] = current + (target - current) / transitionSpeed;
        }
        lastUpdated = now;
      }
    },
  } as any;

  const mockDataArray = new Uint8Array(mockAnalyser.frequencyBinCount);

  return (
    <Modal
      size={["full", "xl"]}
      isOpen={isOpen}
      onClose={() => {
        stopPlayer();
        onClose();
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottom="1px" borderColor={"gray.200"}>
          <Heading mb={2} fontWeight={"medium"} fontSize={"18"} as={"h2"}>
            Audio Recording
          </Heading>
          <Text fontWeight={"normal"} fontSize={"14"} color="gray.500">
            The more, the better! Please share as much as you can so we can get
            your a solution faster. (We love feedback too!).
          </Text>
        </ModalHeader>

        <ModalBody overflow="hidden" display="flex" flexDirection={"column"}>
          <VStack
            height={"full"}
            justifyContent={"space-between"}
            width="full"
            py={2}
            overflow={"hidden"}
            gap={10}
            flexGrow={1}
          >
            <LanguageSelection />

            <Stack gap={4} pt={10} alignItems={"center"} width={"full"}>
              <AnimatedMicrophone
                dataArray={mockDataArray}
                analyser={mockAnalyser}
                isRecording={isPlayingAudio}
              />
              <HStack justify={"center"} width={"full"}>
                <Icon color="brand.700" as={HiOutlineClock} boxSize={5} />
                <Text fontWeight={"light"} fontSize={"24"} color="brand.700">
                  {secondsToHH_MM_SS(duration || 0)}
                </Text>
              </HStack>
            </Stack>
            <VStack width="full" gap={0}>
              {isPlayingAudio ? (
                <AudioVisualization
                  dataArray={mockDataArray}
                  analyser={mockAnalyser}
                />
              ) : (
                <PausedAudioAnimation />
              )}
              <Button
                alignSelf={"center"}
                variant={"outline"}
                onClick={playAudio}
                size="xs"
                bg="gray.50"
                rounded="full"
                color="gray.700"
                fontWeight={"medium"}
              >
                {isPlayingAudio ? "Pause" : "Play"} audio
              </Button>
            </VStack>
          </VStack>
        </ModalBody>

        <ModalFooter
          bg="gray.200"
          borderTop="1px"
          borderColor={"gray.200"}
          roundedBottom={"lg"}
        >
          <ButtonGroup flexGrow={1} gap={3}>
            <Button
              fontWeight={"normal"}
              variant="outline"
              bg="white"
              borderColor={"gray.300"}
              onClick={() => {
                stopPlayer();
                onRetry();
              }}
              width={"50%"}
            >
              Retry
            </Button>
            <HarkButton onClick={handleSaveClick} width={"50%"}>
              Save
            </HarkButton>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AudioPlayback;
