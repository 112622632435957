import { Circle, Icon } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiMic } from "react-icons/fi";

type AudioVisualizationProps = {
  analyser: AnalyserNode | null;
  dataArray: Uint8Array;
  isRecording: boolean;
};

export const AnimatedMicrophone: React.FC<AudioVisualizationProps> = ({
  analyser,
  dataArray,
  isRecording,
}) => {
  const [outlineWidth, setOutlineWidth] = useState(1);

  useEffect(() => {
    let animationFrameId: number;

    function update() {
      if (analyser) {
        analyser.getByteFrequencyData(dataArray);

        let sum = 0;
        for (let i = 0; i < dataArray.length; i++) {
          sum += dataArray[i];
        }
        let average = sum / dataArray.length;

        setOutlineWidth(average / 8);

        animationFrameId = requestAnimationFrame(update);
      }
    }

    if (isRecording) {
      update();
    }

    // Cleanup function
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [analyser, dataArray, isRecording]);

  return (
    <Circle
      size={20}
      border="1px"
      borderColor={"gray.100"}
      outline={`${outlineWidth}px solid`}
      outlineColor="brand.100"
      bg={isRecording ? "brand.300" : "white"}
    >
      <Icon
        color={isRecording ? "brand.buttonText" : "brand.500"}
        as={FiMic}
        boxSize={8}
      />
    </Circle>
  );
};
