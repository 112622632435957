import axios from "axios";
import { Company } from "../@types/Company";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// TODO: use useCallback or useMemo or reactquery to prevent recalling the api to fecth companies
export async function getCompanies(): Promise<{ companies: Company[] }> {
  const response = await axios.get(`${BASE_URL}/companies`);
  return response.data.data;
}

export async function getCompanyById(
  id: string | number
): Promise<{ company: Company }> {
  const response = await axios.get(`${BASE_URL}/companies/${id}/`);
  return response.data.data;
}
