import { useRecoilValue } from "recoil";
import { HStack, Heading, Image } from "@chakra-ui/react";
import { newHarkState } from "../atoms/NewHarkStateAtom";

const CompanyLogo = () => {
  const newHark = useRecoilValue(newHarkState);
  let selectedWorkflow = newHark.workflow;
  const company = newHark.company;
  if (!selectedWorkflow)
    selectedWorkflow =
      company?.workflows.find((wf) => !!wf.default) || company?.workflows[0];
  const showBrandLogo = selectedWorkflow?.settings?.showBrandLogo;
  const showBusinessName = selectedWorkflow?.settings?.showBusinessName;

  return (
    <HStack width="full" justify="center" mb={[2, 4]}>
      {showBrandLogo && (
        <Image height={8} src={company.logo_url} alt={`${company.name} logo`} />
      )}
      {showBusinessName && (
        <Heading color="text.primary" size="lg">
          {company.name}
        </Heading>
      )}
    </HStack>
  );
};

export default CompanyLogo;
