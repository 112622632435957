import { Icon } from "@chakra-ui/react";

export const WriteTextIcon = () => (
  <Icon viewBox="0 0 20 18" fill="none">
    <path
      d="M10.8337 4.83333H4.33366C3.40024 4.83333 2.93353 4.83333 2.57701 5.01499C2.2634 5.17478 2.00844 5.42975 1.84865 5.74335C1.66699 6.09987 1.66699 6.56658 1.66699 7.5V10.5C1.66699 11.4334 1.66699 11.9001 1.84865 12.2567C2.00844 12.5703 2.2634 12.8252 2.57701 12.985C2.93353 13.1667 3.40024 13.1667 4.33366 13.1667H10.8337M14.167 4.83333H15.667C16.6004 4.83333 17.0671 4.83333 17.4236 5.01499C17.7372 5.17478 17.9922 5.42975 18.152 5.74335C18.3337 6.09987 18.3337 6.56658 18.3337 7.5V10.5C18.3337 11.4334 18.3337 11.9001 18.152 12.2567C17.9922 12.5703 17.7372 12.8252 17.4236 12.985C17.0671 13.1667 16.6004 13.1667 15.667 13.1667H14.167M14.167 16.5L14.167 1.5M16.2503 1.50001L12.0837 1.5M16.2503 16.5L12.0837 16.5"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
