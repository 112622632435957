import axios from "axios";
import { CustomField } from "../@types/Workflow";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function getCustomFieldsByCompany(
  companyID: string
): Promise<[CustomField]> {
  const response = await axios.get(
    `${BASE_URL}/companies/custom-fields/?company_id=${companyID}`
  );
  return response.data as [CustomField];
}
