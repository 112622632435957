import {
  Modal,
  ModalHeader,
  ModalFooter,
  Button,
  Text,
  Spinner,
  Center,
  Heading,
  ModalProps,
  ModalFooterProps,
  ModalHeaderProps,
  ButtonProps,
} from "@chakra-ui/react";

export const RecordModal = (props: ModalProps) => (
  <Modal size={["full", "xl"]} {...props} />
);

export const RecordModalFooter = (props: ModalFooterProps) => (
  <ModalFooter
    bg="gray.200"
    borderTop="1px"
    borderColor={"gray.200"}
    roundedBottom={"lg"}
    {...props}
  />
);

interface RecordModalHeaderProps extends ModalHeaderProps {
  title: string;
  description: string;
}
export const RecordModalHeader = ({
  title,
  description,
  ...modalHeaderProps
}: RecordModalHeaderProps) => (
  <ModalHeader
    borderBottom="1px"
    borderColor={"gray.200"}
    {...modalHeaderProps}
  >
    <Heading mb={2} fontWeight={"medium"} fontSize={"18"} as={"h2"}>
      {title}
    </Heading>
    <Text fontWeight={"normal"} fontSize={"14"} color="gray.500">
      {description}
    </Text>
  </ModalHeader>
);

export const SecondaryRecordModalButton = (props: ButtonProps) => (
  <Button
    fontWeight={"normal"}
    variant="outline"
    bg="white"
    borderColor={"gray.300"}
    {...props}
  />
);

export const RecordModalLoadingSpinner = () => (
  <Center alignItems={"center"} py={20} width={"full"}>
    <Spinner
      thickness="5px"
      speed="1s"
      emptyColor="gray.200"
      color="brand.100"
      size="xl"
    />
  </Center>
);
