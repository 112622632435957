// return a date string in the format DD MON YYYY
// accepts optional dateString. If dateString is not provided then today's date is used
export const DD_MON_YYYY = (dateString?: string) => {
  const date = dateString ? new Date(dateString) : new Date();
  return date
    .toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    })
    .replace(/ /g, " ");
};

export function secondsToHH_MM_SS(totalSeconds: number) {
  let hours: number | string = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes: number | string = Math.floor(totalSeconds / 60);
  let seconds: number | string = Math.round(totalSeconds % 60);

  minutes = String(minutes).padStart(2, "0");
  hours = String(hours).padStart(2, "0");
  seconds = String(seconds).padStart(2, "0");

  return totalSeconds < 3600
    ? `${minutes}: ${seconds}`
    : `${hours}:${minutes}: ${seconds}`;
}

export const usDateFormat = (date: Date | null) => {
  return date
    ? date.toLocaleDateString("en-GB", {
        weekday: "short",
        day: "numeric",
        month: "short",
        year: "numeric",
      })
    : "";
};

export const HHMM = (date: Date) => {
  const hh = date.getHours() % 12; // => 9
  const min = date.getMinutes();
  const minutes = min < 10 ? `0${min}` : min;
  const hours = hh < 10 ? `0${hh}` : hh;

  return `${hours}:${minutes} ${date.getHours() >= 12 ? "PM" : "AM"}`;
};

export const convertTZ = (date: string, tzString?: string) => {
  if (!tzString) tzString = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return new Date(
    new Date(date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
};

export function formatDateToMMDDYYYY(date: Date) {
  if (!(date instanceof Date)) {
    console.error("Input is not a Date object");
    return "";
  }

  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString(); // getMonth() is zero-based
  let year = date.getFullYear().toString(); // Get last two digits of year

  // Pad single-digit months and days with a leading zero
  day = day.length < 2 ? "0" + day : day;
  month = month.length < 2 ? "0" + month : month;

  return `${month}/${day}/${year}`;
}

export function secondsToTimeWords(seconds: number): string {
  if (seconds < 0) {
    return "Invalid input";
  }

  if (seconds === 0) {
    return "0 seconds";
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let timeWords = "";

  if (hours > 0) {
    timeWords += `${hours} hour${hours > 1 ? "s" : ""} `;
  }

  if (minutes > 0) {
    timeWords += `${minutes} minute${minutes > 1 ? "s" : ""} `;
  }

  if (remainingSeconds > 0 || (hours === 0 && minutes === 0)) {
    timeWords += `${remainingSeconds} second${
      remainingSeconds !== 1 ? "s" : ""
    }`;
  }

  return timeWords.trim();
}
