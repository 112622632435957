import mixpanel from "mixpanel-browser";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { setSessionRewindInfo } from "./utils/sessionRewind";
import { Honeybadger, HoneybadgerErrorBoundary } from "@honeybadger-io/react";

const config = ({
  apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY,
  environment: process.env.REACT_APP_ENV,
  reportData: true
})
const honeybadger = Honeybadger.configure(config);

mixpanel.init(String(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN), {
  debug: process.env.REACT_APP_ENV === "development",
});
const queryString = window.location.search;
const parameters = new URLSearchParams(queryString);
const mixpanelID = parameters.get("sendharkjsmxpnlid");
if (mixpanelID) mixpanel.identify(mixpanelID);
setSessionRewindInfo({ "Mixpanel ID": mixpanel.get_distinct_id() });

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  <HoneybadgerErrorBoundary honeybadger={honeybadger}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </HoneybadgerErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
