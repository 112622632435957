import { useState } from "react";
import { FiUploadCloud } from "react-icons/fi";
import { FileUploader } from "react-drag-drop-files";
import {
  Text,
  VStack,
  Box,
  Icon,
  Stack,
  useDisclosure,
  ScaleFade,
} from "@chakra-ui/react";

import UploadedFile from "./UploadedFile";

const DragDropFileUploader = ({ onChange, files, onDelete }) => {
  const [errMessage, setErrMessage] = useState("");
  const { isOpen, onToggle } = useDisclosure();

  const handleError = (err) => {
    setErrMessage(err);
    onToggle();

    setTimeout(() => {
      setErrMessage("");

      onToggle();
    }, 3000);
  };

  return (
    <Stack>
      (
      <ScaleFade initialScale={0.9} in={isOpen}>
        {errMessage && (
          <Box
            border="1px"
            borderColor="red.100"
            rounded="md"
            bg="red.50"
            fontSize="sm"
            color="red.500"
            px={2}
            py={1}
          >
            {errMessage}
          </Box>
        )}
      </ScaleFade>
      )
      <Stack
        borderRadius="lg"
        bg="brand.25"
        border="1px"
        borderColor="brand.200"
      >
        <FileUploader
          handleChange={onChange}
          maxSize={100}
          name="file"
          onTypeError={handleError}
          onSizeError={handleError}
          types={[
            "png",
            "jpeg",
            "jpg",
            "gif",
            "svg",
            "heic",
            "mp3",
            "mp4",
            "pdf",
            "mov",
          ]}
        >
          <VStack
            fontSize="sm"
            bg="brand.25"
            _hover={{ bg: "brand.50" }}
            py={3}
          >
            <Box
              px={1.5}
              pt={1}
              pb={0}
              rounded="full"
              bg="brand.100"
              border="4px"
              borderColor="brand.50"
            >
              <Icon as={FiUploadCloud} color="band.600" />
            </Box>
            <Box display="flex">
              <Text fontWeight="bold" color="brand.700">
                Click to upload
              </Text>
              <Text color="brand.600">or drag and drop </Text>
            </Box>
            <Text color="brand.600">SVG, PNG, JPG or GIF (max 100 MB)</Text>
          </VStack>
        </FileUploader>
      </Stack>
      {files.map((file, index) => (
        <UploadedFile
          key={index}
          fileName={file.name}
          size={file.size}
          type={file.type}
          onDelete={(index) => onDelete(index)}
        />
      ))}
    </Stack>
  );
};

export default DragDropFileUploader;
