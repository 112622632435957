import { Box, Button, ButtonProps, Icon, Tag } from "@chakra-ui/react";
import { RecordingOption } from "../../../@types/Workflow";
import { HarkButtonVariant } from "../../../components/HarkButton";
import { RecordAudioIcon } from "../../../components/icons/RecordAudioIcon";
import { RecordScreenIcon } from "../../../components/icons/RecordScreenIcon";
import { RecordVideoIcon } from "../../../components/icons/RecordVideoIcon";
import { WriteTextIcon } from "../../../components/icons/WriteTextIcon";
import { useRecoilValue } from "recoil";
import { newHarkState } from "../atoms/NewHarkStateAtom";
const icons = {
  video: <RecordVideoIcon />,
  audio: <RecordAudioIcon />,
  screen: <RecordScreenIcon />,
  text: <WriteTextIcon />,
};

interface RecordHarkButtonProps extends ButtonProps {
  variant: HarkButtonVariant;
  recordingOption: RecordingOption;
}

export const RecordHarkButton = ({
  variant,
  recordingOption,
  children,
  ...restProps
}: RecordHarkButtonProps) => {
  const { workflow: selectedWorkflow } = useRecoilValue(newHarkState);
  const photRequired =
    selectedWorkflow?.settings?.requirePhotos?.includes(recordingOption);

  const variantProps: Partial<ButtonProps> =
    variant === "solid"
      ? {
          color: "brand.buttonText",
          _hover: { pl: "1rem", pr: "0.75rem" },
        }
      : {
          color: "brand.500",
          borderColor: "brand.500",
          _hover: { bg: "brand.activeBg", pl: "1rem", pr: "0.75rem" },
        };

  return (
    <Button
      borderRadius={"1.5rem"}
      paddingY="0.625rem"
      pl={"0.875rem"}
      pr="0.625rem"
      variant={variant}
      textTransform={"capitalize"}
      justifyContent={"start"}
      width={"fit-content"}
      fontWeight={"normal"}
      colorScheme="brand"
      transition="padding 0.3s ease"
      gap="0.375rem"
      {...variantProps}
      {...restProps}
    >
      <Icon>{icons[recordingOption]}</Icon>
      <Box textAlign={"left"} flexGrow={1}>
        {children}
      </Box>
      {photRequired && (
        <Tag
          bg="gray.50"
          border={"1px solid"}
          borderColor="#EAECF0"
          size={["xs", "sm"]}
          fontSize={["xs", "sm"]}
          borderRadius="full"
          color="text.tertiary"
          px={2}
          overflowX={"hidden"}
          textOverflow={"ellipsis"}
          textTransform={"none"}
        >
          Requires photo
        </Tag>
      )}
    </Button>
  );
};
