import {
  Box,
  HStack,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { IoMdClose, IoMdReverseCamera } from "react-icons/io";
import { RecordButton } from "../../../../components/RecordButton";
import PhotoPreview from "./PhotoPreview";
import { logVideoError } from "../../../../utils/videoError";

interface MobileTakePhotoModalProps {
  isOpen: boolean;
  onClose: () => void;
  image: Blob | undefined;
  picturePreviewUrl: string;
  retakePhoto: () => void;
  savePhoto: () => void;
  videoStream: MediaStream | undefined;
  takePhoto: () => void;
  reverseCameraFacing: () => void;
  closeCamera: () => void;
  loading: boolean;
}

const MobileTakePhotoModal = ({
  isOpen,
  onClose,
  image,
  picturePreviewUrl,
  retakePhoto,
  savePhoto,
  videoStream,
  takePhoto,
  reverseCameraFacing,
  closeCamera,
  loading,
}: MobileTakePhotoModalProps) => {
  return (
    <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        background={"black"}
        minH={"-webkit-fill-available"}
        height={"-webkit-fill-available"}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          position="relative"
          height={"full"}
        >
          {loading && (
            <Center width={"full"} paddingY={20}>
              <Spinner
                thickness="5px"
                speed="1s"
                emptyColor="gray.200"
                color="brand.100"
                size="xl"
              />
            </Center>
          )}

          {image ? (
            <PhotoPreview
              picturePreviewUrl={picturePreviewUrl}
              onRetry={retakePhoto}
              onSave={savePhoto}
            />
          ) : (
            <>
              <Box width={"full"} height="85%">
                <video
                  id="photo-video"
                  playsInline
                  muted
                  autoPlay
                  style={{
                    minWidth: "100%",
                    minHeight: "100%",
                  }}
                  onError={(e) => logVideoError(e, "MobileModal.tsx")} 
                ></video>
              </Box>

              <HStack
                width={"full"}
                justify="space-evenly"
                height={"fit-content"}
                position="fixed"
                bottom={10}
              >
                <IconButton
                  onClick={() => {
                    closeCamera();
                    onClose();
                  }}
                  colorScheme="gray"
                  aria-label="Discard"
                  rounded="full"
                  icon={<IoMdClose />}
                  background="rgba(255, 255, 255, 0.25)"
                  color="white"
                  size="md"
                />

                <RecordButton
                  isRecording={false}
                  isDisabled={!videoStream}
                  onClick={takePhoto}
                />
                <IconButton
                  onClick={reverseCameraFacing}
                  colorScheme="gray"
                  aria-label="Discard"
                  rounded="full"
                  icon={<IoMdReverseCamera />}
                  background="rgba(255, 255, 255, 0.25)"
                  color="white"
                  size="md"
                />
              </HStack>
            </>
          )}
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default MobileTakePhotoModal;
