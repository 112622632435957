import { Loader } from "@googlemaps/js-api-loader";

export function getAddressWithZipCode(
  placeId: string
): Promise<string | undefined> {
  const loader = new Loader({
    apiKey: String(process.env.REACT_APP_GOOGLE_MAPS_API_KEY),
    libraries: ["places"],
  });

  // @ts-ignore
  return loader
    .importLibrary("places")
    .then((google) => {
      return new Promise((resolve, reject) => {
        const map = document.createElement("div"); // Create a div element to host the map instance that is not added to the DOM
        const service = new google.PlacesService(map);

        service.getDetails(
          {
            placeId: placeId,
            fields: ["formatted_address", "address_components"], // Specify the fields you want to retrieve
          },
          (result, status) => {
            if (status === google.PlacesServiceStatus.OK && result) {
              resolve(result.formatted_address);
            } else {
              reject(`Failed to fetch details: ${status}`);
            }
          }
        );
      });
    })
    .catch((error) => {
      console.error(
        "Problem loading Google Maps API or fetching details",
        error
      );
      throw error; // Propagate the error if needed or handle it based on your application requirements
    });
}
