import { Progress, Stack, Text } from "@chakra-ui/react";
import { HarkStatus } from "../../../@types/IHark";
interface StatusProgressBarProps {
  status: HarkStatus;
}
const StatusProgressBar = ({ status }: StatusProgressBarProps) => {
  const progressValue = {
    submitted: 30,
    review: 70,
    completed: 100,
  };

  return (
    <Stack alignItems="baseline" width="full" direction="row">
      <Progress
        colorScheme="blue"
        size="sm"
        rounded="full"
        width="full"
        value={progressValue[status]}
      />
      <Text>{`${progressValue[status]}%`}</Text>
    </Stack>
  );
};

export default StatusProgressBar;
