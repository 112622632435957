import { useDisclosure, useToast } from "@chakra-ui/react";
import { useRecoilState } from "recoil";

import { HarkButtonVariant } from "../../../../components/HarkButton";
import { secondsToHH_MM_SS } from "../../../../utils/dateFormatter";
import { isMobileDevice } from "../../../../utils/detectMobileBrowser";
import { trackMixpanelRecordingEvent } from "../../../../utils/mixpanelEvents";
import { newHarkState } from "../../atoms/NewHarkStateAtom";
import useScreenRecorder from "../../hooks/useScreenRecorder";
import ScreenPlayback from "./ScreenPlayBack";
import { RecordScreenControl } from "./RecordScreenControl";
import { RecordScreenMobile } from "./RecordScreenMobile";
import Countdown from "../CountDownTimer";
import { useState } from "react";
import { RecordHarkButton } from "../RecordHarkButton";

/**
 * Todo:
 * disable recording buttons when recording
 */

interface RecordScreenProps {
  buttonVariant: HarkButtonVariant;
}
const RecordScreen = ({ buttonVariant }: RecordScreenProps) => {
  const [showCountdown, setShowCountdown] = useState<boolean>(false);

  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const toast = useToast();
  const {
    isOpen: isMobileOpen,
    onOpen: openMobile,
    onClose: closeMobile,
  } = useDisclosure();
  const {
    isOpen: isVideoPlayBackOpen,
    onOpen: openVideoPlayback,
    onClose: closeVideoPlayback,
  } = useDisclosure();

  const {
    requestAccessToMediaDevices,
    stopAllMediaStreams,
    startRecording,
    stopRecording,
    duration,
    isRecording,
  } = useScreenRecorder({
    onRecordingStopped: openVideoPlayback,
    onRecordingStarted: () => setShowCountdown(false),
  });

  const handleStartRecording = () => {
    trackMixpanelRecordingEvent("Recording Modal Opened", "Screen");
    if (isMobileDevice()) {
      openMobile();
      return;
    }

    requestAccessToMediaDevices()
      .then(() => {
        if (document.hidden) startRecording();
        else setShowCountdown(true);

        setNewHark({ ...newHark });
      })
      .catch((err) => {
        trackMixpanelRecordingEvent("Recording Cancelled", "Screen");
        if (
          String(err.message)
            .toLocaleLowerCase()
            .includes("permission denied by system")
        )
          toast({
            status: "error",
            title: "Can't share screen.",
            description:
              "Check your system preferences to make sure your browser has screen recording permission, and try again",
          });
        else
          toast({
            status: "error",
            title: "Please select a screen to begin screen Recording",
          });

        // setErrorMessage(err);
      });
  };

  const handleStopRecording = () => {
    stopRecording();
    openVideoPlayback();
    stopAllMediaStreams();
  };

  const retryRecroding = () => {
    trackMixpanelRecordingEvent("Recording Retry", "Screen");
    setNewHark({ ...newHark, recording: null });
    closeVideoPlayback();
    handleStartRecording();
  };
  return (
    <>
      {!newHark.recording && (
        <RecordHarkButton
          onClick={handleStartRecording}
          variant={buttonVariant}
          recordingOption="screen"
        >
          Record Screen
        </RecordHarkButton>
      )}
      {newHark.recording && (
        <ScreenPlayback
          duration={duration}
          closeCamera={stopAllMediaStreams}
          isOpen={isVideoPlayBackOpen}
          onOpen={openVideoPlayback}
          onClose={closeVideoPlayback}
          onRetry={retryRecroding}
        />
      )}
      {isRecording && (
        <RecordScreenControl
          duration={secondsToHH_MM_SS(duration)}
          stopRecording={handleStopRecording}
        />
      )}

      {showCountdown && (
        <Countdown onFinished={startRecording} color="gray.900" />
      )}

      <RecordScreenMobile isOpen={isMobileOpen} onClose={closeMobile} />
    </>
  );
};

export default RecordScreen;
