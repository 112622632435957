import { Box, Divider, HStack, Text, Stack, Image } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";

import RecordingOptions from "./RecordingOptions";
import { newHarkState } from "../atoms/NewHarkStateAtom";

import Attachment from "./Attachment/Attachment";
import AdditionalInformation from "./AdditonalInformation/AdditionalInfomation";
import { RecordingSnapshot } from "./RecordingSnapshot";
import { validationErrorState } from "../atoms/validationErrorsState";
import { ErrorAlert } from "./ErrorAlert";
import { RecordingOption } from "../../../@types/Workflow";

const RecordHark = () => {
  const newHark = useRecoilValue(newHarkState);
  const validationErrors = useRecoilValue(validationErrorState);
  const selectedWorkflow = newHark.workflow;

  
  if (!selectedWorkflow) {
    return null;
  }


  const getStartedImage = selectedWorkflow?.settings?.getStartedImage;
  

  const requireAttachments = Boolean(
    selectedWorkflow?.settings?.requirePhotos?.includes(
      newHark.recording?.type as RecordingOption
    )
  );
  const requiredCustomField = selectedWorkflow?.custom_fields?.some(
    (f) => f.required
  );
  const showRequired = requireAttachments || requiredCustomField;

  return (
    <Stack gap={5}>
      {/* Recording Playback */}

      <RecordingOptions />

      {newHark.recording && (
        <Box
          borderRadius="0.5rem"
          border={"1px solid"}
          borderColor="border.gray"
          box-shadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
          p="1rem"
        >
          <RecordingSnapshot />
        </Box>
      )}

      {newHark.recording && (
        <Stack gap={5}>
          {validationErrors.attachments && (
            <ErrorAlert
              isOpen={true}
              message="Seems there’s a missing or incomplete field, please complete the field to proceed"
            />
          )}
          {(selectedWorkflow?.settings?.collectAttachment ||
            selectedWorkflow.custom_fields?.length) && (
            <HStack>
              <Divider /> <Text>Additional </Text> <Text> Info </Text>
              {/* check if there is a required field or photos are required */}
              {showRequired && <Text> Required </Text>}
              <Divider />
            </HStack>
          )}
          {selectedWorkflow?.settings?.collectAttachment && <Attachment />}
          {<AdditionalInformation />}
        </Stack>
      )}

      {!newHark.recording && getStartedImage && (
        <Image
          maxH={250}
          src={getStartedImage}
          objectFit={"contain"}
          align={"left"}
        />
      )}
    </Stack>
  );
};

export default RecordHark;
