import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Slide,
  Stack,
  Text,
} from "@chakra-ui/react";

import { IoMdCheckmark, IoMdClose } from "react-icons/io";

interface AgentContactSlideProps {
  isOpen: boolean;
  isHelpful: boolean | null;
  handleClick: (event: any) => void;
}
export const ArticleHelpfulSlide = ({
  isOpen,
  isHelpful,
  handleClick,
}: AgentContactSlideProps) => (
  <Slide direction="bottom" in={isOpen} style={{ zIndex: 10 }}>
    <Box
      background={"white"}
      borderTop={"1px solid"}
      borderColor={"gray.300"}
      width="full"
    >
      <Container px={2} py={5}>
        <Stack
          width={"full"}
          p={4}
          py={4}
          borderRadius={"md"}
          border={"1px"}
          borderColor={"gray.300"}
        >
          <Text textAlign={"justify"} fontSize={"sm"} color="gray.600" as="p">
            Did this information help solve your issue?{" "}
          </Text>

          <ButtonGroup
            gap="4"
            size={"xs"}
            variant="outline"
            colorScheme="brand"
          >
            <Button
              background={isHelpful === true ? "brand.50" : "white"}
              rounded={"full"}
              value={"yes"}
              name="isHelpful"
              onClick={handleClick}
              leftIcon={<IoMdCheckmark />}
            >
              Yes
            </Button>
            <Button
              background={isHelpful === false ? "brand.50" : "white"}
              rounded={"full"}
              onClick={handleClick}
              value={"no"}
              name="isHelpful"
              leftIcon={<IoMdClose />}
            >
              No
            </Button>
          </ButtonGroup>
        </Stack>
      </Container>
    </Box>
  </Slide>
);
