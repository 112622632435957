import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  InputProps,
} from "@chakra-ui/react";
import { FiAlertCircle } from "react-icons/fi";

interface ContactInputProps extends InputProps {
  error: string | undefined;
  label: string;
  value: string;
}

export const ContactInput = ({
  error,
  value,
  label,
  ...rest
}: ContactInputProps) => {
  return (
    <FormControl isInvalid={!!error}>
      <FormLabel fontSize="sm">{label}</FormLabel>
      <InputGroup>
        <Input
          _invalid={{ border: "1px solid", borderColor: "border.error" }}
          _focus={{
            borderColor: error ? "border.error" : "border.primary",
            boxShadow: "none",
          }}
          value={value}
          bg={value ? "#FAFAFA" : "white"}
          {...rest}
        />

        {!!error && (
          <InputRightElement>
            <Icon color={"text.error.secondary"} as={FiAlertCircle}></Icon>
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage color="text.error">{error}</FormErrorMessage>
    </FormControl>
  );
};
