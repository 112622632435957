import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import { HarkButton } from "../../../../components/HarkButton";

interface RecordScreenMobileProps {
  isOpen: boolean;
  onClose: () => void;
}

export const RecordScreenMobile = ({
  isOpen,
  onClose,
}: RecordScreenMobileProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md">Record Screen</ModalHeader>
        <ModalCloseButton size="sm" />
        <ModalBody>
          <Text textAlign="center">
            Screen Recording is not supported on your device
          </Text>
        </ModalBody>

        <ModalFooter>
          <HarkButton onClick={onClose}>close</HarkButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
