import Joi, { Schema } from "joi";
import { NewHark } from "../../../@types/IHark";
import { RecordingOption } from "../../../@types/Workflow";
import {
  getAddressSchema,
  getAttachmentSchema,
  getContactDetailsSchema,
  getCustomFieldResponseSchema,
  getEmailSchema,
  getPhoneNumberSchema,
  getRecordingSchema,
} from "./validationSchemas";

function formatErrors(error: Joi.ValidationError): { [key: string]: string } {
  return error?.details.reduce((errors, detail) => {
    return { ...errors, [String(detail.context?.key)]: detail.message };
  }, {});
}

export const validateItem = (schema: Schema, value: any) => {
  const { error } = schema.validate(value);
  return error?.message;
};

export const validateContactDetails = (item: Object) => {
  const { error } = Joi.object(getContactDetailsSchema())
    .required()
    .validate(item, { abortEarly: false });
  return error ? formatErrors(error) : undefined;
};

export const validateRecordHark = (newHark: NewHark) => {
  const selectedWorkflow = newHark.workflow;

  const requireAttachments = Boolean(
    selectedWorkflow?.settings?.requirePhotos?.includes(
      newHark.recording?.type as RecordingOption
    )
  );
  const customFields = selectedWorkflow?.custom_fields || [];
  const collectPhoneNumber = selectedWorkflow?.settings.collectPhoneNumber;
  const collectAddress = selectedWorkflow?.settings.collectAddress;

  const schema: { [key: string]: Joi.Schema } = {
    recording: getRecordingSchema(),
    attachments: getAttachmentSchema(requireAttachments),
    address: getAddressSchema(collectAddress === "required"),
    phone: getPhoneNumberSchema(collectPhoneNumber === "required"),
    customFieldResponses: Joi.object(
      getCustomFieldResponseSchema(customFields)
    ),
  };

  const newHarkSchema = Joi.object(schema);
  const objectToValidate = {
    recording: newHark.recording,
    attachments: newHark.attachments,
    customFieldResponses: newHark.customFieldResponses,
    address: newHark.contactDetails.address,
    phone: newHark.contactDetails.phone,
  };

  const { error } = newHarkSchema.validate(objectToValidate, {
    abortEarly: false,
  });
  return error ? formatErrors(error) : undefined;
};

export const validateContactMethodSection = (newHark: NewHark): any => {
  if (newHark?.workflow?.settings?.hidePreferredContactMethod) return undefined;

  const preferredMethod = newHark.contactDetails.communicationMethod;
  const schema =
    preferredMethod === "email" ? getEmailSchema() : getPhoneNumberSchema(true);

  const keyToValidate =
    preferredMethod === "email" ? "preferredEmail" : "preferredPhone";
  const { error } = schema.validate(newHark.contactDetails[keyToValidate]);

  return error ? { [keyToValidate]: error.details[0].message } : undefined;
};


export const validateSurveyFields = (newHark: NewHark) => {
  const selectedWorkflow = newHark.workflow;
  const customFields = selectedWorkflow?.custom_fields?.filter((field) => field.is_survey === true) || [];
  const customFieldsSchema = getCustomFieldResponseSchema(customFields);
  const errors = customFields.reduce((errors, field) => {
    const error = validateItem(customFieldsSchema[field.name], newHark.customFieldResponses[field.name]);
    return error ? { ...errors, [field.name]: error } : errors;
  }, {});
  return Object.keys(errors).length ? errors : undefined;
}
