import axios from "axios";
import _ from "lodash";
import { Article } from "../@types/Article";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function getArticles(
  companyID: string,
  workflowSlug: string
): Promise<Article[]> {
  const response = await axios.get(
    `${BASE_URL}/companies/workflows/articles/?company_id=${companyID}&workflow_slug=${workflowSlug}`
  );
  return response.data;
}

export async function getArticleByID(
  articleID: string,
  companyID: string,
  workflowSlug: string
): Promise<Article> {
  const response = await axios.get(
    `${BASE_URL}/companies/workflows/articles/${articleID}/?company_id=${companyID}&workflow_slug=${workflowSlug}`
  );
  return response.data;
}

export async function createArticle(
  companyID: string,
  workflowSlug: string,
  article: Article
): Promise<Article[]> {
  const response = await axios.post(
    `${BASE_URL}/companies/workflows/articles/?company_id=${companyID}&workflow_slug=${workflowSlug}`,
    { ...article }
  );
  return response.data.data;
}

export async function updateArticle(
  companyID: string,
  workflowSlug: string,
  article: Article
): Promise<Article[]> {
  const id = article.id;
  const payload = _.omit(article, ["id"]);
  const response = await axios.put(
    `${BASE_URL}/companies/workflows/articles/${id}/?company_id=${companyID}&workflow_slug=${workflowSlug}`,
    payload
  );
  return response.data.data;
}

export async function deleteArticle(
  companyID: string,
  workflowSlug: string,
  article: Article
): Promise<Article[]> {
  const response = await axios.delete(
    `${BASE_URL}/companies/workflows/articles/${article.id}/?company_id=${companyID}&worlflow_slug=${workflowSlug}`
  );
  return response.data.data;
}
