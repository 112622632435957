import { HStack, Button, Box } from "@chakra-ui/react";
import { PoweredByHark } from "../../../components/PoweredByHark";

interface PrivacyPolicyTCProps {
  privacyPolicyUrl?: string;
  termsAndConditionsUrl?: string;
  companyID?: string;
}

export const PrivacyPolicyTC = ({
  privacyPolicyUrl,
  termsAndConditionsUrl,
  companyID,
}: PrivacyPolicyTCProps) => (
  <HStack width={"full"} gap={[2, 5]} justify={"space-between"}>
    <HStack gap={[2, 5]} wrap={"wrap"}>
      {privacyPolicyUrl && (
        <Button
          variant="link"
          fontWeight="normal"
          size="xs"
          as="a"
          target="_blank"
          href={privacyPolicyUrl}
        >
          Privacy Policy
        </Button>
      )}
      {termsAndConditionsUrl && (
        <Button
          variant="link"
          fontWeight="normal"
          size="xs"
          as="a"
          target="_blank"
          href={termsAndConditionsUrl}
        >
          Terms and Conditions
        </Button>
      )}
    </HStack>
    <Box w="fit-content">
      {companyID !== "653001c27e0790d0492cd18b" &&
        companyID !== "65d39602d0a23a1f64c51311" && <PoweredByHark />}
    </Box>
  </HStack>
);
