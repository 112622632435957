import {
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Text,
  HStack,
  ButtonGroup,
  IconButton,
  Stack,
  Divider,
  Icon,
  VStack,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { secondsToHH_MM_SS } from "../../../../utils/dateFormatter";
import { IoMdClose } from "react-icons/io";
import { LanguageSelection } from "../LanguageSelection";
import { HiOutlineClock } from "react-icons/hi";
import { MdFiberManualRecord } from "react-icons/md";
import { FaCircle } from "react-icons/fa";
import { HarkButton } from "../../../../components/HarkButton";
import {
  RecordModal,
  RecordModalFooter,
  RecordModalHeader,
  RecordModalLoadingSpinner,
  SecondaryRecordModalButton,
} from "../RecordingModal";

interface RecordAudioModalProps {
  loading: boolean;
  isOpen: boolean;
  startRecording: () => void;
  isRecording: boolean;
  handleStopRecording: () => void;
  pauseRecording: () => Promise<void>;
  isPausedRecording: boolean;
  resumeRecording: () => Promise<void>;
  duration: number;
  handleCancelRecording: () => void;
  audioVisualization: ReactNode;
  animatedMicrophone: ReactNode;
  countDownTimer: ReactNode;
}

export const RecordAudioModal = ({
  loading,
  isOpen,
  startRecording,
  isRecording,
  handleStopRecording,
  duration,
  handleCancelRecording,
  pauseRecording,
  isPausedRecording,
  resumeRecording,
  audioVisualization,
  animatedMicrophone,
  countDownTimer,
}: RecordAudioModalProps) => {
  return (
    <RecordModal
      id="audioModal"
      isOpen={isOpen}
      onClose={handleCancelRecording}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <RecordModalHeader
          title="Record Audio"
          description="The more, the better! Please share as much as you can so we can get your a solution faster. (We love feedback too!)"
        />
        <ModalCloseButton size="sm" isDisabled={loading} />
        <ModalBody
          position={"relative"}
          display="flex"
          flexDirection={"column"}
        >
          {loading && <RecordModalLoadingSpinner />}
          {!loading && (
            <VStack
              height={"full"}
              justifyContent={"space-between"}
              width="full"
              py={2}
              overflow={"hidden"}
              gap={10}
              flexGrow={1}
            >
              <LanguageSelection />

              <Stack gap={2} w="full" alignItems={"center"} pt={[32, 24]}>
                {animatedMicrophone}
                <HStack width="full" justify={"center"}>
                  <Icon color="brand.700" as={HiOutlineClock} boxSize={5} />
                  <Text fontWeight={"light"} fontSize={"24"} color="brand.700">
                    {secondsToHH_MM_SS(duration || 0)}
                  </Text>
                </HStack>
              </Stack>
              {audioVisualization}
            </VStack>
          )}
        </ModalBody>
        <Divider />

        <RecordModalFooter>
          {isRecording || isPausedRecording ? (
            <ButtonGroup width={"full"} gap={1}>
              <HStack gap={0} alignItems={"center"} justify={"center"}>
                <FaCircle color="red" size={10} />
                <Text fontSize={["xs", "md"]} color="gray.700">
                  {secondsToHH_MM_SS(duration || 0)}
                </Text>
              </HStack>
              <SecondaryRecordModalButton
                onClick={isPausedRecording ? resumeRecording : pauseRecording}
              >
                {isPausedRecording ? "Resume" : "Pause"}
              </SecondaryRecordModalButton>
              <HarkButton flexGrow={1} onClick={handleStopRecording}>
                Stop Recording
              </HarkButton>
            </ButtonGroup>
          ) : (
            <ButtonGroup width={"full"}>
              <IconButton
                aria-label="Cancel"
                variant="outline"
                bg="white"
                borderColor={"gray.300"}
                onClick={handleCancelRecording}
                isDisabled={loading}
                icon={<IoMdClose />}
              />

              <HarkButton
                isDisabled={!!countDownTimer}
                width={"full"}
                leftIcon={<MdFiberManualRecord />}
                onClick={startRecording}
              >
                Record
              </HarkButton>
            </ButtonGroup>
          )}
        </RecordModalFooter>
        {countDownTimer}
      </ModalContent>
    </RecordModal>
  );
};
