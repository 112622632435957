import { useRecoilState } from "recoil";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { CustomFieldInput } from "./CustomFieldInput";
import {CustomField } from"../../../../@types/Workflow";
import { newHarkState } from "../../atoms/NewHarkStateAtom";
import { DisplayCard } from "../DisplayCard";
import LocationSearchInput from "../Address";
import "../../style/phone-input.css";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Text,
} from "@chakra-ui/react";
import { validationErrorState } from "../../atoms/validationErrorsState";
import { validateItem } from "../../utils/newHarkValidator";
import { getCustomFieldResponseSchema } from "../../utils/validationSchemas";


const AdditionalInformation = () => {
  const [errors, setErrors] = useRecoilState(validationErrorState);

  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const { customFieldResponses } = newHark;
  const selectedWorkflow = newHark.workflow;
  const customFields = selectedWorkflow?.custom_fields || [];
  const collectAddress = selectedWorkflow?.settings?.collectAddress;
  const collectPhone = selectedWorkflow?.settings?.collectPhoneNumber;

  const customFieldsSchema = getCustomFieldResponseSchema(customFields);
  const handleChange = (name: string, value: string) => {
    const customFieldsToUpdate = newHark.customFieldResponses;
    const customFieldResponses = { ...customFieldsToUpdate, [name]: value };
    setNewHark({ ...newHark, customFieldResponses });
    //simple error check for nps field. If nps is clicked, a value is guaranteed by default
    if (name === "nps" && value !== ""){
        return;
    }
    const error = customFieldsSchema[name] ? validateItem(customFieldsSchema[name], value) : undefined;

    setErrors({ ...errors, [name]: error });
  };

  const validateNumberInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      (e.key < "0" || e.key > "9") &&
      e.key !== "Backspace" &&
      e.key !== "Tab" &&
      e.key !== "Enter" &&
      e.key !== "Shift" &&
      e.key !== "Control" &&
      e.key !== "CapsLock" &&
      e.key !== "Alt"
    ) {
      setErrors({
        ...errors,
        [e.currentTarget.name]: "Only numbers are allowed",
      });
    }
  };


  return !!customFields?.length || !!collectAddress || !!collectPhone ? (
    <DisplayCard>
      <Heading fontSize="0.875rem" fontWeight={"600"}>
        Almost there!
      </Heading>
      <Text color="GrayText" fontSize="0.875rem">
        Just a few details and we'll be all set to assist you.
      </Text>

      {collectPhone && (
        <FormControl isInvalid={!!errors.phone}>
          <FormLabel fontSize="sm">
            Your mobile number ({collectPhone})
          </FormLabel>

          <PhoneInput
            className={!!errors.phone ? "PhoneInput-inValid" : ""}
            international
            countryCallingCodeEditable={false}
            placeholder="Enter phone number"
            value={newHark.contactDetails.phone}
            onChange={(value: string) => {
              setNewHark({
                ...newHark,
                contactDetails: { ...newHark.contactDetails, phone: value },
              });
              let isValid = isValidPhoneNumber(String(value));
              if (collectPhone === "optional" && !value) isValid = true;

              setErrors({
                ...errors,
                phone: isValid ? undefined : "Invalid phone number",
              });
            }}
            defaultCountry="US"
          />
          <FormErrorMessage>{errors.phone}</FormErrorMessage>
        </FormControl>
      )}

      {!!collectAddress && (
        <LocationSearchInput isRequired={collectAddress === "required"} />
      )}

      {customFields?.filter((field) => !field.is_survey).map((field) => (
        <CustomFieldInput
          key={field.id}
          field={field}
          value={customFieldResponses[field.name]}
          onChange={handleChange}
          validateNumberInput={validateNumberInput}
          errorMessage={errors[field.name]}
        />
      ))}
    </DisplayCard>
  ) : (
    <></>
  );
};

export default AdditionalInformation;
