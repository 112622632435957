import { HStack, Text, Image } from "@chakra-ui/react";
import harkLogo from "../assets/svg/HarkLogoWide.svg";

export const PoweredByHark = () => (
  <HStack width="full" justifyContent="end">
    <Text fontSize="xs" whiteSpace={"nowrap"}>
      Powered by
    </Text>
    <Image h="12px" src={harkLogo} alt="Hark Logo"/>
  </HStack>
);
