import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import { RecoilRoot, useRecoilValue } from "recoil";

import { brandColorsThemeState } from "./atoms/brandColorsThemeState";
import { colors, fonts, components } from "./config/defaultTheme";

// Pages
import TrackingPage from "./pages/Tracking";
import SuggestedArticlePage from "./pages/SuggestedArticles/SuggestedArticlesPage";
import ReadArticlePage from "./pages/SuggestedArticles/ReadArticle";
import SendHarkPage from "./pages/SendHarkPage";
import { getCompanyById } from "./api/companies";
import { getWorkflowBySlug } from "./api/workflows";
import { Company } from "./@types/Company";
import { getCustomFieldsByCompany } from "./api/customFields";


const router = createBrowserRouter([
  { path: "*", element: <ThemedApp /> },
  {
    path: "/",
    element: <ThemedApp />,
    children: [
      {
        path: "new/:companyID",
        element: <SendHarkPage />,
        loader: async ({ request, params }) => {
          try {
            if (!params.companyID) return null;

            const url = new URL(request.url);
            const slug = url.searchParams.get("slug");
            const companyPromise = getCompanyById(params.companyID);
            const workflowPromise = slug ? getWorkflowBySlug(params.companyID, slug) : Promise.resolve(null);
            const customFieldsPromise = getCustomFieldsByCompany(params.companyID);
            const [company, workflow, customFields] = await Promise.all([companyPromise, workflowPromise, customFieldsPromise]);
            return { company: company, slugWorkflow: workflow, customFields: customFields };
          } catch (error) {
            console.error(error);
            return null;
          }
          
        },
      },
      {
        path: "harks/:id",
        element: <TrackingPage />,
      },
      {
        path: "harks/:id",
        element: <TrackingPage />,
      },
      {
        path: "harks/:harkID/suggested-articles",
        element: <SuggestedArticlePage />,
      },
      {
        path: "harks/:harkID/read-article/:articleID",
        element: <ReadArticlePage />,
      },
    ],
  },
]);

export const App = () => (
  <RecoilRoot>
    <RouterProvider router={router} />
  </RecoilRoot>
);

function ThemedApp() {
  const brandColors = useRecoilValue(brandColorsThemeState);
  const theme = {
    colors: { ...colors, brand: brandColors },
    fonts,
    components,
  };
  return (
    <ChakraProvider theme={extendTheme(theme)}>
      <Outlet />
    </ChakraProvider>
  );
}
