import { Button, ButtonGroup, Heading, Stack, Text } from "@chakra-ui/react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getWorkflowBySlug } from "../../../api/workflows";
import { PreformattedText } from "../../SendHarkPage/components/PreFormattedText";

interface SuccessNotificationProps {
  chatEnabled: boolean;
}

export const SuccessNotification = ({
  chatEnabled,
}: SuccessNotificationProps) => {
  const { harkID } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const workflowSlug = queryParams.get("workflow");
  const companyID = queryParams.get("company");
  const [submittedTitle, setSubittedTitle] = useState<string | undefined>(
    undefined
  );
  const [submittedMessage, setSubmittedMessage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    async function loadWorkflow() {
      try {
        const workflow = await getWorkflowBySlug(
          String(companyID),
          String(workflowSlug)
        );
        console.log(workflow?.meta_data?.submittedHarkTitle, "Submit Title");
        console.log(
          workflow?.meta_data?.submittedHarkMessage,
          "Submit Message"
        );
        setSubittedTitle(workflow?.meta_data?.submittedHarkTitle);
        setSubmittedMessage(workflow?.meta_data?.submittedHarkMessage);
      } catch (err) {
        console.log(err);
      }
    }
    loadWorkflow();
  }, [submittedTitle, submittedMessage]);
  const customerEmail = sessionStorage.getItem("chat_handoff_email");

  const navigate = useNavigate();
  const activateChatHandOff = () => {
    const message = {
      action: "open-zd-chat",
      customerEmail: customerEmail,
      harkId: harkID,
    };

    window.parent && window.parent.postMessage(message, "*");
    navigate(-1);
    sessionStorage.removeItem("chat_handoff_email");
  };

  const handleDone = () => {
    window.parent && window.parent.postMessage("hark sent", "*");
    navigate(-1);
    sessionStorage.removeItem("chat_handoff_email");
  };

  const entityToChar = (str: string) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = str;
    return textarea.value;
  };

  return (
    <Stack
      id="thanksNotif"
      fontSize={14}
      background={"success.50"}
      p={4}
      gap={3}
      borderRadius={8}
      mb={5}
    >
      <Heading fontSize={16} as="h3" size="md">
        {!!submittedTitle
          ? entityToChar(submittedTitle)
          : "Thanks for reaching out!"}
      </Heading>

      <PreformattedText textAlign={"justify"}>
        {!!submittedMessage
          ? entityToChar(submittedMessage)
          : "We are working on it and will get back to you as soon as we can."}
        {chatEnabled &&
          `If it is
          urgent, feel free to chat with us.`}
      </PreformattedText>

      <ButtonGroup colorScheme="brand">
        <Button
          rounded="lg"
          bg="gray.800"
          _hover={{
            bg: "gray.700",
          }}
          _focus={{ borderColor: "gray.200" }}
          variant={"solid"}
          onClick={handleDone}
        >
          Done
        </Button>
        {customerEmail && (
          <Button
            rounded="lg"
            color="gray.800"
            borderColor={"gray.800"}
            variant="outline"
            onClick={activateChatHandOff}
            _hover={{ bg: "gray.50" }}
          >
            Chat with us
          </Button>
        )}
      </ButtonGroup>
    </Stack>
  );
};
