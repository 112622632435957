import {
    Box,
  } from "@chakra-ui/react";
  import { useRecoilState } from "recoil";
  import { newHarkState } from "../atoms/NewHarkStateAtom";
  import { validationErrorState } from "../atoms/validationErrorsState";
import { CustomFieldInput } from "./AdditonalInformation/CustomFieldInput";
import { getCustomFieldResponseSchema } from "../utils/validationSchemas";
import { validateItem } from "../utils/newHarkValidator";

export const SurveyStep: React.FC = () => {
    const [errors, setErrors] = useRecoilState(validationErrorState);

    const [newHark, setNewHark] = useRecoilState(newHarkState);
    const { customFieldResponses } = newHark;
    const selectedWorkflow = newHark.workflow;
    const customFields = selectedWorkflow?.custom_fields || [];

    const customFieldsSchema = getCustomFieldResponseSchema(customFields);
    const handleChange = (name: string, value: string) => {
        const customFieldsToUpdate = newHark.customFieldResponses;
        const customFieldResponses = { ...customFieldsToUpdate, [name]: value };
        setNewHark({ ...newHark, customFieldResponses });
        //simple error check for nps field. If nps is clicked, a value is guaranteed by default
        if (name === "nps" && value !== ""){
            return;
        }
        const error = customFieldsSchema[name] ? validateItem(customFieldsSchema[name], value) : undefined;

        setErrors({ ...errors, [name]: error });
    };

  const validateNumberInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      (e.key < "0" || e.key > "9") &&
      e.key !== "Backspace" &&
      e.key !== "Tab" &&
      e.key !== "Enter" &&
      e.key !== "Shift" &&
      e.key !== "Control" &&
      e.key !== "CapsLock" &&
      e.key !== "Alt"
    ) {
      setErrors({
        ...errors,
        [e.currentTarget.name]: "Only numbers are allowed",
      });
    }
  };
    return (
      <Box>
        {customFields?.filter((field) => field.is_survey === true).map((field) => (
            <CustomFieldInput
            key={field.id}
            field={field}
            value={customFieldResponses[field.name]}
            onChange={handleChange}
            validateNumberInput={validateNumberInput}
            errorMessage={errors[field.name]}
            />
        ))}
      </Box>
    );
  }
